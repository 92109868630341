@charset "UTF-8";
/*Roboto Condensed*/
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,300,300italic,400italic,700,700italic&subset=latin,latin-ext);
/*Open Sans*/
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,600italic,700,700italic&subset=latin,latin-ext);
/*Oswald*/
@import url(https://fonts.googleapis.com/css?family=Oswald:400,300,700&subset=latin,latin-ext);
/*--------------------- FONT --------------------------------*/
/*COLOR LOADING*/
/*---------------------------------COLOR SET 2---------------------------------*/
/*--------------------- COLOR TEXT -------------------------*/
/*--------------------- BUTTON COLOR -----------------------*/
/*--------------------- MAIN COLOR -------------------------*/
/*--------------------- COLOR LABEL ------------------------*/
/*--------------------- COLOR BANNER LIMIT TIME OFFER-------*/
/*---------------------------------COLOR SET 3---------------------------------*/
/*--------------------- COLOR TEXT -------------------------*/
/*--------------------- BUTTON COLOR -----------------------*/
/*--------------------- MAIN COLOR -------------------------*/
/*--------------------- COLOR LABEL ------------------------*/
/*--------------------- COLOR BANNER LIMIT TIME OFFER-------*/
/*================ Global | Sass Mixins ================*/
/*============================================================================
  Dependency-free breakpoint mixin
    - http://blog.grayghostvisuals.com/sass/sass-media-query-mixin/
==============================================================================*/
/*============================================================================
  THEME MIXINS
==============================================================================*/
/*============================================================================
  Accent text
==============================================================================*/
/*=============================== GLOBAL CSS ======================================================*/
html, body {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: #8b8b8b;
}

@media (max-width: 1229px) {
  html, body {
    font-size: 10.8px;
  }
}

@media (max-width: 991px) {
  html, body {
    font-size: 10.2px;
  }
}

@media (max-width: 767px) {
  html, body {
    font-size: 10.8px;
  }
}

body {
  min-width: 0;
}

a {
  -webkit-transition: all .0s;
  -o-transition: all .0s;
  transition: all .0s;
}

img {
  max-width: 100%;
  height: auto;
}

.button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]),
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]),
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]):focus,
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]):active,
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]):visited {
  height: 34px;
  line-height: 34px !important;
  padding: 0 15px;
  display: inline-block;
  background-color: #3498db;
  text-transform: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  font-weight: none;
  color: #ffffff !important;
  text-align: center;
  padding: 0 12px;
  border: none;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  -webkit-appearance: none;
  outline: none;
}

.button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]) span,
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]) span,
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]):focus span,
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]):active span,
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]):visited span {
  font-size: inherit !important;
  color: inherit !important;
}

.button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]):after,
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]):after,
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]):focus:after,
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]):active:after,
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]):visited:after {
  /*content:'';
		display: block;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
		background-color: #fff;
		transform: scale(0,0);
		opacity: 0;	
		border-radius: 0px;*/
}

.button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]):hover,
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]):hover,
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]):focus:hover,
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]):active:hover,
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]):visited:hover {
  color: #ffffff !important;
  background-color: #252a2f;
  /*&:after {
			transition: all .35s ease;
			transform: scale(1,1);	
			opacity: 1;
		}*/
}

.button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]) span,
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]) span,
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]):focus span,
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]):active span,
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]):visited span {
  border: none;
  padding: 0;
}

.button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]) [class^="icon-"],
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]) [class^="icon-"],
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]):focus [class^="icon-"],
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]):active [class^="icon-"],
button:not(.social-sharing):not(.bt_compare):not(.ajax_add_to_cart_button):not([name*="submit_search"]):not([name*="submitNewsletter"]):visited [class^="icon-"] {
  display: none;
}

.form-group.form-error input, .form-group.form-ok input {
  background-position: 98% 50%;
}

label {
  margin-bottom: 10px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  color: #687785;
}

input,
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"] {
  background: #fff;
  border: 1px solid #ccc !important;
  height: 34px;
  line-height: 32px;
  font-size: 1.1666666667rem;
  color: #8b8b8b;
  -webkit-appearance: none;
  border-radius: 4px;
  padding: 0 10px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

input:focus,
input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="tel"]:focus {
  border-color: #1a7eb8 !important;
  outline: none;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

textarea, textarea.form-control {
  border-color: #ccc !important;
  border-radius: 4px;
  font-family: "Open Sans", sans-serif;
  padding: 10px !important;
  font-size: 1.1666666667rem;
  color: #8b8b8b;
}

textarea:focus, textarea.form-control:focus {
  border-color: #1a7eb8 !important;
}

fieldset {
  min-width: 0;
}

/*============================================================================================================*/
/*================================================CUSTOME CONTAINER===========================================*/
.columns-container {
  padding-top: 20px;
}

.columns-container #columns {
  padding-top: 0;
}

@media (min-width: 1230px) {
  .container {
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 1229px) and (min-width: 992px) {
  .container {
    max-width: 970px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.menu-opened {
  overflow: hidden;
}

.menu-opened body.menu-opened {
  position: fixed;
  left: 0;
  top: 0;
}

/*=============================================================================================================*/
/*=============================================BLOCK TITLE=====================================================*/
.title_block h2, .title_block .h2 {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 3rem;
  color: #252a2f;
  margin: 0;
}

@media (max-width: 767px) {
  .title_block h2, .title_block .h2 {
    font-size: 26px;
  }
}

/*=============================================================================================================*/
/*=============================================MOBILE MENU OPEN================================================*/
.menu-opened #page {
  cursor: url(http://lookbook.arenathemes.com/themes/lookbook/css/../img/toggle_close.png), pointer;
  position: relative;
}

.menu-opened #page:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

/*==============================================================================================================*/
/*SELECTOR*/
div.select {
  *zoom: 1;
}

div.select:after, div.select:before {
  content: "";
  display: table;
  clear: both;
}

div.selector {
  height: 34px;
  background: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

div.selector > span {
  background: none;
  width: 100% !important;
  height: 32px;
  position: relative;
  line-height: 32px;
  color: #8b8b8b;
  font-size: 1.1666666667rem;
  text-align: left;
  padding-right: 50px;
}

div.selector > span:after {
  content: "\f0d7";
  color: #8b8b8b;
  font-size: 14px;
  font-family: "FontAweSome";
  width: 32px;
  height: 32px;
  text-align: center;
  border-left: 1px solid #ccc;
  position: absolute;
  right: 0;
  top: 0;
}

div.selector select {
  width: 100%;
  height: 32px;
}

div.selector.focus {
  border-color: #1a7eb8 !important;
  outline: none;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

div.selector + span {
  margin-top: 2px;
}

/*RADIO*/
/*div.radio {
	span {
		background: none;
		border: 1px solid #f3f3f3;
		border-radius: 0%;

		&.checked {
			position: relative;
			border-color: $mainColor_1;
			&:after {
				content:"";
				width: 5px;
				height: 5px;
				display: block;
				border-radius: 0%;
				background: $mainColor_1;
				position: absolute;
				left: 3px;
				top: 3px;
			}
		}
	}
}*/
/*CHECKER*/
/*div.checker {
	width: 10px;
	height: 10px;
	position: relative;
	min-height: 0;
	display: inline-block!important;

	+ label { 

		a {
			-webkit-transition-duration: 0s;
			-o-transition-duration: 0s;
			transition-duration: 0s;
		}

		&:hover {

			a {
				color: #ff3837;	
			}
		}
	}

	span {
		background: none;		
		width: 10px;
		height: 10px;
		position: relative;
		z-index: 1;
		top: 0;
		vertical-align: top;
		border: 1px solid #eee;

		&.checked {
			border-color: #ff3837;
			&:before {
				content: "";
				width: 4px;
				height: 4px;
				position: absolute;
				left: 50%;
				top: 50%;
				margin-left: -2px;
				margin-top: -2px;
				background: #ff3837;
			}
		}
	}

	input {
		width: 10px;
		height: 10px;
		min-height: 0;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
	}

	&:hover {
		span {
			border-color: #ff3837;
		}

		+ label {
			color: #ff3837;	
		}
	}
}*/
/*NEW LABEL*/
.new-box, .price-percent-reduction, .product .pb-center-column .content_prices #reduction_percent {
  min-width: 60px;
  height: 25px;
  padding: 0 15px;
  border-radius: 0;
  background-color: #3498db;
  display: block;
  text-align: center;
  line-height: 25px;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  color: #ffffff;
  position: absolute;
  left: -25px;
  top: -15px;
  z-index: 3;
  transition: left 0.3s ease;
}

.new-box:before, .price-percent-reduction:before, .product .pb-center-column .content_prices #reduction_percent:before {
  /*content: "";
		height: 0;
		width: 0;
		position: absolute;
		top: 0;
		left: 0;
		background: linear-gradient(135deg,#fff 45%, #aaa 50%, #ccc 56%, #fff 80%); 
		box-shadow: 1px 1px 1px rgba(0,0,0,0.4);
		transition: width 0.35s, height 0.35s;*/
}

.new-box:hover, .price-percent-reduction:hover, .product .pb-center-column .content_prices #reduction_percent:hover {
  color: #ffffff;
  background-color: #3498db;
}

.new-box:hover:before, .price-percent-reduction:hover:before, .product .pb-center-column .content_prices #reduction_percent:hover:before {
  width: 15px;
  height: 15px;
}

/*PRODUCT SALE LABEL*/
.sale-box {
  min-width: 60px;
  height: 25px;
  padding: 0 15px;
  border-radius: 0;
  background-color: #ff0000;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  display: block;
  text-align: center;
  line-height: 25px;
  color: #ffffff;
  position: absolute;
  left: -25px;
  top: 10px;
  z-index: 3;
  transition: left 0.3s ease;
  transition-delay: 0.1s;
}

.sale-box:before {
  /*content: "";
		height: 0;
		width: 0;
		position: absolute;
		top: 0;
		right: 0;
		background: linear-gradient(225deg,#fff 45%, #aaa 50%, #ccc 56%, #fff 80%);
		box-shadow: -1px 1px 1px rgba(0,0,0,0.4);
		transition: width 0.35s, height 0.35s;*/
}

.sale-box:hover {
  background-color: #ff0000;
  color: #ffffff;
}

.sale-box:hover:before {
  width: 15px;
  height: 15px;
}

/*PRODUCT NAME */
.product-name {
  color: #3498db;
  line-height: 1;
  text-transform: uppercase;
}

.product-name:hover {
  color: #252a2f !important;
  text-decoration: underline;
}

.product-name a {
  text-transform: uppercase;
  line-height: inherit;
  display: block;
  color: inherit;
}

/*PRODUCT PRICE*/
.price.product-price, .module-bcblog-post .out_related_products .ajax_block_product .price, .old-price.product-price {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 2rem;
  color: #252a2f;
  margin: 0;
  line-height: 1;
}

.old-price.product-price {
  color: #252a2f;
}

/*PRODUCT STOCK STATUS*/
.stock-availability, #cart_summary tbody td.cart_avail span {
  border: none;
  display: inline-block;
  color: #ffffff;
  background: #3498db;
  border-radius: 4px;
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  padding: 3px 6px 2px;
}

.availability span {
  background-color: #3498db;
  border: none;
  padding: 5px 10px;
  color: #fff;
  border-radius: 3px;
}

/*PRODUCT REDUCTION STATUS*/
.product-flags .discount {
  color: #ff3837;
  font-weight: bold;
}

/*PRICE REDUCE PERCEN*/
.price-percent-reduction, .product .pb-center-column .content_prices #reduction_percent {
  background: #ff4444;
  border: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  font-weight: bold;
}

.price-percent-reduction:hover, .product .pb-center-column .content_prices #reduction_percent:hover {
  background: #ff4444;
}

/*TIME COUNTER*/
.clock-block {
  *zoom: 1;
}

.clock-block:after, .clock-block:before {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 991px) and (min-width: 768px) {
  .clock-block {
    position: absolute;
    left: 100%;
    bottom: 50px;
    width: 100%;
  }
}

.clock-block .timer {
  *zoom: 1;
}

.clock-block .timer:after, .clock-block .timer:before {
  content: "";
  display: table;
  clear: both;
}

.clock-block .clock {
  text-align: center;
}

.clock-block li {
  display: inline-block;
  width: 50px;
  height: 50px;
  background: #f3f3f3;
  font-size: 0;
  border: 2px solid #252a2f;
  margin: 0 22px;
  text-transform: uppercase;
  padding-top: 10px;
  position: relative;
}

@media (max-width: 991px) and (min-width: 768px) {
  .clock-block li {
    margin: 0 10px;
  }
}

@media (max-width: 767px) {
  .clock-block li {
    margin: 0 7px;
    width: 40px;
    height: 40px;
    padding-top: 5px;
  }
}

.clock-block li:after {
  content: "";
  display: block;
  width: 4px;
  height: 24px;
  position: absolute;
  top: 13px;
  right: -26px;
  background: url("../images/dot-line-2.png") no-repeat center;
}

@media (max-width: 991px) and (min-width: 768px) {
  .clock-block li:after {
    right: -14px;
  }
}

@media (max-width: 767px) {
  .clock-block li:after {
    right: -11px;
    top: 7px;
  }
}

.clock-block li:last-child:after {
  display: none;
}

.clock-block li span {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 2rem;
  color: #252a2f;
  font-weight: bold;
}

@media (max-width: 767px) {
  .clock-block li span {
    font-size: 1.5rem;
  }
}

/*PRODUCT ITEM*/
.ajax_block_product {
  padding: 0 15px;
}

.ajax_block_product .product-container {
  border: 1px solid #e8e8e8;
  padding: 15px 15px 10px;
  position: relative;
  overflow: hidden;
  /*Functional buttons*/
  /*left block*/
  /*right block*/
}

@media (max-width: 480px) {
  .ajax_block_product .product-container {
    padding: 10px 5px 10px;
  }
  .ajax_block_product .product-container .new-box, .ajax_block_product .product-container .price-percent-reduction, .ajax_block_product .product-container .product .pb-center-column .content_prices #reduction_percent, .product .pb-center-column .content_prices .ajax_block_product .product-container #reduction_percent {
    left: -5px !important;
    top: -10px !important;
  }
  .ajax_block_product .product-container .sale-box {
    left: -5px !important;
    top: 15px !important;
  }
}

.ajax_block_product .product-container .functional-buttons {
  display: inline-block;
  overflow: hidden;
  padding-top: 10px;
}

.ajax_block_product .product-container .functional-buttons .quick-view-wrapper-mobile,
.ajax_block_product .product-container .functional-buttons .lnk_view {
  display: none !important;
}

.ajax_block_product .product-container .functional-buttons .addToWishlist, .ajax_block_product .product-container .functional-buttons .add_to_compare,
.ajax_block_product .product-container .functional-buttons .quick-view {
  width: 38px;
  height: 38px;
  display: inline-block;
  font-size: 0;
  line-height: 38px;
  border-radius: 0px;
  border: none;
  background-color: #252a2f;
  color: #ffffff;
  text-align: center;
  padding: 0;
  transform: translate3d(0, 0, 0);
  float: left;
}

.ajax_block_product .product-container .functional-buttons .addToWishlist:hover, .ajax_block_product .product-container .functional-buttons .add_to_compare:hover,
.ajax_block_product .product-container .functional-buttons .quick-view:hover {
  background-color: #3498db;
  color: #ffffff;
}

.ajax_block_product .product-container .functional-buttons .ajax_add_to_cart_button span {
  transition: all 0.2s ease-out;
  height: 38px;
  line-height: 38px;
  display: inline-block;
  background-color: #3498db;
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.3333333333rem;
  color: #fff !important;
  text-align: center;
  padding: 0 15px;
  border-radius: 0;
}

.ajax_block_product .product-container .functional-buttons .ajax_add_to_cart_button span:hover {
  background-color: #252a2f;
}

.ajax_block_product .product-container .functional-buttons .ajax_add_to_cart_button:before {
  transition: all 0.2s ease-out;
  content: '';
  display: inline-block;
  float: right;
  width: 38px;
  height: 38px;
  display: inline-block;
  font-size: 0;
  line-height: 38px;
  border-radius: 0px;
  border: none;
  background-color: #252a2f;
  color: #ffffff;
  text-align: center;
  padding: 0;
  transform: translate3d(0, 0, 0);
}

.ajax_block_product .product-container .functional-buttons .ajax_add_to_cart_button:before:hover {
  background-color: #3498db;
  color: #ffffff;
}

.ajax_block_product .product-container .functional-buttons .ajax_add_to_cart_button:before {
  content: "";
  font-family: "FontAweSome";
  display: block;
  font-size: 1.1666666667rem;
  color: #ffffff;
}

.ajax_block_product .product-container .functional-buttons .ajax_add_to_cart_button:hover span {
  background-color: #252a2f;
}

.ajax_block_product .product-container .functional-buttons .ajax_add_to_cart_button:hover:before {
  background: #3498db;
  content: "\f218";
}

.ajax_block_product .product-container .functional-buttons .addToWishlist:before {
  content: "";
  font-family: "FontAweSome";
  display: block;
  font-size: 1.1666666667rem;
  color: #ffffff;
}

.ajax_block_product .product-container .functional-buttons .addToWishlist.checked {
  background: #1a7eb8;
}

.ajax_block_product .product-container .functional-buttons .addToWishlist.checked:before {
  content: "";
  font-family: "FontAweSome";
  display: block;
  font-size: 1.1666666667rem;
  color: #ffffff;
}

.ajax_block_product .product-container .functional-buttons .add_to_compare:before {
  content: "";
  font-family: "FontAweSome";
  display: block;
  font-size: 1.1666666667rem;
  color: #ffffff;
}

.ajax_block_product .product-container .functional-buttons .add_to_compare.checked {
  background: #1a7eb8;
}

.ajax_block_product .product-container .functional-buttons .add_to_compare.checked:before {
  content: "";
  font-family: "FontAweSome";
  display: block;
  font-size: 1.1666666667rem;
  color: #ffffff;
}

.ajax_block_product .product-container .functional-buttons .quick-view {
  width: 80px;
  height: 80px;
  background: #1a7eb8;
  display: block;
  position: absolute;
  top: -80px;
  right: -25px;
  transform: rotate(0deg);
  transform-origin: 0 100%;
  transition: all 0.2s ease-out;
}

.ajax_block_product .product-container .functional-buttons .quick-view:hover {
  background: #1a7eb8;
}

.ajax_block_product .product-container .functional-buttons .quick-view:after {
  content: "\f06e";
  font-family: "FontAweSome";
  display: block;
  font-size: 1.1666666667rem;
  line-height: 1;
  padding-top: 15px;
  padding-right: 13px;
  transform: rotate(-45deg);
}

@media (max-width: 767px) {
  .ajax_block_product .product-container .functional-buttons .quick-view {
    display: none !important;
  }
}

.ajax_block_product .product-container .functional-buttons .ajax_add_to_cart_button {
  float: left;
  margin: 0 !important;
}

@media (max-width: 1229px) {
  .ajax_block_product .product-container .functional-buttons .ajax_add_to_cart_button {
    margin-bottom: 5px !important;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .ajax_block_product .product-container .functional-buttons .ajax_add_to_cart_button span {
    padding: 0 13px;
  }
}

.ajax_block_product .product-container .functional-buttons .wishlist, .ajax_block_product .product-container .functional-buttons .compare,
.ajax_block_product .product-container .functional-buttons .quick-view {
  margin-left: 1px !important;
  float: left;
}

@media (max-width: 1229px) and (min-width: 768px) {
  .ajax_block_product .product-container .functional-buttons .wishlist, .ajax_block_product .product-container .functional-buttons .compare,
  .ajax_block_product .product-container .functional-buttons .quick-view {
    margin-left: 1px !important;
  }
}

.ajax_block_product .product-container .left-block {
  position: relative;
  width: 100%;
}

.ajax_block_product .product-container .left-block .product_img_link {
  *zoom: 1;
  display: block;
}

.ajax_block_product .product-container .left-block .product_img_link:after, .ajax_block_product .product-container .left-block .product_img_link:before {
  content: "";
  display: table;
  clear: both;
}

.ajax_block_product .product-container .left-block .product_img_link:before {
  content: "";
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.35s ease-out;
  background: rgba(255, 255, 255, 0.7);
  z-index: 2;
  position: absolute;
  left: -10px;
  top: -10px;
  right: -10px;
  bottom: -10px;
}

.ajax_block_product .product-container .left-block .product_img_link .img-responsive {
  transition: all .35s;
  transform: scale(1, 1);
  max-width: 100%;
}

@media (max-width: 767px) {
  .ajax_block_product .product-container .left-block .product_img_link .img-responsive {
    width: 100%;
  }
}

.ajax_block_product .product-container .left-block .product_img_link .image-product-hover {
  max-width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: all 0s;
  transform: scale(1, 1);
  transition-delay: 0s;
}

@media (max-width: 767px) {
  .ajax_block_product .product-container .left-block .product_img_link .image-product-hover {
    width: 100%;
  }
}

.ajax_block_product .product-container .left-block .product-image-container {
  padding: 0px;
}

.ajax_block_product .product-container .left-block .button-container {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.ajax_block_product .product-container .left-block .button-container .ajax_add_to_cart_button,
.ajax_block_product .product-container .left-block .button-container .addToWishlist, .ajax_block_product .product-container .left-block .button-container .add_to_compare,
.ajax_block_product .product-container .left-block .button-container .quick-view {
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  display: none;
}

.ajax_block_product .product-container .left-block .button-container .ajax_add_to_cart_button {
  animation-delay: 0.2s;
}

.ajax_block_product .product-container .left-block .button-container .addToWishlist {
  animation-delay: 0.3s;
}

.ajax_block_product .product-container .left-block .button-container .add_to_compare {
  animation-delay: 0.4s;
}

.ajax_block_product .product-container .left-block .button-container .quick-view {
  animation-delay: 0.5s;
}

.ajax_block_product .product-container .right-block {
  text-align: left;
  padding: 20px 0;
  *zoom: 1;
}

.ajax_block_product .product-container .right-block:after, .ajax_block_product .product-container .right-block:before {
  content: "";
  display: table;
  clear: both;
}

.ajax_block_product .product-container .right-block h5, .ajax_block_product .product-container .right-block .h5 {
  margin: 0;
}

.ajax_block_product .product-container .right-block .button-container {
  line-height: 0;
}

.ajax_block_product .product-container .right-block .product-name {
  font-size: 1.5rem;
  color: #3498db;
  padding: 0px;
  margin: 10px 0;
  display: block;
}

.ajax_block_product .product-container .right-block .product-name:hover {
  text-decoration: underline;
  color: #252a2f;
}

.ajax_block_product .product-container .right-block .product-desc {
  color: #8b8b8b;
  padding: 0;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  max-height: 36px;
  margin: 5px 0;
}

.ajax_block_product .product-container .right-block .availability,
.ajax_block_product .product-container .right-block .product-flags {
  display: none !important;
}

.ajax_block_product .product-container .right-block .content_price,
.ajax_block_product .product-container .right-block .reviews-container {
  float: left;
  width: 100%;
  height: auto;
  margin: 0;
}

.ajax_block_product .product-container .right-block .content_price {
  background-color: none;
  padding: 10px 0;
}

.ajax_block_product .product-container .right-block .content_price .price {
  padding-right: 5px;
}

.ajax_block_product .product-container .right-block .content_price .old-price {
  color: #687785;
  font-size: 1.5rem;
}

.ajax_block_product .product-container .right-block .reviews-container {
  background-color: none;
  padding-bottom: 10px;
}

.ajax_block_product .product-container .right-block .reviews-container .comments_note {
  padding: 0;
  height: auto;
}

.ajax_block_product .product-container .right-block .reviews-container .comments_note .star_content {
  height: 100%;
  padding-top: 0px;
}

.ajax_block_product .product-container .right-block .price-percent-reduction, .ajax_block_product .product-container .right-block .product .pb-center-column .content_prices #reduction_percent, .product .pb-center-column .content_prices .ajax_block_product .product-container .right-block #reduction_percent {
  display: none !important;
  top: 55px;
}

@media (max-width: 767px) {
  .ajax_block_product .product-container .right-block .price-percent-reduction, .ajax_block_product .product-container .right-block .product .pb-center-column .content_prices #reduction_percent, .product .pb-center-column .content_prices .ajax_block_product .product-container .right-block #reduction_percent {
    top: 40px;
  }
}

.ajax_block_product .product-container:hover .left-block .product_img_link .img-responsive {
  transform: scale(1, 1);
  opacity: 0;
}

.ajax_block_product .product-container:hover .left-block .product_img_link .image-product-hover {
  transform: scale(1, 1);
  opacity: 1;
  transition: all .35s;
}

.ajax_block_product .product-container:hover .left-block .product_img_link:before {
  visibility: visible;
  opacity: 1;
}

.ajax_block_product .product-container:hover .left-block .button-container .ajax_add_to_cart_button,
.ajax_block_product .product-container:hover .left-block .button-container .addToWishlist, .ajax_block_product .product-container:hover .left-block .button-container .add_to_compare,
.ajax_block_product .product-container:hover .left-block .button-container .quick-view {
  display: block;
}

.ajax_block_product .product-container:hover .left-block .new-box, .ajax_block_product .product-container:hover .left-block .price-percent-reduction, .ajax_block_product .product-container:hover .left-block .product .pb-center-column .content_prices #reduction_percent, .product .pb-center-column .content_prices .ajax_block_product .product-container:hover .left-block #reduction_percent, .ajax_block_product .product-container:hover .left-block .sale-box {
  left: -15px;
}

.ajax_block_product .product-container:hover .right-block .functional-buttons .quick-view {
  transform: rotate(45deg);
}

.ajax_block_product.no-review .right-block .reviews-container {
  display: none;
}

.ajax_block_product.no-review .right-block .content_price {
  width: 100%;
  background: transparent;
}

.ajax_block_product.no-review .right-block .content_price .product-price {
  position: relative;
  top: -10px;
}

/*SCROLL TO TOP BUTTON*/
#scroll-to-top {
  position: fixed;
  right: 10px;
  bottom: 100px;
  border-radius: 0;
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  z-index: 200;
  font-size: 0;
  text-align: center;
  color: #eee;
  background: #3498db;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  cursor: pointer;
}

#scroll-to-top:after {
  border-radius: 0;
}

#scroll-to-top:before {
  content: "\f062";
  font-family: "FontAweSome";
  font-size: 15px;
}

#scroll-to-top:hover {
  color: #fff;
  -webkit-animation-name: hvr-bob-float, hvr-bob;
  animation-name: hvr-bob-float, hvr-bob;
  -webkit-animation-duration: .3s, 1.5s;
  animation-duration: .3s, 1.5s;
  -webkit-animation-delay: 0s, .3s;
  animation-delay: 0s, .3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}

/*OWL SLIDER CONTROL*/
.slideNav, .owl-controls .owl-nav .owl-prev, .owl-controls .owl-nav .owl-next, #layer_cart .crossseling #blockcart_list .bx-prev, #layer_cart .crossseling #blockcart_list .bx-next, .product .pb-left-column #views_block #view_scroll_left, .product .pb-left-column #views_block #view_scroll_right {
  border-radius: 0px;
  margin: 0 5px !important;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  color: #ffffff;
  font-size: 0;
  border: none;
  background: none;
  line-height: 1;
}

.slideNav:before, .owl-controls .owl-nav .owl-prev:before, .owl-controls .owl-nav .owl-next:before, #layer_cart .crossseling #blockcart_list .bx-prev:before, #layer_cart .crossseling #blockcart_list .bx-next:before, .product .pb-left-column #views_block #view_scroll_left:before, .product .pb-left-column #views_block #view_scroll_right:before {
  font-family: "FontAweSome";
  text-align: center;
  font-size: 24px;
  font-weight: normal;
  line-height: 1;
  display: block;
  color: inherit;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0;
}

.slideNav:hover, .owl-controls .owl-nav .owl-prev:hover, .owl-controls .owl-nav .owl-next:hover, #layer_cart .crossseling #blockcart_list .bx-prev:hover, #layer_cart .crossseling #blockcart_list .bx-next:hover, .product .pb-left-column #views_block #view_scroll_left:hover, .product .pb-left-column #views_block #view_scroll_right:hover {
  color: #3498db !important;
}

.slidePrev:before, .owl-controls .owl-nav .owl-prev:before, #layer_cart .crossseling #blockcart_list .bx-prev:before, .product .pb-left-column #views_block #view_scroll_left:before {
  content: "\f053";
}

.slideNext:before, .owl-controls .owl-nav .owl-next:before, #layer_cart .crossseling #blockcart_list .bx-next:before, .product .pb-left-column #views_block #view_scroll_right:before {
  content: "\f054";
}

/*BLOCK TAG*/
.tags_block a {
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 0 15px;
  background-color: #f3f3f3;
  float: left;
  margin-right: 10px;
  margin-bottom: 12px;
  min-height: 30px;
  padding-top: 5px;
}

.tags_block a:hover {
  background: #3498db;
  color: #fff !important;
}

/*BUTTON COMPARE SUBMIT ON CATEGORY PAGE*/
.button.button-medium.bt_compare {
  opacity: 1;
  cursor: pointer;
  height: 34px;
  line-height: 34px !important;
  display: inline-block;
  background-color: #252a2f;
  text-transform: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  font-weight: normal;
  color: #ffffff !important;
  text-align: center;
  padding: 0 15px;
  border: none;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.button.button-medium.bt_compare i {
  display: none;
}

.button.button-medium.bt_compare span {
  font-size: inherit !important;
  color: inherit !important;
}

.button.button-medium.bt_compare:after {
  /*content:'';
		display: block;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
	background-color: $colorBtn_1;
		transform: scale(0,0);
		opacity: 0;	
		border-radius: 0px;*/
}

.button.button-medium.bt_compare:hover {
  color: #ffffff !important;
  background-color: #3498db;
  /*&:after {
			transition: all .3s ease;
			transform: scale(1,1);	
			opacity: 1;
		}*/
}

.button.button-medium.bt_compare:hover {
  opacity: 1;
}

.button.button-medium.bt_compare:before {
  content: "\f080";
  display: inline-block;
  padding-right: 5px;
  font-family: "FontAweSome";
  font-size: 14px;
  color: #ffffff;
}

/*REVIEW STAR*/
.comments_note {
  font-size: 0;
  text-align: left;
}

.comments_note .nb-comments {
  display: none;
}

.star_content {
  line-height: 1;
}

.star_content .star {
  float: none;
  display: inline-block;
  margin: 0 2px;
}

.star_content .star.star_on {
  display: inline-block;
}

.star_content .star:after {
  color: #3498db !important;
}

/*PAGE HEADING*/
.page-heading {
  line-height: 1.2;
  padding-bottom: 20px;
  margin-bottom: 20px !important;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: normal;
  text-align: center;
  color: #252a2f;
  position: relative;
  /*&:before {
		content: "";
		height: 1px;
		display: block;
		position: absolute;
		left: 50%;
		bottom: 0;
		background-color: #f3f3f3;
		width: 50vw;
		transform: translate(-100%, 0);
	}
	&:after {
		content: "";
		display: block;
		background-color: #fff;
		width: 10px;
		height: 10px;
		border: 2px solid #f3f3f3;
		border-radius: 10px;
		position: absolute;
		bottom: -5px;
		left: 50%;
		margin-left: -5px;
	}*/
}

@media (max-width: 767px) {
  .page-heading {
    font-size: 26px;
  }
}

.page-heading .lighter {
  color: #3498db;
  font-weight: normal;
}

.page-heading span.heading-counter {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.3333333333rem;
  color: #3498db;
  font-weight: normal;
  float: right;
  position: absolute;
  right: 0;
  bottom: 0;
}

/*PAGE SUB-HEADING*/
.page-subheading, .category #center_column .content_scene_cat_bg .category-name, .category #center_column #subcategories .subcategory-heading, .product .pb-center-column h1, .product .pb-center-column .h1, .module-bcblog-post .out_related_products .title, .module-bcblog-post .blog-comment-list .title, .module-bcblog-post .related_posts .title, .module-bcblog-post .pl_comment_form .title {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 2rem;
  font-weight: normal;
  border-bottom: 1px solid #83909d;
  margin: 0 0 20px;
  color: #252a2f;
  position: relative;
  display: inline-block;
  line-height: 1;
  padding: 5px 0 15px 0;
  width: 100%;
  /*&:before {
		content:'';
		width: 20px;
		height: 20px;
		position: absolute;
		right: 0px;
		top: 0px;
		border-top: 1px solid $mainColor_1;
		border-right: 1px solid $mainColor_1;
	}
	&:after {
		content:'';
		width: 20px;
		height: 20px;
		position: absolute;
		left: 0px;
		bottom: 0px;
		border-bottom: 1px solid $mainColor_1;
		border-left: 1px solid $mainColor_1;
	}*/
}

/*HISTORY PRICE*/
.history_price .price {
  color: #ff3837;
  font-size: 16px;
  font-weight: bold;
}

/*TOP BANNER*/
#header #banner_block_popup {
  margin-bottom: 0;
  text-align: center;
  padding: 10px 15px;
  position: relative;
  font-family: "Roboto Condensed", sans-serif;
}

#header #banner_block_popup .text_content {
  color: #ffffff;
  padding: 0 30px;
  text-transform: uppercase;
}

#header #banner_block_popup .text_content p {
  margin-bottom: 0;
}

#header #banner_block_popup .ads-bar-link {
  display: inline-block;
  color: #ffffff;
  font-weight: bold;
  padding-left: 5px;
  text-decoration: underline;
}

#header #banner_block_popup .close {
  font-size: 0;
  position: absolute;
  border: none;
  position: absolute;
  right: 15px;
  top: 4px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: url("../images/close.png") no-repeat center;
  transform: rotate(0deg);
  transition: transform 0.5s ease-out;
}

#header #banner_block_popup .close:after {
  display: none;
}

#header #banner_block_popup .close span {
  padding: 0;
  border: none;
  display: none;
}

/*COOKIE LAW*/
#cookie-popup {
  background: #3498db;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}

#cookie-popup #cookie_block_popup {
  margin-bottom: 0;
  padding: 20px 0 10px;
}

@media (max-width: 767px) {
  #cookie-popup #cookie_block_popup {
    padding-left: 15px;
    padding-right: 15px;
  }
}

#cookie-popup .text_content {
  width: 60%;
  float: left;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  color: #fff;
}

#cookie-popup .text_content .hi-cookie-head {
  text-transform: uppercase;
}

@media (max-width: 370px) {
  #cookie-popup .text_content {
    width: 100%;
  }
}

#cookie-popup .button-container {
  width: 40%;
  float: right;
  text-align: right;
  padding-top: 5px;
}

@media (max-width: 370px) {
  #cookie-popup .button-container {
    width: 100%;
    text-align: left;
  }
}

#cookie-popup .btn {
  height: 34px;
  line-height: 34px !important;
  display: inline-block;
  background-color: #252a2f;
  text-transform: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  font-weight: normal;
  color: #ffffff !important;
  text-align: center;
  padding: 0 15px;
  border: none;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin: 0 5px 10px;
  text-shadow: none;
  cursor: pointer;
  display: inline-block;
}

#cookie-popup .btn span {
  font-size: inherit !important;
  color: inherit !important;
}

#cookie-popup .btn:after {
  /*content:'';
		display: block;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
	background-color: $colorBtn_1;
		transform: scale(0,0);
		opacity: 0;	
		border-radius: 0px;*/
}

#cookie-popup .btn:hover {
  color: #ffffff !important;
  background-color: #3498db;
  /*&:after {
			transition: all .3s ease;
			transform: scale(1,1);	
			opacity: 1;
		}*/
}

#cookie-popup .btn span {
  background: none;
  border: none;
  padding: 0;
}

#cookie-popup .btn:hover {
  background: #525d68;
}

/*STICKY MENU*/
.menu-sticky.bc-stickymenu-fixed {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  /*-webkit-box-shadow: 0 3px 5px 0px #f3f3f3;
		box-shadow: 0 3px 5px 0px #f3f3f3;*/
}

/*=============================== TYPOGRAPHY ======================================================*/
h1, .h1, h2, .h2, h3, .h3, h4, .h4, #index .type-tab .nav-tabs li a, h5, .h5 {
  font-family: "Roboto Condensed", sans-serif;
  line-height: 1;
}

h1, .h1 {
  font-size: 3rem;
}

h2, .h2 {
  font-size: 2.5rem;
}

h3, .h3 {
  font-size: 2rem;
}

h4, .h4, #index .type-tab .nav-tabs li a {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.3333333333rem;
}

ul, ol {
  list-style: none;
}

ul li, ol li {
  outline: none;
}

a {
  color: #8b8b8b;
}

a:active, a:focus {
  outline: none;
  text-decoration: none;
}

a:hover, a:focus {
  background: none;
}

/*=========================================== CSS FOR HEADER ===========================================================*/
@media (max-width: 767px) {
  .header-container {
    padding-bottom: 0px;
  }
}

header {
  /*cart block*/
}

header .banner {
  background-color: #1a7eb8;
}

header .container {
  overflow: visible;
}

header .nav {
  background-color: #252a2f;
  border-bottom: 1px solid #f3f3f3;
}

header .nav nav {
  min-height: 44px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.1666666667rem;
}

@media (max-width: 991px) {
  header .nav nav {
    text-align: center;
  }
}

header .nav #languages-block-top, header .nav #currencies-block-top {
  float: right;
  border: none;
  height: 44px;
  font-size: 1.1666666667rem;
}

@media (max-width: 991px) {
  header .nav #languages-block-top, header .nav #currencies-block-top {
    float: none;
    display: inline-block;
    vertical-align: top;
    border: none;
    height: 30px;
  }
}

header .nav #languages-block-top .current, header .nav #currencies-block-top .current {
  color: #ffffff;
  padding: 0 10px 0 10px;
  line-height: 44px;
  transition: all 0.3s ease-out;
}

@media (max-width: 991px) {
  header .nav #languages-block-top .current, header .nav #currencies-block-top .current {
    line-height: 30px;
  }
}

@media (max-width: 480px) {
  header .nav #languages-block-top .current, header .nav #currencies-block-top .current {
    padding: 0 5px 0 5px;
  }
}

header .nav #languages-block-top .current::after, header .nav #currencies-block-top .current::after {
  content: "\f0d7";
  color: inherit;
  font-size: 14px;
  padding-left: 3px;
  vertical-align: 0px;
  text-shadow: none;
}

header .nav #languages-block-top .current span, header .nav #languages-block-top .current strong, header .nav #currencies-block-top .current span, header .nav #currencies-block-top .current strong {
  color: inherit;
  text-shadow: none;
  font-weight: normal;
}

header .nav #languages-block-top .current:hover, header .nav #languages-block-top .current.active, header .nav #currencies-block-top .current:hover, header .nav #currencies-block-top .current.active {
  color: #3498db;
  background: none;
}

header .nav #languages-block-top ul, header .nav #currencies-block-top ul {
  background: #fff;
  border: 1px solid #eee;
  border-top: none;
  top: 44px;
  left: -1px;
}

@media (max-width: 991px) {
  header .nav #languages-block-top ul, header .nav #currencies-block-top ul {
    top: 30px;
  }
}

header .nav #languages-block-top ul li a, header .nav #languages-block-top ul li > span, header .nav #currencies-block-top ul li a, header .nav #currencies-block-top ul li > span {
  color: #8b8b8b;
  padding-left: 10px;
}

header .nav #languages-block-top ul li.selected, header .nav #languages-block-top ul li:hover, header .nav #currencies-block-top ul li.selected, header .nav #currencies-block-top ul li:hover {
  background: #3498db;
}

header .nav #languages-block-top ul li.selected a, header .nav #languages-block-top ul li:hover a, header .nav #currencies-block-top ul li.selected a, header .nav #currencies-block-top ul li:hover a {
  color: #fff;
  background: #3498db;
}

header .nav #languages-block-top ul li.selected > span, header .nav #languages-block-top ul li:hover > span, header .nav #currencies-block-top ul li.selected > span, header .nav #currencies-block-top ul li:hover > span {
  color: #fff;
}

header .nav .social-header {
  float: right;
  display: none;
}

header .nav .social-header ul li {
  float: left;
  height: 54px;
  font-family: "FontAwesome";
  color: #333;
  cursor: pointer;
  border-right: 1px solid #eee;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

@media (max-width: 991px) {
  header .nav .social-header ul li {
    border: none;
    height: 30px;
  }
}

header .nav .social-header ul li a {
  font-size: 0;
  padding-top: 0;
  width: 47px;
  height: 54px;
  line-height: 54px;
  text-align: center;
  display: inline-block;
  color: #8b8b8b;
}

@media (max-width: 991px) {
  header .nav .social-header ul li a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}

header .nav .social-header ul li a:hover {
  color: #3498db;
}

header .nav .social-header ul li a:before {
  font-size: 1.1666666667rem;
}

header .nav .social-header ul li.facebook a:before {
  content: "\f09a";
}

header .nav .social-header ul li.twitter a:before {
  content: "\f099";
}

header .nav .social-header ul li.google-plus a:before {
  content: "\f0d5";
}

header .nav .social-header ul li.dribbble a:before {
  content: "\f17d";
}

header .nav .social-header ul li.linkedin a:before {
  content: "\f0e1";
}

header .nav .header_user_info {
  border: none;
  padding-left: 0px;
  padding-top: 14px;
  float: left;
}

@media (max-width: 991px) {
  header .nav .header_user_info {
    width: 100%;
    text-align: center;
    padding-top: 0px;
    margin-bottom: 10px;
  }
  header .nav .header_user_info span, header .nav .header_user_info a {
    float: none !important;
    display: inline-block;
  }
}

@media (max-width: 767px) {
  header .nav .header_user_info {
    *zoom: 1;
    clear: both;
    float: none;
    padding: 0 15px;
    text-align: center;
  }
  header .nav .header_user_info:after, header .nav .header_user_info:before {
    content: "";
    display: table;
    clear: both;
  }
  header .nav .header_user_info span, header .nav .header_user_info a {
    float: none !important;
    display: inline-block;
  }
}

header .nav .header_user_info span {
  float: left;
  padding-right: 5px;
  color: #3498db;
}

header .nav .header_user_info a {
  padding: 0;
  color: #8b8b8b;
  float: left;
}

header .nav .header_user_info a:hover {
  background: none;
  color: #3498db;
}

header .nav .header_links {
  *zoom: 1;
  display: inline-block;
  float: left;
  text-align: center;
}

header .nav .header_links:after, header .nav .header_links:before {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 991px) {
  header .nav .header_links {
    width: 100%;
  }
}

header .nav .header_links .dropdown-toggle {
  display: none;
}

header .nav .header_links #header_links {
  display: inline-block !important;
  position: relative;
  margin-top: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  background-color: transparent;
  padding-top: 10px;
}

@media (max-width: 991px) {
  header .nav .header_links #header_links {
    float: none;
  }
}

header .nav .header_links #header_links li {
  border-left: none;
  color: #ffffff;
  padding: 0 20px 0 0;
}

@media (max-width: 991px) {
  header .nav .header_links #header_links li {
    padding: 0 10px 0 10px;
  }
}

@media (max-width: 480px) {
  header .nav .header_links #header_links li {
    padding: 0 5px 0 5px;
  }
}

header .nav .header_links #header_links li a {
  padding-left: 0;
  padding-right: 0;
  font-size: 1.1666666667rem;
  color: #ffffff;
}

header .nav .header_links #header_links li a:hover {
  background-color: transparent;
  color: #3498db;
  text-decoration: none;
}

header .nav .header_links #header_links li .icon {
  margin-right: 5px;
  font-size: 0.8333333333rem;
}

@media (max-width: 767px) {
  header .nav .header_links {
    float: none;
    margin: 0 auto;
    display: block;
  }
  header .nav .header_links ul#header_links {
    float: none;
  }
}

header .header-middle {
  background: #fff;
  padding-top: 43px;
  padding-bottom: 31px;
  border-bottom: 1px solid #f3f3f3;
}

@media (max-width: 767px) {
  header .header-middle {
    border: none;
    padding-top: 35px;
    padding-bottom: 0px;
  }
}

header .header-middle #header_logo {
  *zoom: 1;
  padding-top: 5px;
}

header .header-middle #header_logo:after, header .header-middle #header_logo:before {
  content: "";
  display: table;
  clear: both;
}

header .header-middle #header_logo img {
  margin: 0 auto;
}

@media (max-width: 767px) {
  header .header-middle #header_logo {
    clear: both;
    padding: 10px 15px 20px;
  }
}

header .header-middle #header_logo + div {
  position: static;
}

@media (max-width: 991px) {
  header .header-middle .contact-info {
    text-align: center;
  }
}

header .header-middle .contact-info ul {
  *zoom: 1;
  margin-bottom: 15px;
}

header .header-middle .contact-info ul:after, header .header-middle .contact-info ul:before {
  content: "";
  display: table;
  clear: both;
}

header .header-middle .contact-info ul li {
  float: left;
  margin-right: 20px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.3333333333rem;
  color: #252a2f;
}

@media (max-width: 991px) {
  header .header-middle .contact-info ul li {
    float: none;
    display: inline-block;
  }
}

header .header-middle .contact-info ul li i {
  font-size: 1.5rem;
  color: #3498db;
  margin-right: 9px;
}

header .header-middle #search_block_top {
  width: 100%;
  float: left;
  padding-top: 0;
  position: relative;
  z-index: 10;
}

@media (max-width: 767px) {
  header .header-middle #search_block_top {
    margin-bottom: 10px;
  }
}

header .header-middle #search_block_top form {
  position: relative;
}

header .header-middle #search_block_top #search_query_top {
  width: 100%;
  height: 46px;
  padding: 0 56px 0 15px;
  background-color: #fff;
  border-radius: 0;
  border-color: #3498db !important;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
}

header .header-middle #search_block_top .button-search {
  width: 46px;
  height: 46px;
  position: absolute;
  background: transparent;
  right: 0px;
  top: 0px;
  border-radius: 0;
  border: none;
  padding: 0;
}

header .header-middle #search_block_top .button-search:before {
  font-size: 1.5rem;
  color: #3498db;
}

header .header-middle #search_block_top .button-search:hover {
  color: #fff;
}

@media (max-width: 480px) {
  header .header-middle #search_block_top .button-search {
    width: 35px;
    height: 35px;
  }
}

header .header-middle #search_block_top #show_result {
  position: absolute;
  background: #fff;
  min-width: 270px;
  width: 100%;
  left: 0;
  top: calc(100% + 9px);
  z-index: 100;
  color: #252a2f;
  /*-webkit-box-shadow: 0 5px 10px 0 rgba(0,0,0,0.4);
				box-shadow: 0 5px 10px 0 rgba(0,0,0,0.4);*/
}

header .header-middle #search_block_top #show_result .result-wrapper {
  *zoom: 1;
  border: 1px solid #3498db;
}

header .header-middle #search_block_top #show_result .result-wrapper:after, header .header-middle #search_block_top #show_result .result-wrapper:before {
  content: "";
  display: table;
  clear: both;
}

header .header-middle #search_block_top #show_result .result {
  width: 100%;
  padding: 15px;
}

header .header-middle #search_block_top #show_result #closedform {
  position: absolute;
  font-size: 0;
  right: 0px !important;
  top: 0px !important;
  cursor: pointer;
}

header .header-middle #search_block_top #show_result #closedform:hover {
  color: #ff3837;
}

header .header-middle #search_block_top #show_result h1, header .header-middle #search_block_top #show_result .h1 {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  color: #3498db;
  font-weight: normal;
  margin: 0;
  padding: 0 0 20px;
}

header .header-middle #search_block_top #show_result #search-products_block li {
  border-bottom: 1px solid #ddd;
}

header .header-middle #search_block_top #show_result #search-products_block li .products-block-image {
  float: left;
  margin-right: 10px;
  width: 64px;
  margin-right: 15px;
}

header .header-middle #search_block_top #show_result #search-products_block li h5, header .header-middle #search_block_top #show_result #search-products_block li .h5 {
  margin-top: 0;
  margin-bottom: 10px;
}

header .header-middle #search_block_top #show_result #search-products_block li h5 .product-name, header .header-middle #search_block_top #show_result #search-products_block li .h5 .product-name {
  color: #3498db;
}

header .header-middle #search_block_top #show_result #search-products_block li .price-box .price {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.3333333333rem;
  color: #252a2f;
}

header .header-middle #search_block_top #show_result #search-products_block li:last-child {
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

header .header-middle .shopping_cart {
  padding-top: 0;
  float: left;
  border: 1px solid #3498db;
  padding: 12px 15px 11px;
  width: 100%;
}

@media (max-width: 767px) {
  header .header-middle .shopping_cart {
    float: none !important;
    width: auto;
    margin-right: 56px;
  }
}

header .header-middle .shopping_cart > a:first-child {
  *zoom: 1;
  background: transparent;
  text-shadow: none;
  color: #8b8b8b;
  text-transform: uppercase;
  font-size: 1.3333333333rem;
  padding: 0;
  overflow: hidden;
  font-weight: normal;
  padding-top: 3px;
  /*@media (max-width: 767px) {
					padding: 0;
					.ajax_cart_quantity {
						display: block !important;
						width: 20px;
						height: 20px;
						position: absolute;
						right: -5px;
						border-radius: 50%;
						top: -5px;
						background: #373737;
						text-align: center;
						padding-right: 0 !important;
						color: #fff;
						line-height: 20px;
					}
				}*/
}

header .header-middle .shopping_cart > a:first-child:after, header .header-middle .shopping_cart > a:first-child:before {
  content: "";
  display: table;
  clear: both;
}

header .header-middle .shopping_cart > a:first-child:before {
  content: "\f07a";
  color: #3498db;
  padding-right: 0;
  margin-right: 5px;
  float: left;
  margin-top: -5px;
}

header .header-middle .shopping_cart > a:first-child:after {
  content: "\f0d7";
  line-height: 1;
  padding-top: 0 !important;
  margin-left: 5px;
  font-size: 14px;
  color: #3498db !important;
}

header .header-middle .shopping_cart > a:first-child b {
  color: #252a2f;
  text-shadow: none;
}

header .header-middle .shopping_cart > a:first-child b:after {
  content: ":";
  display: inline-block;
}

@media (max-width: 1229px) {
  header .header-middle .shopping_cart > a:first-child b {
    display: none !important;
  }
}

@media (max-width: 767px) {
  header .header-middle .shopping_cart > a:first-child b {
    display: block !important;
  }
}

@media (max-width: 480px) {
  header .header-middle .shopping_cart > a:first-child b {
    display: none !important;
  }
}

header .header-middle .shopping_cart > a:first-child b, header .header-middle .shopping_cart > a:first-child > span {
  float: left;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.3333333333rem;
  padding-left: 5px;
  display: inline-block;
}

header .header-middle .shopping_cart > a:first-child b.unvisible, header .header-middle .shopping_cart > a:first-child > span.unvisible {
  display: none;
}

header .header-middle .shopping_cart > a:first-child .ajax_cart_product_txt,
header .header-middle .shopping_cart > a:first-child .ajax_cart_product_txt_s {
  color: #3498db;
  padding-left: 3px;
}

header .header-middle .shopping_cart > a:first-child .ajax_cart_quantity {
  text-align: center;
  color: #3498db;
}

@media (max-width: 991px) and (min-width: 768px) {
  header .header-middle .shopping_cart > a:first-child .ajax_cart_quantity {
    position: absolute;
    top: 1px;
    left: 10px;
    background: #252a2f;
    text-align: center;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    color: #ffffff;
    padding: 0;
  }
}

header .header-middle .shopping_cart > a:first-child .ajax_cart_total,
header .header-middle .shopping_cart > a:first-child .ajax_cart_no_product {
  font-weight: normal;
  color: #3498db;
  display: inline-block;
  padding-top: 0px;
  white-space: nowrap;
}

header .header-middle .shopping_cart > a:first-child .ajax_cart_total {
  padding-left: 3px;
}

header .header-middle .shopping_cart > a:first-child .ajax_cart_total:before {
  content: "-";
  display: inline-block;
  padding-right: 3px;
}

header .header-middle .shopping_cart > a:first-child:hover:after {
  padding: 0 !important;
}

header#header .cart_block {
  top: calc(100% + 10px);
  left: 0;
  margin-left: -1px;
  min-width: 270px;
  background: #fff;
  border: 1px solid #3498db;
  color: #373737;
  /*-webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.4);
			box-shadow: 0 5px 10px rgba(0,0,0,0.4);*/
}

@media (max-width: 1229px) {
  header#header .cart_block {
    left: auto;
    right: -1px;
  }
}

@media (max-width: 767px) {
  header#header .cart_block {
    left: 0;
    right: auto;
    width: calc(100% + 57px);
  }
}

header#header .cart_block .products {
  padding: 20px 20px 0;
}

header#header .cart_block .cart_block_list .remove_link {
  right: 0;
  top: 0;
}

header#header .cart_block .cart_block_list .remove_link .ajax_cart_block_remove_link {
  color: #373737;
  width: 20px;
  height: 20px;
  text-align: right;
}

header#header .cart_block .cart_block_list .remove_link .ajax_cart_block_remove_link:hover {
  color: red;
}

header#header .cart_block .cart_block_list .remove_link .ajax_cart_block_remove_link:before {
  content: "\f146";
  font-size: 13px;
}

header#header .cart_block dt {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  padding: 0;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

header#header .cart_block dt.last_item {
  border: none;
}

header#header .cart_block .cart-images {
  width: 64px;
  margin-right: 15px;
}

header#header .cart_block .cart-prices {
  background: none;
}

header#header .cart_block .cart-buttons {
  background: none;
}

header#header .cart_block .cart-info .product-name .cart_block_product_name {
  color: #3498db;
}

header#header .cart_block .cart-info .product-name .cart_block_product_name:hover {
  color: #252a2f;
  text-decoration: underline;
}

header#header .cart_block .cart-info .product-name .quantity-formated {
  color: #252a2f;
}

header#header .cart_block .cart-info .product-name .quantity-formated .quantity {
  font-size: 1.1666666667rem;
}

header#header .cart_block .product-atributes a {
  font-size: 1.1666666667rem;
}

header#header .cart_block .product-atributes a:hover {
  color: #3498db;
}

header#header .cart_block .cart-prices span {
  font-weight: normal;
}

header#header .cart_block .cart-prices span:not(.price) {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.3333333333rem;
}

header#header .cart_block .cart-prices .cart-prices-line {
  border-color: #ddd;
}

header#header .cart_block .price {
  color: #252a2f;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
}

header#header .cart_block .price.cart_block_total {
  color: #3498db;
}

header#header .cart_block .cart-buttons {
  padding-top: 0;
}

header#header .cart_block .cart-buttons #button_order_cart {
  height: 34px;
  line-height: 34px !important;
  display: inline-block;
  background-color: #252a2f;
  text-transform: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  font-weight: normal;
  color: #ffffff !important;
  text-align: center;
  padding: 0 15px;
  border: none;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

header#header .cart_block .cart-buttons #button_order_cart span {
  font-size: inherit !important;
  color: inherit !important;
}

header#header .cart_block .cart-buttons #button_order_cart:after {
  /*content:'';
		display: block;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
	background-color: $colorBtn_1;
		transform: scale(0,0);
		opacity: 0;	
		border-radius: 0px;*/
}

header#header .cart_block .cart-buttons #button_order_cart:hover {
  color: #ffffff !important;
  background-color: #3498db;
  /*&:after {
			transition: all .3s ease;
			transform: scale(1,1);	
			opacity: 1;
		}*/
}

header#header .cart_block .cart-buttons #button_order_cart i {
  margin-left: 10px;
}

header#header .cart_block .cart-buttons #button_order_cart span {
  border: none;
  background: none;
  padding: 0;
}

header#header .banner a {
  max-width: 100%;
}

header#header .banner a img {
  max-width: 100%;
}

/*=========================================== CSS FOR FOOTER ===========================================================*/
.bc-top-footer {
  *zoom: 1;
  background: #252a2f;
}

.bc-top-footer:after, .bc-top-footer:before {
  content: "";
  display: table;
  clear: both;
}

.bc-top-footer #newsletter_block_left {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
  left: 25%;
}

@media (max-width: 1229px) {
  .bc-top-footer #newsletter_block_left {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 767px) {
  .bc-top-footer #newsletter_block_left {
    left: 0%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.bc-top-footer #newsletter_block_left .block_content {
  position: relative;
}

.bc-top-footer #newsletter_block_left .block_content .form-group:before {
  content: "\f0e0";
  font-family: "FontAweSome";
  font-size: 1.3333333333rem;
  display: inline-block;
  color: #8b8b8b;
  position: absolute;
  left: 10px;
  top: 11px;
}

.bc-top-footer #newsletter_block_left h4, .bc-top-footer #newsletter_block_left .h4, .bc-top-footer #newsletter_block_left #index .type-tab .nav-tabs li a, #index .type-tab .nav-tabs li .bc-top-footer #newsletter_block_left a {
  border: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 30px;
  padding-bottom: 18px;
  background: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 2rem;
  color: #ffffff;
  text-align: center;
  line-height: 1.2;
}

.bc-top-footer #newsletter_block_left h4 span, .bc-top-footer #newsletter_block_left .h4 span, .bc-top-footer #newsletter_block_left #index .type-tab .nav-tabs li a span, #index .type-tab .nav-tabs li .bc-top-footer #newsletter_block_left a span {
  display: block;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: none;
  padding-top: 5px;
}

.bc-top-footer #newsletter_block_left h4 + div, .bc-top-footer #newsletter_block_left .h4 + div, .bc-top-footer #newsletter_block_left #index .type-tab .nav-tabs li a + div, #index .type-tab .nav-tabs li .bc-top-footer #newsletter_block_left a + div {
  padding-left: 80px;
  padding-right: 80px;
}

@media (max-width: 1229px) {
  .bc-top-footer #newsletter_block_left h4 + div, .bc-top-footer #newsletter_block_left .h4 + div, .bc-top-footer #newsletter_block_left #index .type-tab .nav-tabs li a + div, #index .type-tab .nav-tabs li .bc-top-footer #newsletter_block_left a + div {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 767px) {
  .bc-top-footer #newsletter_block_left h4 + div, .bc-top-footer #newsletter_block_left .h4 + div, .bc-top-footer #newsletter_block_left #index .type-tab .nav-tabs li a + div, #index .type-tab .nav-tabs li .bc-top-footer #newsletter_block_left a + div {
    padding-left: 0;
    padding-right: 0;
  }
}

.bc-top-footer #newsletter_block_left h4 + div .form-group, .bc-top-footer #newsletter_block_left .h4 + div .form-group, .bc-top-footer #newsletter_block_left #index .type-tab .nav-tabs li a + div .form-group, #index .type-tab .nav-tabs li .bc-top-footer #newsletter_block_left a + div .form-group {
  position: relative;
  margin-bottom: 0;
}

.bc-top-footer #newsletter_block_left input.newsletter-input {
  height: 40px;
  line-height: 40px;
  border: 2px solid #3498db;
  padding-left: 30px;
  padding-right: 105px;
  max-width: 100%;
  border-radius: 0;
}

@media (max-width: 480px) {
  .bc-top-footer #newsletter_block_left input.newsletter-input {
    border-radius: 3px 3px 0 0;
    padding-right: 30px;
  }
}

.bc-top-footer #newsletter_block_left button[type="submit"] {
  border: none;
  border-radius: 0;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.3333333333rem;
  text-transform: uppercase;
  background: #3498db;
  color: #ffffff;
  height: 40px;
  padding: 0 15px;
  position: absolute;
  right: 0;
  top: 0;
}

.bc-top-footer #newsletter_block_left button[type="submit"]:hover {
  background: #4aa3df;
  color: #fff !important;
}

@media (max-width: 480px) {
  .bc-top-footer #newsletter_block_left button[type="submit"] {
    position: relative;
    width: 100%;
    border-top-left-radius: 0;
    border-radius: 0 0 3px 3px;
  }
}

.footer-container {
  background: none;
  background-color: #fff;
  padding-top: 35px;
  padding-bottom: 40px;
  border-bottom: 1px solid #f3f3f3;
}

@media (max-width: 767px) {
  .footer-container {
    padding-top: 40px;
    padding-bottom: 30px;
  }
}

.footer-container section ul {
  margin-bottom: 0;
}

.footer-container section h4, .footer-container section .h4, .footer-container section #index .type-tab .nav-tabs li a, #index .type-tab .nav-tabs li .footer-container section a {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #252a2f;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 20px;
  position: relative;
}

.footer-container section h4 a, .footer-container section .h4 a, .footer-container section #index .type-tab .nav-tabs li a a, #index .type-tab .nav-tabs li .footer-container section a a {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  color: #252a2f;
}

.footer-container section h4:after, .footer-container section .h4:after, .footer-container section #index .type-tab .nav-tabs li a:after, #index .type-tab .nav-tabs li .footer-container section a:after {
  content: "";
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 80px;
  height: 1px;
  background-color: #f3f3f3;
}

@media (max-width: 991px) and (min-width: 768px) {
  .footer-container section h4, .footer-container section .h4, .footer-container section #index .type-tab .nav-tabs li a, #index .type-tab .nav-tabs li .footer-container section a {
    font-size: 1.6666666667rem;
  }
  .footer-container section h4 a, .footer-container section .h4 a, .footer-container section #index .type-tab .nav-tabs li a a, #index .type-tab .nav-tabs li .footer-container section a a {
    font-size: inherit;
  }
}

@media (max-width: 767px) {
  .footer-container section h4, .footer-container section .h4, .footer-container section #index .type-tab .nav-tabs li a, #index .type-tab .nav-tabs li .footer-container section a {
    width: 100%;
  }
  .footer-container section h4:after, .footer-container section .h4:after, .footer-container section #index .type-tab .nav-tabs li a:after, #index .type-tab .nav-tabs li .footer-container section a:after {
    display: none !important;
  }
  .footer-container section h4:before, .footer-container section .h4:before, .footer-container section #index .type-tab .nav-tabs li a:before, #index .type-tab .nav-tabs li .footer-container section a:before {
    content: "\f13a";
    font-family: "FontAweSome";
    font-size: 13px;
    display: block;
    position: absolute;
    right: 0;
    top: 3px;
  }
  .footer-container section h4.active, .footer-container section .active.h4, .footer-container section #index .type-tab .nav-tabs li a.active, #index .type-tab .nav-tabs li .footer-container section a.active {
    color: #3498db;
  }
  .footer-container section h4.active a, .footer-container section .active.h4 a, .footer-container section #index .type-tab .nav-tabs li a.active a, #index .type-tab .nav-tabs li .footer-container section a.active a {
    color: inherit;
  }
  .footer-container section h4.active:before, .footer-container section .active.h4:before, .footer-container section #index .type-tab .nav-tabs li a.active:before, #index .type-tab .nav-tabs li .footer-container section a.active:before {
    content: "\f139";
  }
}

.footer-container section:not(#block_contact_infos) ul li {
  padding: 2px 0;
  line-height: 2.1;
}

.footer-container section:not(#block_contact_infos) ul li a {
  color: #252a2f;
}

.footer-container section:not(#block_contact_infos) ul li a:before {
  content: "\f0da";
  font-family: "FontAweSome";
  display: none;
  font-size: 16px;
  color: inherit;
  margin-right: 7px;
}

.footer-container section:not(#block_contact_infos) ul li a:hover {
  text-decoration: underline;
  color: #3498db;
  font-weight: bold;
  padding-left: 0px;
}

.footer-container section#block_contact_infos > div {
  padding-left: 0;
}

.footer-container section#block_contact_infos > div ul li {
  padding-bottom: 15px;
  font-size: 1rem;
  color: #252a2f;
}

.footer-container section#block_contact_infos > div ul li i {
  display: none;
}

.footer-container section#block_contact_infos > div ul li span {
  color: #3498db;
  font-weight: bold;
}

.footer-container #social_block h4, .footer-container #social_block .h4, .footer-container #social_block #index .type-tab .nav-tabs li a, #index .type-tab .nav-tabs li .footer-container #social_block a {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  text-transform: uppercase !important;
  color: #252a2f;
  line-height: 1 !important;
  margin-top: 0;
  margin-bottom: 20px !important;
  position: relative;
  font-size: 1.5rem !important;
  float: none !important;
}

.footer-container #social_block h4 a, .footer-container #social_block .h4 a, .footer-container #social_block #index .type-tab .nav-tabs li a a, #index .type-tab .nav-tabs li .footer-container #social_block a a {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  color: #252a2f;
}

.footer-container #social_block h4:after, .footer-container #social_block .h4:after, .footer-container #social_block #index .type-tab .nav-tabs li a:after, #index .type-tab .nav-tabs li .footer-container #social_block a:after {
  content: "";
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 80px;
  height: 1px;
  background-color: #f3f3f3;
}

@media (max-width: 991px) and (min-width: 768px) {
  .footer-container #social_block h4, .footer-container #social_block .h4, .footer-container #social_block #index .type-tab .nav-tabs li a, #index .type-tab .nav-tabs li .footer-container #social_block a {
    font-size: 1.6666666667rem;
  }
  .footer-container #social_block h4 a, .footer-container #social_block .h4 a, .footer-container #social_block #index .type-tab .nav-tabs li a a, #index .type-tab .nav-tabs li .footer-container #social_block a a {
    font-size: inherit;
  }
}

@media (max-width: 767px) {
  .footer-container #social_block h4, .footer-container #social_block .h4, .footer-container #social_block #index .type-tab .nav-tabs li a, #index .type-tab .nav-tabs li .footer-container #social_block a {
    width: 100%;
  }
  .footer-container #social_block h4:after, .footer-container #social_block .h4:after, .footer-container #social_block #index .type-tab .nav-tabs li a:after, #index .type-tab .nav-tabs li .footer-container #social_block a:after {
    display: none !important;
  }
  .footer-container #social_block h4:before, .footer-container #social_block .h4:before, .footer-container #social_block #index .type-tab .nav-tabs li a:before, #index .type-tab .nav-tabs li .footer-container #social_block a:before {
    content: "\f06c";
    font-family: "FontAweSome";
    font-size: 14px;
    display: block;
    position: absolute;
    right: 0;
    top: 3px;
  }
  .footer-container #social_block h4.active, .footer-container #social_block .active.h4, .footer-container #social_block #index .type-tab .nav-tabs li a.active, #index .type-tab .nav-tabs li .footer-container #social_block a.active {
    color: #3498db;
  }
}

.footer-container #social_block ul {
  *zoom: 1;
}

.footer-container #social_block ul:after, .footer-container #social_block ul:before {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 767px) {
  .footer-container #social_block ul {
    text-align: center;
    padding-top: 30px;
  }
}

.footer-container #social_block ul li {
  display: block;
  float: none !important;
  text-align: left !important;
  /*width: 40px;
				height: 40px;	
				border-radius: 3px;
				padding-bottom: 0 !important;		
				margin-right: 10px;
				border: 1px solid #f3f3f3;
				background-color: transparent;*/
}

.footer-container #social_block ul li span {
  display: block !important;
  font-size: 1rem !important;
  color: inherit !important;
}

.footer-container #social_block ul li a {
  display: block;
  width: 100%;
  height: 100%;
  color: #252a2f !important;
  padding-top: 0px;
}

.footer-container #social_block ul li a:before {
  margin-right: 0 !important;
  font-size: 14px !important;
  line-height: 1;
  display: none !important;
}

.footer-container #social_block ul li a:hover {
  color: #3498db !important;
}

@media (max-width: 991px) and (min-width: 768px) {
  .footer-container #social_block ul li {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }
  .footer-container #social_block ul li a {
    padding-top: 0px;
  }
  .footer-container #social_block ul li a:before {
    font-size: 12px !important;
  }
}

@media (max-width: 767px) {
  .footer-container #social_block ul li {
    float: none !important;
    display: inline-block;
  }
}

.footer-container .bc_tags_block h4, .footer-container .bc_tags_block .h4, .footer-container .bc_tags_block #index .type-tab .nav-tabs li a, #index .type-tab .nav-tabs li .footer-container .bc_tags_block a {
  text-transform: uppercase;
  color: #252a2f;
  margin-bottom: 42px;
}

.footer-container .bc_tags_block a {
  text-transform: uppercase;
  font-size: 0.9166666667rem;
  border: 1px solid #ededed;
  padding: 0 18px;
  float: left;
  margin-right: 10px;
  margin-bottom: 12px;
  min-height: 30px;
  padding-top: 5px;
}

@media (max-width: 991px) {
  .footer-container .bc_tags_block a {
    padding-right: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.footer-container .bc_tags_block a:hover {
  background: #373737;
  color: #fff;
}

.bc-bottom-footer {
  background: #252a2f;
  padding: 18px 0 15px;
}

.bc-bottom-footer .copy-right {
  text-transform: none;
  color: #ffffff;
}

@media (max-width: 767px) {
  .bc-bottom-footer .copy-right {
    text-align: center;
    margin-bottom: 10px;
  }
}

.bc-bottom-footer .bc-bewaer-ft {
  text-align: right;
}

@media (max-width: 767px) {
  .bc-bottom-footer .bc-bewaer-ft {
    text-align: center;
  }
}

.bc-bottom-footer .bc-bewaer-ft a {
  display: inline-block;
}

/*=============================== CSS FOR MODAL / POPUP / LIGHTBOX ======================================================*/
.fancybox-close, header .header-middle #search_block_top #show_result #closedform, #layer_cart .cross {
  width: 30px;
  height: 30px;
  font-size: 0;
  top: 0;
  right: 0;
  background: none;
  border: 1px solid #3498db;
  background: url("../images/close.png") no-repeat center #3498db;
  text-align: center;
  transform: translate3d(0, 0, 0);
  transition: all .35s;
}

.fancybox-close:hover, header .header-middle #search_block_top #show_result #closedform:hover, #layer_cart .cross:hover {
  background: url("../images/close.png") no-repeat center #75b9e7;
}

.fancybox-skin {
  border-radius: 0;
}

/*Newsletter Popup*/
.newsletter-popup #newsletter-popup {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.newsletter-popup .fancybox-skin {
  padding: 0 !important;
}

.newsletter-popup .fancybox-inner {
  overflow: visible !important;
}

.newsletter-popup .popup-image {
  float: left;
  display: block;
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.newsletter-popup .popup-image img {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: auto;
  display: none;
}

.newsletter-popup .block h4, .newsletter-popup .block .h4, .newsletter-popup .block #index .type-tab .nav-tabs li a, #index .type-tab .nav-tabs li .newsletter-popup .block a {
  background: none;
  border: none;
  padding: 0;
  margin: 0 0 30px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 3rem;
  font-weight: bold;
  color: #252a2f;
}

.newsletter-popup .popup-mail-content {
  padding: 65px 40px 40px;
  overflow: hidden;
  color: #252a2f;
  text-align: center;
  position: relative !important;
  z-index: 10;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
}

@media (max-width: 767px) {
  .newsletter-popup .popup-mail-content {
    padding: 50px 15px 30px;
  }
  .newsletter-popup .popup-mail-content .title:after {
    display: none;
  }
}

.newsletter-popup .block_content {
  padding: 0 40px;
  margin-bottom: 40px;
  overflow: hidden;
  position: relative !important;
  text-align: center;
  z-index: 10;
  font-size: 0;
}

.newsletter-popup .block_content input {
  vertical-align: top;
}

.newsletter-popup .block_content .inputNew {
  padding: 0 10px;
  float: none;
  width: 300px;
}

.newsletter-popup .block_content input[type="submit"] {
  height: 34px;
  line-height: 34px !important;
  display: inline-block;
  background-color: #252a2f;
  text-transform: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  font-weight: normal;
  color: #ffffff !important;
  text-align: center;
  padding: 0 15px;
  border: none;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-left: 5px;
  text-transform: uppercase;
  border: none !important;
  transition: all 0.35s;
}

.newsletter-popup .block_content input[type="submit"] span {
  font-size: inherit !important;
  color: inherit !important;
}

.newsletter-popup .block_content input[type="submit"]:after {
  /*content:'';
		display: block;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
	background-color: $colorBtn_1;
		transform: scale(0,0);
		opacity: 0;	
		border-radius: 0px;*/
}

.newsletter-popup .block_content input[type="submit"]:hover {
  color: #ffffff !important;
  background-color: #3498db;
  /*&:after {
			transition: all .3s ease;
			transform: scale(1,1);	
			opacity: 1;
		}*/
}

@media (max-width: 480px) {
  .newsletter-popup .block_content {
    padding: 0 15px;
  }
  .newsletter-popup .block_content .inputNew {
    width: 100%;
  }
  .newsletter-popup .block_content input[type="submit"] {
    margin-top: 10px;
    width: 30%;
    margin-left: 0;
  }
}

/*New Comment Popup*/
#new_comment_form .page-subheading, #new_comment_form .category #center_column .content_scene_cat_bg .category-name, .category #center_column .content_scene_cat_bg #new_comment_form .category-name, #new_comment_form .category #center_column #subcategories .subcategory-heading, .category #center_column #subcategories #new_comment_form .subcategory-heading, #new_comment_form .product .pb-center-column h1, .product .pb-center-column #new_comment_form h1, #new_comment_form .product .pb-center-column .h1, .product .pb-center-column #new_comment_form .h1, #new_comment_form .module-bcblog-post .out_related_products .title, .module-bcblog-post .out_related_products #new_comment_form .title, #new_comment_form .module-bcblog-post .blog-comment-list .title, .module-bcblog-post .blog-comment-list #new_comment_form .title, #new_comment_form .module-bcblog-post .related_posts .title, .module-bcblog-post .related_posts #new_comment_form .title, #new_comment_form .module-bcblog-post .pl_comment_form .title, .module-bcblog-post .pl_comment_form #new_comment_form .title {
  margin-top: 0;
}

#new_comment_form .product img {
  width: 70px;
  height: auto;
  float: left;
  border: none;
  margin-right: 10px;
}

#new_comment_form .product .product_desc .product_name {
  padding-top: 0;
  margin-bottom: 0;
  font-size: 1.1666666667rem;
  color: #252a2f;
  text-transform: uppercase;
}

#new_comment_form .new_comment_form_content {
  background: none;
  padding-left: 0;
  padding-right: 15px;
}

@media (max-width: 767px) {
  #new_comment_form .new_comment_form_content {
    padding-left: 15px;
  }
}

#new_comment_form .new_comment_form_content input {
  height: 45px;
}

#new_comment_form .new_comment_form_content #criterions_list {
  padding-bottom: 0;
}

#new_comment_form .new_comment_form_content .star_content {
  font-size: 0;
  float: none;
  line-height: 1;
}

#new_comment_form .new_comment_form_content .star_content .cancel {
  float: none;
  display: inline-block;
}

#new_comment_form .new_comment_form_content .star_content .star {
  vertical-align: bottom;
  line-height: 16px;
}

#new_comment_form #new_comment_form_footer {
  padding-top: 15px;
}

#new_comment_form #new_comment_form_footer .fr {
  margin-top: -8px;
  font-size: 1.1666666667rem;
}

#new_comment_form #new_comment_form_footer .fr .closefb {
  font-weight: bold;
}

/*Layer Cart Popup*/
.layer_cart_overlay {
  z-index: 100;
}

#layer_cart {
  border-radius: 0;
  border: 10px solid #252a2f;
  z-index: 102;
  padding: 0px;
  left: 8%;
  margin-right: 0;
  margin-left: 0;
  -webkit-animation-duration: 0.35s;
  animation-duration: 0.35s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
}

#layer_cart > div {
  overflow: hidden;
  background: #fff;
}

#layer_cart > div.clearfix {
  padding-top: 20px;
  padding-bottom: 20px;
}

#layer_cart .cross {
  right: -10px;
  top: -10px;
}

#layer_cart .cross:before {
  display: none;
}

#layer_cart .layer_cart_product {
  padding: 0 20px 20px;
}

#layer_cart .layer_cart_product .title {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.4;
  padding-bottom: 13px;
  margin-bottom: 17px;
  border-bottom: 1px solid #525d68;
  line-height: 1;
}

#layer_cart .layer_cart_product .title i {
  margin-bottom: 0px;
  font-size: 1.5rem;
  line-height: 1;
}

#layer_cart .layer_cart_product .product-image-container {
  padding: 0;
  border: none;
}

#layer_cart .layer_cart_product .product-image-container .layer_cart_img {
  border: 1px solid #ddd;
}

#layer_cart .layer_cart_product .layer_cart_product_info {
  padding-top: 3px;
}

#layer_cart .layer_cart_product .layer_cart_product_info .product-name {
  text-align: left;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  color: #3498db;
  margin-bottom: 0;
}

#layer_cart .layer_cart_product .layer_cart_product_info > div {
  margin: 5px 0;
}

#layer_cart .layer_cart_product .layer_cart_product_info > div strong {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  font-weight: normal;
}

#layer_cart .layer_cart_product .layer_cart_product_info > div #layer_cart_product_quantity {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
}

#layer_cart .layer_cart_product .layer_cart_product_info #layer_cart_product_price {
  color: #3498db;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  padding-left: 10px;
}

#layer_cart .layer_cart_cart {
  border-color: #525d68;
  background: #fff;
  min-height: 0;
  position: relative;
  padding-top: 0;
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 991px) {
  #layer_cart .layer_cart_cart {
    padding-top: 20px;
  }
}

#layer_cart .layer_cart_cart .title {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1;
  border-color: #525d68;
}

#layer_cart .layer_cart_cart .button-container {
  padding: 30px;
}

#layer_cart .layer_cart_cart .button-container .continue {
  text-shadow: none;
  color: #fff;
}

#layer_cart .layer_cart_cart .button-container .continue i {
  display: none;
}

@media (max-width: 767px) {
  #layer_cart .layer_cart_cart .button-container .continue {
    width: 100%;
  }
}

#layer_cart .layer_cart_cart .button-container a.btn {
  float: right;
  height: 34px;
  line-height: 34px !important;
  display: inline-block;
  background-color: #252a2f;
  text-transform: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  font-weight: normal;
  color: #ffffff !important;
  text-align: center;
  padding: 0 15px;
  border: none;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

#layer_cart .layer_cart_cart .button-container a.btn span {
  font-size: inherit !important;
  color: inherit !important;
}

#layer_cart .layer_cart_cart .button-container a.btn:after {
  /*content:'';
		display: block;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
	background-color: $colorBtn_1;
		transform: scale(0,0);
		opacity: 0;	
		border-radius: 0px;*/
}

#layer_cart .layer_cart_cart .button-container a.btn:hover {
  color: #ffffff !important;
  background-color: #3498db;
  /*&:after {
			transition: all .3s ease;
			transform: scale(1,1);	
			opacity: 1;
		}*/
}

#layer_cart .layer_cart_cart .button-container a.btn i {
  display: none;
}

#layer_cart .layer_cart_cart .button-container a.btn span {
  border: none;
  padding: 0;
}

@media (max-width: 767px) {
  #layer_cart .layer_cart_cart .button-container a.btn {
    float: left;
    width: 100%;
  }
}

#layer_cart .layer_cart_cart .layer_cart_row > span {
  font-weight: normal;
  color: #373737;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  padding-left: 10px;
}

#layer_cart .layer_cart_cart .layer_cart_row > span.ajax_block_cart_total {
  color: #3498db;
}

#layer_cart .layer_cart_cart .layer_cart_row strong {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  font-weight: normal;
  min-width: 130px;
  display: inline-block !important;
}

#layer_cart .crossseling h2, #layer_cart .crossseling .h2 {
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.5rem;
  margin-top: 0;
}

#layer_cart .crossseling .crossseling-content {
  border-color: #eee;
}

#layer_cart .crossseling #blockcart_list {
  width: 100%;
  max-width: 100%;
  overflow: visible;
}

#layer_cart .crossseling #blockcart_list .bx-wrapper {
  border: 1px solid #eee;
}

#layer_cart .crossseling #blockcart_list ul {
  *zoom: 1;
  margin-bottom: 0;
}

#layer_cart .crossseling #blockcart_list ul:after, #layer_cart .crossseling #blockcart_list ul:before {
  content: "";
  display: table;
  clear: both;
}

#layer_cart .crossseling #blockcart_list ul li {
  padding-bottom: 20px;
  text-align: center;
  border-right: 1px solid #eee;
}

#layer_cart .crossseling #blockcart_list ul li .product-image-container {
  border: none;
}

#layer_cart .crossseling #blockcart_list ul li .product-name {
  margin-bottom: 0;
  text-align: center;
}

#layer_cart .crossseling #blockcart_list ul li .price {
  color: #ff3837;
}

#layer_cart .crossseling #blockcart_list .bx-prev, #layer_cart .crossseling #blockcart_list .bx-next {
  display: block !important;
  width: 30px !important;
  height: 30px !important;
  text-indent: 0 !important;
  top: 50%;
  margin-top: -15px !important;
}

#layer_cart .crossseling #blockcart_list .bx-prev {
  left: -15px !important;
  margin-left: 0 !important;
}

#layer_cart .crossseling #blockcart_list .bx-prev:before {
  color: #eee;
}

#layer_cart .crossseling #blockcart_list .bx-prev:after {
  display: none !important;
}

#layer_cart .crossseling #blockcart_list .bx-next {
  right: -15px !important;
  margin-left: 0 !important;
}

#layer_cart .crossseling #blockcart_list .bx-next:before {
  color: #eee;
}

#layer_cart .crossseling #blockcart_list .bx-next:after {
  display: none !important;
}

/*Error Popup*/
.fancybox-error {
  padding-top: 30px;
  padding-bottom: 30px;
  min-width: 300px;
  white-space: normal;
}

/*Fancybox preloader*/
.ls-vpcontainer img {
  display: none;
}

#fancybox-loading, .ls-loading-container, .columns-container ul.product_list > p, .ls-vpcontainer .ls-playvideo {
  background: none !important;
  border: 3px solid transparent;
  border-top-color: #3498db;
  border-radius: 50%;
  width: 150px !important;
  height: 150px !important;
  margin-left: -75px !important;
  margin-top: -75px !important;
  animation: spin 2s linear infinite;
  transform: none;
}

@media (max-width: 1229px) {
  #fancybox-loading, .ls-loading-container, .columns-container ul.product_list > p, .ls-vpcontainer .ls-playvideo {
    width: 100px !important;
    height: 100px !important;
    margin-left: -50px !important;
    margin-top: -50px !important;
  }
}

#fancybox-loading:before, .ls-loading-container:before, .columns-container ul.product_list > p:before, .ls-vpcontainer .ls-playvideo:before {
  content: '';
  border-radius: 50%;
  border: 3px solid transparent;
  top: 5px;
  bottom: 5px;
  left: 5px;
  right: 5px;
  position: absolute;
  display: block;
  animation: spin 3s linear infinite;
  border-top-color: #ef498b;
}

#fancybox-loading:after, .ls-loading-container:after, .columns-container ul.product_list > p:after, .ls-vpcontainer .ls-playvideo:after {
  content: '';
  border-radius: 50%;
  border: 3px solid transparent;
  top: 15px;
  bottom: 15px;
  left: 15px;
  right: 15px;
  position: absolute;
  display: block;
  animation: spin 1.5s linear infinite;
  border-top-color: #ffc107;
}

#fancybox-loading div, .ls-loading-container div, .columns-container ul.product_list > p div, .ls-vpcontainer .ls-playvideo div {
  display: none;
}

.zoomPreload {
  font-size: 0;
  position: relative;
  background: none;
  border: 3px solid transparent;
  border-top-color: #3498db;
  border-radius: 50%;
  width: 150px !important;
  height: 150px !important;
  animation: spin 2s linear infinite;
}

@media (max-width: 1229px) {
  .zoomPreload {
    width: 100px !important;
    height: 100px !important;
  }
}

.zoomPreload:before {
  content: '';
  border-radius: 50%;
  border: 3px solid transparent;
  top: 5px;
  bottom: 5px;
  left: 5px;
  right: 5px;
  position: absolute;
  display: block;
  animation: spin 3s linear infinite;
  border-top-color: #ef498b;
}

.zoomPreload:after {
  content: '';
  border-radius: 50%;
  border: 3px solid transparent;
  top: 15px;
  bottom: 15px;
  left: 15px;
  right: 15px;
  position: absolute;
  display: block;
  animation: spin 1.5s linear infinite;
  border-top-color: #ffc107;
}

/* CSS FOR MEGAMENU */
.link-with--icon:before, .bc-menu-container .menu-item.menu-v1 .column-item a:before, .bc-menu-container .menu-item.menu-v2 .level-2 > a:before {
  content: "\f054";
  display: inline-block;
  font-family: "FontAweSome";
  font-size: 1rem;
  color: #3498db;
  margin-right: 5px;
  position: relative;
}

#header > .mega-menu {
  background: #3498db;
}

@media (max-width: 767px) {
  #header > .mega-menu .mega-menu {
    display: none;
  }
}

.bc-menu-container .title-menu-mobile {
  display: none;
  width: 46px;
  height: 46px;
  border: 1px solid #3498db;
  border-radius: 3px;
  background: #3498db;
  font-size: 0;
  text-align: center;
  line-height: 46px;
  color: #fff;
  cursor: pointer;
}

.bc-menu-container .title-menu-mobile:before {
  content: "\f0c9";
  font-family: "FontAweSome";
  font-size: 18px;
}

@media (max-width: 767px) {
  .bc-menu-container .title-menu-mobile {
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 0;
    margin-top: -46px;
  }
}

.bc-menu-container .menu-content {
  height: 50px;
  margin-bottom: 0;
}

.bc-menu-container .menu-content img {
  width: 100%;
}

.bc-menu-container .menu-content > li {
  display: inline-block;
  float: left;
  color: #fff;
  text-transform: uppercase;
  height: 100%;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  position: relative;
}

.bc-menu-container .menu-content > li a {
  display: block;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.3333333333rem;
  color: #252a2f;
  font-weight: normal;
  text-transform: uppercase;
}

.bc-menu-container .menu-content > li > a {
  height: 100%;
  line-height: 50px;
  padding: 0 15px;
  font-size: 1.1666666667rem;
  font-family: "Oswald", sans-serif;
  font-weight: normal;
  color: #ffffff;
}

@media (max-width: 991px) {
  .bc-menu-container .menu-content > li > a {
    padding: 0 10px;
  }
}

.bc-menu-container .menu-content > li:hover {
  background: #252a2f;
}

.bc-menu-container .menu-content > li:hover > a {
  color: #ffffff;
}

.bc-menu-container .menu-content > li:hover > a:after {
  color: #fff;
}

.bc-menu-container {
  /*Share properties between horizontal menu and vertical menu*/
  /*Horizontal Menu unique props*/
  /*Vertical menu unique props*/
}

.bc-menu-container .dropdown {
  display: none;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fff;
  border: 1px solid #f3f3f3;
}

@media (max-width: 991px) {
  .bc-menu-container .dropdown {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.bc-menu-container .icon-drop-mobile:before {
  content: "\f054";
  color: #3498db;
  display: block;
  font-size: 1rem;
  font-family: "FontAweSome";
}

.bc-menu-container .content-drop {
  *zoom: 1;
  padding-left: 0;
  padding-right: 0;
}

.bc-menu-container .content-drop:after, .bc-menu-container .content-drop:before {
  content: "";
  display: table;
  clear: both;
}

.bc-menu-container .menu-column {
  padding: 0 30px;
}

@media (max-width: 991px) {
  .bc-menu-container .menu-column {
    padding: 0 15px;
  }
}

.bc-menu-container .column-item {
  *zoom: 1;
  color: #252a2f;
}

.bc-menu-container .column-item:after, .bc-menu-container .column-item:before {
  content: "";
  display: table;
  clear: both;
}

.bc-menu-container .column-item li:hover > .icon-drop-mobile {
  color: #3498db;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.bc-menu-container .column-item li:hover > .column-item {
  visibility: visible;
  opacity: 1;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.bc-menu-container .column-item a {
  padding: 10px 0;
  line-height: normal;
  text-transform: none;
  font-family: "Open Sans", sans-serif;
}

.bc-menu-container .column-item a:hover {
  color: #3498db;
  text-decoration: underline;
}

.bc-menu-container .column-item h3, .bc-menu-container .column-item .h3 {
  margin-top: 0;
  margin-bottom: 15px;
}

.bc-menu-container .column-item .parent {
  position: relative;
}

.bc-menu-container .column-item .parent .icon-drop-mobile {
  position: absolute;
  right: 0;
  top: 12px;
}

.bc-menu-container .column-item .parent .icon-drop-mobile:after {
  content: "";
  display: block;
  position: absolute;
  left: -10px;
  top: -10px;
  bottom: -10px;
  right: -10px;
  z-index: 2;
}

.bc-menu-container .column-item .parent .column-item {
  position: absolute;
  left: calc(100% + 30px);
  top: 0;
  min-width: 195px;
  background: #fff;
  padding: 0 25px;
  padding-left: 25%;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.bc-menu-container .column-item .parent .column-item:before {
  content: '';
  display: block;
  width: 35px;
  height: 100%;
  position: absolute;
  left: -35px;
  top: 0;
}

@media (max-width: 991px) {
  .bc-menu-container .column-item .parent .column-item {
    min-width: 140px;
    left: calc(100% + 15px);
    padding: 0 15px;
  }
  .bc-menu-container .column-item .parent .column-item:before {
    width: 20px;
    left: -20px;
  }
}

.bc-menu-container .menu-item:hover .dropdown {
  display: block;
}

.bc-menu-container .menu-item h3, .bc-menu-container .menu-item .h3 {
  padding-bottom: 18px;
  margin-bottom: 20px;
  border-bottom: 1px solid #525d68;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: normal;
  color: #252a2f;
}

.bc-menu-container .menu-item h3:before, .bc-menu-container .menu-item .h3:before {
  content: '';
  display: none;
  width: 5px;
  height: 5px;
  background: #3498db;
  margin-right: 7px;
  position: relative;
  top: -3px;
}

.bc-menu-container .menu-item .ajax_block_product {
  *zoom: 1;
  padding: 0;
}

.bc-menu-container .menu-item .ajax_block_product:after, .bc-menu-container .menu-item .ajax_block_product:before {
  content: "";
  display: table;
  clear: both;
}

.bc-menu-container .menu-item .ajax_block_product .product-container {
  *zoom: 1;
}

.bc-menu-container .menu-item .ajax_block_product .product-container:after, .bc-menu-container .menu-item .ajax_block_product .product-container:before {
  content: "";
  display: table;
  clear: both;
}

.bc-menu-container .menu-item .ajax_block_product .product-container .left-block {
  border: none;
}

.bc-menu-container .menu-item .ajax_block_product .product-container .left-block .product_img_link {
  padding: 0;
}

.bc-menu-container .menu-item .ajax_block_product .product-container .right-block .product-name {
  padding: 0;
  margin: 0;
}

.bc-menu-container .menu-item .ajax_block_product .product-container .right-block .product-name a {
  color: inherit;
  font-size: 1.5rem;
  margin: 10px 0;
  padding: 0;
}

.bc-menu-container .menu-item.menu-v1 .dropdown {
  background-image: url("../images/mega_menu_bg_img_1.jpg");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto 120%;
}

@media (max-width: 991px) {
  .bc-menu-container .menu-item.menu-v1 .dropdown {
    background-size: auto 100%;
  }
}

.bc-menu-container .menu-item.menu-v1 .content-drop {
  overflow: hidden;
}

.bc-menu-container .menu-item.menu-v1 .column-item {
  border-right: 1px solid #eee;
  padding-bottom: 1000px;
  margin-bottom: -1000px;
  padding-right: 30px;
}

.bc-menu-container .menu-item.menu-v1 .menu-column {
  padding-right: 0;
}

.bc-menu-container .menu-item.menu-v1 .menu-column:last-child .column-item, .bc-menu-container .menu-item.menu-v1 .menu-column:nth-child(3) .column-item {
  border: none;
}

.bc-menu-container .menu-item.menu-v2 .parent .icon-drop-mobile {
  display: none;
}

.bc-menu-container .menu-item.menu-v2 .parent .column-item {
  position: relative;
  left: 0;
  top: 0;
  visibility: visible;
  opacity: 1;
  padding-left: 0;
  min-width: 0;
  white-space: nowrap;
  box-shadow: none;
}

.bc-menu-container .menu-item.menu-v2 .parent .column-item li a {
  padding: 5px 0;
  color: #252a2f;
  font-size: 1.1666666667rem;
  padding-left: 15px;
}

@media (max-width: 991px) {
  .bc-menu-container .menu-item.menu-v2 .parent .column-item li a {
    padding-left: 0;
  }
}

.bc-menu-container .menu-item.menu-v2 .parent .column-item li a:before {
  font-size: 1rem;
}

.bc-menu-container .menu-item.menu-v2 .parent .column-item li a:hover {
  color: #3498db;
  padding-left: 15px;
}

@media (max-width: 991px) {
  .bc-menu-container .menu-item.menu-v2 .parent .column-item li a:hover {
    padding-left: 0;
  }
}

.bc-menu-container .menu-item.menu-v3 img {
  margin-bottom: 20px;
}

.bc-menu-container .menu-item.menu-v4 .dropdown {
  background-image: url("../images/mega_menu_bg_img_2.jpg");
  background-repeat: no-repeat;
  background-position: right bottom;
  -webkit-background-size: 30%;
  background-size: 30%;
}

.bc-menu-container .menu-item.menu-v4 .content-drop {
  overflow: hidden;
}

.bc-menu-container .menu-item.menu-v4 .column-item {
  border-right: 1px solid #eee;
  padding-bottom: 1000px;
  margin-bottom: -1000px;
  padding-right: 30px;
}

.bc-menu-container .menu-item.menu-v4 .menu-column {
  padding-right: 0;
}

.bc-menu-container .menu-item.menu-v4 .menu-column:last-child .column-item, .bc-menu-container .menu-item.menu-v4 .menu-column:nth-child(3) .column-item {
  border: none;
}

.bc-menu-container .menu-item.menu-v4 .ajax_block_product .product-container {
  border: none;
  padding: 0;
}

.bc-menu-container .menu-item.menu-v4 .ajax_block_product .left-block {
  width: 64px;
  margin-right: 15px;
  float: left;
}

.bc-menu-container .menu-item.menu-v4 .ajax_block_product .right-block {
  float: left;
  padding-top: 0;
  width: calc(100% - 79%);
}

.bc-menu-container .menu-item.menu-v4 .ajax_block_product .right-block .product-name {
  text-align: left;
  margin-top: 0;
  padding: 0;
}

.bc-menu-container .menu-item.menu-v4 .ajax_block_product .right-block .product-name a {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.3333333333rem;
  color: #252a2f;
  text-transform: none;
  padding: 0;
  margin: 10px 0 7px;
}

.bc-menu-container .menu-item.menu-v4 .ajax_block_product .right-block .content_price {
  text-align: left;
  padding-top: 0;
}

.bc-menu-container .menu-item.menu-v4 .ajax_block_product .right-block .content_price .price {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  color: #3498db;
}

.bc-menu-container#megamenu_top {
  /*grid*/
}

.bc-menu-container#megamenu_top .col-sm-12 {
  width: 1170px;
}

@media (max-width: 1229px) {
  .bc-menu-container#megamenu_top .col-sm-12 {
    width: 940px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container#megamenu_top .col-sm-12 {
    width: 720px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container#megamenu_top .col-sm-12 {
    width: 100%;
  }
}

.bc-menu-container#megamenu_top .col-sm-11 {
  width: 1072.5px;
}

@media (max-width: 1229px) {
  .bc-menu-container#megamenu_top .col-sm-11 {
    width: 861.666666667px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container#megamenu_top .col-sm-11 {
    width: 660px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container#megamenu_top .col-sm-11 {
    width: 100%;
  }
}

.bc-menu-container#megamenu_top .col-sm-10 {
  width: 975px;
}

@media (max-width: 1229px) {
  .bc-menu-container#megamenu_top .col-sm-10 {
    width: 783.333333333px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container#megamenu_top .col-sm-10 {
    width: 600px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container#megamenu_top .col-sm-10 {
    width: 100%;
  }
}

.bc-menu-container#megamenu_top .col-sm-9 {
  width: 877.5px;
}

@media (max-width: 1229px) {
  .bc-menu-container#megamenu_top .col-sm-9 {
    width: 705px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container#megamenu_top .col-sm-9 {
    width: 540px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container#megamenu_top .col-sm-9 {
    width: 100%;
  }
}

.bc-menu-container#megamenu_top .col-sm-8 {
  width: 780px;
}

@media (max-width: 1229px) {
  .bc-menu-container#megamenu_top .col-sm-8 {
    width: 626.666666667px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container#megamenu_top .col-sm-8 {
    width: 480px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container#megamenu_top .col-sm-8 {
    width: 100%;
  }
}

.bc-menu-container#megamenu_top .col-sm-7 {
  width: 682.5px;
}

@media (max-width: 1229px) {
  .bc-menu-container#megamenu_top .col-sm-7 {
    width: 548.333333333px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container#megamenu_top .col-sm-7 {
    width: 420px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container#megamenu_top .col-sm-7 {
    width: 100%;
  }
}

.bc-menu-container#megamenu_top .col-sm-6 {
  width: 585px;
}

@media (max-width: 1229px) {
  .bc-menu-container#megamenu_top .col-sm-6 {
    width: 470px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container#megamenu_top .col-sm-6 {
    width: 360px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container#megamenu_top .col-sm-6 {
    width: 100%;
  }
}

.bc-menu-container#megamenu_top .col-sm-5 {
  width: 487.5px;
}

@media (max-width: 1229px) {
  .bc-menu-container#megamenu_top .col-sm-5 {
    width: 391.666666667px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container#megamenu_top .col-sm-5 {
    width: 300px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container#megamenu_top .col-sm-5 {
    width: 100%;
  }
}

.bc-menu-container#megamenu_top .col-sm-4 {
  width: 390px;
}

@media (max-width: 1229px) {
  .bc-menu-container#megamenu_top .col-sm-4 {
    width: 313.333333333px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container#megamenu_top .col-sm-4 {
    width: 240px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container#megamenu_top .col-sm-4 {
    width: 100%;
  }
}

.bc-menu-container#megamenu_top .col-sm-3 {
  width: 292.5px;
}

@media (max-width: 1229px) {
  .bc-menu-container#megamenu_top .col-sm-3 {
    width: 235px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container#megamenu_top .col-sm-3 {
    width: 180px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container#megamenu_top .col-sm-3 {
    width: 100%;
  }
}

.bc-menu-container#megamenu_top .col-sm-2 {
  width: 195px;
}

@media (max-width: 1229px) {
  .bc-menu-container#megamenu_top .col-sm-2 {
    width: 156.666666667px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container#megamenu_top .col-sm-2 {
    width: 120px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container#megamenu_top .col-sm-2 {
    width: 100%;
  }
}

.bc-menu-container#megamenu_top .col-sm-1 {
  width: 97.5px;
}

@media (max-width: 1229px) {
  .bc-menu-container#megamenu_top .col-sm-1 {
    width: 78.3333333333px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container#megamenu_top .col-sm-1 {
    width: 60px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container#megamenu_top .col-sm-1 {
    width: 100%;
  }
}

.bc-menu-container#megamenu_top .menu-item > .icon-drop-mobile {
  display: none;
}

.bc-menu-container#megamenu_top .dropdown {
  position: absolute;
  top: 50px;
  width: auto;
  left: 0;
  z-index: 100;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
}

.bc-menu-container#menu_vertical {
  padding-top: 20px;
  padding-left: 0;
  position: relative;
  /*grid*/
}

@media (max-width: 767px) {
  .bc-menu-container#menu_vertical {
    height: 0px;
    margin-top: -17px;
    z-index: 1000;
  }
  .bc-menu-container#menu_vertical #bc_menu_collapse_menu_vertical {
    display: none;
  }
  .bc-menu-container#menu_vertical .title-menu-mobile {
    left: 0;
    top: -53px;
  }
}

.bc-menu-container#menu_vertical .col-sm-12 {
  width: 893px;
}

@media (max-width: 1229px) {
  .bc-menu-container#menu_vertical .col-sm-12 {
    width: 719px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container#menu_vertical .col-sm-12 {
    width: 555px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container#menu_vertical .col-sm-12 {
    width: 100%;
  }
}

.bc-menu-container#menu_vertical .col-sm-11 {
  width: 818.583333333px;
}

@media (max-width: 1229px) {
  .bc-menu-container#menu_vertical .col-sm-11 {
    width: 659.083333333px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container#menu_vertical .col-sm-11 {
    width: 508.75px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container#menu_vertical .col-sm-11 {
    width: 100%;
  }
}

.bc-menu-container#menu_vertical .col-sm-10 {
  width: 744.166666667px;
}

@media (max-width: 1229px) {
  .bc-menu-container#menu_vertical .col-sm-10 {
    width: 599.166666667px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container#menu_vertical .col-sm-10 {
    width: 462.5px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container#menu_vertical .col-sm-10 {
    width: 100%;
  }
}

.bc-menu-container#menu_vertical .col-sm-9 {
  width: 669.75px;
}

@media (max-width: 1229px) {
  .bc-menu-container#menu_vertical .col-sm-9 {
    width: 539.25px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container#menu_vertical .col-sm-9 {
    width: 416.25px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container#menu_vertical .col-sm-9 {
    width: 100%;
  }
}

.bc-menu-container#menu_vertical .col-sm-8 {
  width: 595.333333333px;
}

@media (max-width: 1229px) {
  .bc-menu-container#menu_vertical .col-sm-8 {
    width: 479.333333333px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container#menu_vertical .col-sm-8 {
    width: 370px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container#menu_vertical .col-sm-8 {
    width: 100%;
  }
}

.bc-menu-container#menu_vertical .col-sm-7 {
  width: 520.916666667px;
}

@media (max-width: 1229px) {
  .bc-menu-container#menu_vertical .col-sm-7 {
    width: 419.416666667px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container#menu_vertical .col-sm-7 {
    width: 323.75px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container#menu_vertical .col-sm-7 {
    width: 100%;
  }
}

.bc-menu-container#menu_vertical .col-sm-6 {
  width: 446.5px;
}

@media (max-width: 1229px) {
  .bc-menu-container#menu_vertical .col-sm-6 {
    width: 359.5px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container#menu_vertical .col-sm-6 {
    width: 277.5px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container#menu_vertical .col-sm-6 {
    width: 100%;
  }
}

.bc-menu-container#menu_vertical .col-sm-5 {
  width: 372.083333333px;
}

@media (max-width: 1229px) {
  .bc-menu-container#menu_vertical .col-sm-5 {
    width: 299.583333333px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container#menu_vertical .col-sm-5 {
    width: 231.25px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container#menu_vertical .col-sm-5 {
    width: 100%;
  }
}

.bc-menu-container#menu_vertical .col-sm-4 {
  width: 297.666666667px;
}

@media (max-width: 1229px) {
  .bc-menu-container#menu_vertical .col-sm-4 {
    width: 239.666666667px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container#menu_vertical .col-sm-4 {
    width: 185px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container#menu_vertical .col-sm-4 {
    width: 100%;
  }
}

.bc-menu-container#menu_vertical .col-sm-3 {
  width: 223.25px;
}

@media (max-width: 1229px) {
  .bc-menu-container#menu_vertical .col-sm-3 {
    width: 179.75px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container#menu_vertical .col-sm-3 {
    width: 138.75px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container#menu_vertical .col-sm-3 {
    width: 100%;
  }
}

.bc-menu-container#menu_vertical .col-sm-2 {
  width: 148.833333333px;
}

@media (max-width: 1229px) {
  .bc-menu-container#menu_vertical .col-sm-2 {
    width: 119.833333333px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container#menu_vertical .col-sm-2 {
    width: 92.5px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container#menu_vertical .col-sm-2 {
    width: 100%;
  }
}

.bc-menu-container#menu_vertical .col-sm-1 {
  width: 74.4166666667px;
}

@media (max-width: 1229px) {
  .bc-menu-container#menu_vertical .col-sm-1 {
    width: 59.9166666667px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container#menu_vertical .col-sm-1 {
    width: 46.25px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container#menu_vertical .col-sm-1 {
    width: 100%;
  }
}

.bc-menu-container#menu_vertical .container {
  padding: 0;
}

.bc-menu-container#menu_vertical .title-menu {
  min-height: 55px;
  background: #3498db;
  font-size: 1.1666666667rem;
  color: #fff;
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  padding: 19px 20px 18px;
  position: relative;
}

.bc-menu-container#menu_vertical .title-menu:after {
  content: "\f0c9";
  display: block;
  position: absolute;
  right: 18px;
  top: 20px;
  font-family: "FontAweSome";
  color: #fff;
  font-size: 18px;
}

@media (max-width: 991px) {
  .bc-menu-container#menu_vertical .title-menu {
    min-height: 45px;
    padding: 14px 20px 13px;
  }
  .bc-menu-container#menu_vertical .title-menu:after {
    top: 15px;
  }
}

.bc-menu-container#menu_vertical .menu-content {
  border: 1px solid #f3f3f3;
  height: auto;
  *zoom: 1;
}

.bc-menu-container#menu_vertical .menu-content:after, .bc-menu-container#menu_vertical .menu-content:before {
  content: "";
  display: table;
  clear: both;
}

.bc-menu-container#menu_vertical .menu-content .menu-item {
  padding: 17px 0px 17px 0px;
  position: relative;
  border: none;
  border-bottom: 1px solid #f3f3f3;
  width: 100%;
  font-size: 1.1666666667rem;
  min-height: 54px;
}

@media (max-width: 991px) {
  .bc-menu-container#menu_vertical .menu-content .menu-item {
    min-height: 44px;
    padding: 12px 0px 12px 0px;
  }
}

.bc-menu-container#menu_vertical .menu-content .menu-item:last-child {
  border-bottom: none;
}

.bc-menu-container#menu_vertical .menu-content .menu-item:hover .dropdown {
  display: block;
}

.bc-menu-container#menu_vertical .menu-content .menu-item:hover > .icon-drop-mobile {
  color: #3498db;
}

.bc-menu-container#menu_vertical .menu-content .menu-item > a {
  line-height: normal;
  padding: 0 30px 0 20px;
  font-size: inherit;
}

@media (max-width: 991px) {
  .bc-menu-container#menu_vertical .menu-content .menu-item > a {
    padding: 0 20px 0 10px;
  }
}

.bc-menu-container#menu_vertical .menu-content .menu-item > .icon-drop-mobile {
  position: absolute;
  right: 0;
  top: 0;
  width: 25px;
  height: 100%;
  padding-top: 20px;
  color: #252a2f;
}

@media (max-width: 991px) {
  .bc-menu-container#menu_vertical .menu-content .menu-item > .icon-drop-mobile {
    padding-top: 13px;
    width: 15px;
  }
}

.bc-menu-container#menu_vertical .dropdown {
  position: absolute;
  left: 100%;
  top: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 100;
  background-color: #fff;
  margin-left: 0 !important;
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.4);
}

/*Mobile menu*/
.mobile-version.visible-xs.visible-sm {
  /*temporary hide mobile menu on tablet layout*/
}

@media (min-width: 768px) {
  .mobile-version.visible-xs.visible-sm {
    display: none !important;
  }
}

#menu_mobile {
  width: 280px;
  height: 100%;
  background: #252a2f;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10005;
  overflow: auto;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  -webkit-transform: translate(-280px, 0);
  -ms-transform: translate(-280px, 0);
  -o-transform: translate(-280px, 0);
  transform: translate(-280px, 0);
}

#menu_mobile.opened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

#menu_mobile .container {
  padding-left: 0;
  padding-right: 0;
}

#menu_mobile .menu-content {
  height: auto;
  *zoom: 1;
  padding: 0 15px;
}

#menu_mobile .menu-content:after, #menu_mobile .menu-content:before {
  content: "";
  display: table;
  clear: both;
}

#menu_mobile .menu-content .dropdown {
  background: transparent;
  color: #ffffff;
}

#menu_mobile .menu-content > li {
  display: block;
  width: 100%;
  border-bottom: 1px dotted #ffffff;
  border-left: none;
  border-right: none;
}

#menu_mobile .menu-content > li .dropdown {
  clear: both;
  padding-top: 0;
  border: none;
  padding-bottom: 20px;
}

#menu_mobile .menu-content > li .menu-column {
  padding: 0;
}

#menu_mobile .menu-content > li .column-item {
  background: transparent;
  position: relative;
  left: 0;
  top: 0;
  opacity: 1;
  padding: 0;
  visibility: visible;
  min-width: 0;
  padding-left: 10px;
  -webkit-transition-duration: 0s;
  -o-transition-duration: 0s;
  transition-duration: 0s;
  box-shadow: none;
  color: #ffffff;
}

#menu_mobile .menu-content > li .column-item li a {
  text-transform: none;
}

#menu_mobile .menu-content > li .column-item li .column-item {
  display: none;
}

#menu_mobile .menu-content > li .icon-drop-mobile {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 6px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: right;
  z-index: 10;
}

#menu_mobile .menu-content > li .icon-drop-mobile:before {
  content: "\f13a";
  font-family: "FontAweSome";
  font-size: 13px;
  color: #ffffff;
}

#menu_mobile .menu-content > li .icon-drop-mobile.opened:before {
  content: "\f139";
  color: #3498db;
}

#menu_mobile .menu-content > li a {
  color: inherit;
}

#menu_mobile .menu-content > li > a {
  line-height: 40px;
  display: block;
  float: left;
  width: 100%;
  padding: 0;
}

#menu_mobile .menu-content > li:last-child {
  border: none;
}

#menu_mobile .menu-content > li:hover > a {
  color: #3498db;
}

#menu_mobile .menu-content > li:hover .dropdown {
  display: none;
}

#menu_mobile .title-menu {
  background: #3498db;
  text-align: left;
  border-bottom: none;
}

#menu_mobile .title-menu span {
  font-size: 1.6666666667rem;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  padding: 10px 15px;
  display: block;
}

#menu_mobile nav {
  border-top: none;
  margin-left: 0;
  margin-right: 0;
}

#slider-wrapper {
  position: relative;
  /*Slideshow navigation*/
}

#slider-wrapper .ls-nav-prev,
#slider-wrapper .ls-nav-next {
  position: absolute;
  top: 50%;
  z-index: 2;
  transform: translate(0, -50%);
}

#slider-wrapper .ls-nav-prev:hover:before,
#slider-wrapper .ls-nav-next:hover:before {
  color: #3498db;
}

#slider-wrapper .ls-nav-prev {
  left: 20px;
}

#slider-wrapper .ls-nav-prev:before {
  content: "\f053";
  display: block;
  font-family: "FontAweSome";
  font-size: 37px;
  color: #fff;
  transition: all 0.35s;
}

@media (max-width: 767px) {
  #slider-wrapper .ls-nav-prev {
    left: 5px;
  }
  #slider-wrapper .ls-nav-prev:before {
    font-size: 25px;
  }
}

#slider-wrapper .ls-nav-next {
  right: 20px;
}

#slider-wrapper .ls-nav-next:before {
  content: "\f054";
  display: block;
  font-family: "FontAweSome";
  font-size: 37px;
  color: #fff;
  transition: all 0.35s;
}

@media (max-width: 767px) {
  #slider-wrapper .ls-nav-next {
    right: 5px;
  }
  #slider-wrapper .ls-nav-next:before {
    font-size: 25px;
  }
}

#slider-wrapper .ls-lt-container {
  transform: none !important;
}

@media (max-width: 767px) {
  #slider-wrapper .ls-slide > .ls-bg {
    top: 0 !important;
  }
}

#slider-wrapper .ls-video-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
}

#slider-wrapper .ls-video-layer iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
}

/*Fullwidth slider*/
#full-slider-wrapper #layerslider .ls-slide .ls-l.very_big_white {
  font-family: "Roboto Condensed", sans-serif;
  color: #fff;
  font-weight: bold;
  font-size: 48px;
  font-size: 2.5vw;
  letter-spacing: 1px;
  line-height: 1.2;
}

#full-slider-wrapper #layerslider .ls-slide .ls-l.small_text {
  color: #fff;
  font-size: 16px;
  font-size: 0.8vw;
  font-style: italic;
  font-family: "Open Sans", sans-serif;
  text-transform: none;
  line-height: 1.2;
}

@media (max-width: 767px) {
  #full-slider-wrapper #layerslider .ls-slide .ls-l.small_text {
    display: none !important;
  }
}

#full-slider-wrapper #layerslider .ls-slide .ls-l.medium_text {
  font-weight: bold;
  border-radius: 3px;
  font-size: 16px;
  font-size: 0.8vw;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  padding: 0.67vw 1.56vw;
  background-color: #3498db;
  line-height: 1;
}

#full-slider-wrapper #layerslider .ls-slide .ls-l.medium_text span {
  font-size: inherit !important;
  line-height: 1;
  display: block;
}

@media (max-width: 991px) {
  #full-slider-wrapper #layerslider .ls-slide .ls-l.medium_text {
    padding: 10px 10px !important;
    font-size: 10px !important;
  }
}

/*Not fullwidth slider*/
#slider-wrapper {
  overflow: hidden;
  padding-right: 0;
  padding-left: 0;
  padding-top: 0;
  /*Set height for no full width slideshow on responsive*/
}

@media (max-width: 767px) {
  #slider-wrapper {
    padding-left: 0;
  }
}

#slider-wrapper .ls-slide {
  font-size: 862px;
}

@media (max-width: 1229px) {
  #slider-wrapper .ls-slide {
    font-size: 690px;
  }
}

@media (max-width: 991px) {
  #slider-wrapper .ls-slide {
    font-size: 525px;
  }
}

@media (max-width: 767px) {
  #slider-wrapper .ls-slide {
    font-size: 90vw;
  }
}

#slider-wrapper .ls-slide .ls-l.big_black {
  text-transform: capitalize;
  letter-spacing: 0;
  color: #3498db;
  font-size: 2.5rem !important;
  font-size: 3.48% !important;
}

#slider-wrapper .ls-slide .ls-l.big_black:before, #slider-wrapper .ls-slide .ls-l.big_black:after {
  display: none;
}

@media (max-width: 991px) and (min-width: 768px) {
  #slider-wrapper .ls-slide .ls-l.big_black {
    margin-top: -50px !important;
  }
}

#slider-wrapper .ls-slide .ls-l.very_big_white {
  font-family: "Roboto Condensed", sans-serif;
  color: #fff;
  font-weight: bold;
  font-size: 55px;
  font-size: 6.38% !important;
  letter-spacing: 1px;
  line-height: 1.2 !important;
}

@media (max-width: 991px) and (min-width: 768px) {
  #slider-wrapper .ls-slide .ls-l.very_big_white {
    margin-top: -50px !important;
  }
}

#slider-wrapper .ls-slide .ls-l.small_text {
  color: #fff;
  font-size: 16px;
  font-size: 1.85%;
  font-style: italic;
  font-family: "Open Sans", sans-serif;
  text-transform: none;
  line-height: 1.2;
}

@media (max-width: 991px) and (min-width: 768px) {
  #slider-wrapper .ls-slide .ls-l.small_text {
    margin-top: -50px !important;
  }
}

@media (max-width: 767px) {
  #slider-wrapper .ls-slide .ls-l.small_text {
    display: none !important;
  }
}

#slider-wrapper .ls-slide .ls-l.medium_text {
  font-weight: bold;
  border-radius: 3px;
  font-size: 14px;
  font-size: 1.62%;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  padding: 1.4166666667rem 2.0833333333rem;
  background-color: #3498db;
  line-height: 1;
}

#slider-wrapper .ls-slide .ls-l.medium_text span {
  font-size: inherit !important;
  line-height: 1;
  display: block;
}

@media (max-width: 991px) {
  #slider-wrapper .ls-slide .ls-l.medium_text {
    padding: 10px 10px !important;
    font-size: 10px !important;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  #slider-wrapper .ls-slide .ls-l.medium_text {
    margin-top: -50px !important;
  }
}

#slider-wrapper #layerslider {
  width: 100% !important;
}

@media (max-width: 991px) and (min-width: 768px) {
  #slider-wrapper #layerslider, #slider-wrapper .ls-inner,
  #slider-wrapper .ls-lt-container, #slider-wrapper .ls-slide {
    height: 398px !important;
  }
  #slider-wrapper #layerslider img, #slider-wrapper .ls-inner img,
  #slider-wrapper .ls-lt-container img, #slider-wrapper .ls-slide img {
    height: 600px !important;
    width: auto !important;
  }
}

#index {
  /*Title block on Home*/
  /*Home Banner*/
  /*Home Tab Section*/
  /*Home bottom section*/
}

#index .title_block h2, #index .title_block .h2 {
  text-align: center;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 60px;
  overflow: visible;
}

#index .title_block h2:before, #index .title_block .h2:before {
  content: "";
  height: 1px;
  display: none;
  position: absolute;
  left: 50%;
  bottom: 0;
  background-color: #f3f3f3;
  width: 50vw;
  transform: translate(-100%, 0);
}

#index .title_block h2:after, #index .title_block .h2:after {
  content: "";
  display: none;
  background-color: #fff;
  width: 10px;
  height: 10px;
  border: 2px solid #f3f3f3;
  border-radius: 10px;
  position: absolute;
  bottom: -5px;
  left: 50%;
  margin-left: -5px;
}

#index .bc-banner-top {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 60px;
}

@media (max-width: 767px) {
  #index .bc-banner-top {
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) {
  #index .bc-banner-top .bc-banner {
    margin-bottom: 20px;
  }
}

#index .bc-banner-top .bc-banner .bc-banner--wrapper {
  *zoom: 1;
  overflow: hidden;
  position: relative;
  font-weight: bold;
  color: #252a2f;
}

#index .bc-banner-top .bc-banner .bc-banner--wrapper:after, #index .bc-banner-top .bc-banner .bc-banner--wrapper:before {
  content: "";
  display: table;
  clear: both;
}

#index .bc-banner-top .bc-banner .bc-banner--wrapper .simple-text {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  font-family: "Oswald", sans-serif;
  color: #252a2f;
  position: absolute;
  transition: all 0.5s ease-in-out;
}

#index .bc-banner-top .bc-banner .bc-banner--wrapper .color-text {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 2rem;
  color: #3498db;
  text-transform: uppercase;
  position: absolute;
  transition: all 0.5s ease-in-out;
}

#index .bc-banner-top .bc-banner .bc-banner--wrapper .check-icon {
  width: 150px;
  height: 150px;
  position: absolute;
  top: 100%;
  right: -75px;
  display: block;
  background: #3498db;
  transform-origin: 0% 0%;
  transform: rotate(-45deg);
  transition: all 0.3s ease-in-out;
  font-size: 30px;
  line-height: 1;
  color: #ffffff;
}

#index .bc-banner-top .bc-banner .bc-banner--wrapper .check-icon:before {
  content: "\f00c";
  font-family: "FontAweSome";
  display: inline-block;
  transform: rotate(45deg);
  position: absolute;
  left: 40px;
  top: 5px;
}

@media (max-width: 991px) {
  #index .bc-banner-top .bc-banner .bc-banner--wrapper .check-icon {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 100%;
    right: -50px;
    font-size: 15px;
  }
  #index .bc-banner-top .bc-banner .bc-banner--wrapper .check-icon:before {
    left: 25px;
    top: 5px;
  }
}

#index .bc-banner-top .bc-banner:nth-child(2) .bc-banner--wrapper {
  height: 0;
  padding-bottom: 62.82527%;
}

#index .bc-banner-top .bc-banner:nth-child(2) .bc-banner--wrapper .simple-text {
  top: 10%;
  right: 5%;
  opacity: 0;
  color: #ffffff;
}

#index .bc-banner-top .bc-banner:nth-child(2) .bc-banner--wrapper:hover .simple-text {
  opacity: 1;
}

#index .bc-banner-top .bc-banner:nth-child(1) .bc-banner--wrapper {
  height: 0;
  padding-bottom: 29.82175%;
}

#index .bc-banner-top .bc-banner:nth-child(1) .bc-banner--wrapper .simple-text {
  top: 20%;
  left: 5%;
  margin-left: 30px;
  opacity: 0;
  color: #ffffff;
}

#index .bc-banner-top .bc-banner:nth-child(1) .bc-banner--wrapper .color-text {
  left: 5%;
  top: 40%;
  transform: scale(1.5, 1.5);
  opacity: 0;
}

#index .bc-banner-top .bc-banner:nth-child(1) .bc-banner--wrapper img {
  width: 120%;
  height: auto;
  max-width: 200%;
  margin-left: -10%;
  transition: all 0.3s ease-out;
}

#index .bc-banner-top .bc-banner:nth-child(1) .bc-banner--wrapper:hover .simple-text {
  opacity: 1;
  margin-left: 0;
}

#index .bc-banner-top .bc-banner:nth-child(1) .bc-banner--wrapper:hover .color-text {
  transform: scale(1, 1);
  opacity: 1;
}

#index .bc-banner-top .bc-banner:nth-child(1) .bc-banner--wrapper:hover img {
  animation-name: bannerParallax;
  animation-duration: 30s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

#index .bc-banner-top .bc-banner .bc-banner-left, #index .bc-banner-top .bc-banner .bc-banner-right {
  float: left;
}

#index .bc-banner-top .bc-banner .bc-banner-left {
  width: 60%;
  padding: 48px 0 40px 55px;
}

@media (max-width: 767px) {
  #index .bc-banner-top .bc-banner .bc-banner-left {
    width: 100%;
    text-align: center;
    padding: 20px;
  }
}

#index .bc-banner-top .bc-banner .bc-banner-right {
  width: 40%;
  text-align: center;
  padding-top: 48px;
  padding-bottom: 500px;
  margin-bottom: -500px;
  background: url("../images/shadow-line.png") no-repeat left 10px;
}

@media (max-width: 1229px) {
  #index .bc-banner-top .bc-banner .bc-banner-right {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 991px) {
  #index .bc-banner-top .bc-banner .bc-banner-right {
    padding-top: 60px;
  }
}

@media (max-width: 767px) {
  #index .bc-banner-top .bc-banner .bc-banner-right {
    width: 100%;
    text-align: center;
    padding-top: 0;
    padding-bottom: 20px;
    margin-bottom: 0;
    background: none;
  }
}

#index .type-tab .nav-tabs {
  background: #252a2f;
  border-bottom: none;
}

#index .type-tab .nav-tabs li {
  float: left;
}

#index .type-tab .nav-tabs li a {
  margin-right: 0;
  color: #ffffff;
  border: none;
  text-transform: uppercase;
  padding: 15px 20px;
}

#index .type-tab .nav-tabs li.active a, #index .type-tab .nav-tabs li:hover a {
  background: #3498db;
}

#index .type-tab .tab-content {
  margin: 20px 0;
}

#index .type-tab .owl-controls {
  position: absolute;
  right: 15px;
  top: -54px;
}

@media (max-width: 991px) and (min-width: 768px) {
  #index .type-tab .ajax_add_to_cart_button span {
    display: none;
  }
}

@media (max-width: 570px) {
  #index .type-tab .ajax_add_to_cart_button span {
    display: none;
  }
}

#index .bc-productwithcategory .title_block {
  display: none;
}

#index .bc-bottom-home {
  /*parallax section*/
  /*latest blog*/
  /*manufactures*/
}

#index .bc-bottom-home .bc-fashion-shop {
  position: relative;
  background: url("../images/parallax-img.jpg") no-repeat;
  background-position: center -250px;
  background-size: cover;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 90px;
  margin-bottom: 100px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.3333333333rem;
  color: #fff;
  line-height: 1.8;
}

@media (max-width: 1229px) and (min-width: 768px) {
  #index .bc-bottom-home .bc-fashion-shop {
    background-position: center -150px;
  }
}

@media (max-width: 767px) {
  #index .bc-bottom-home .bc-fashion-shop {
    background-position: center center !important;
    background-attachment: fixed;
  }
}

#index .bc-bottom-home .bc-fashion-shop:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

#index .bc-bottom-home .bc-fashion-shop.on_mobile {
  background-attachment: fixed;
}

#index .bc-bottom-home .bc-fashion-shop .bc-shop-text {
  position: relative;
  top: 100px;
}

@media (max-width: 1229px) {
  #index .bc-bottom-home .bc-fashion-shop .bc-shop-text {
    top: 60px;
  }
}

@media (max-width: 767px) {
  #index .bc-bottom-home .bc-fashion-shop .bc-shop-text {
    top: 0 !important;
  }
}

#index .bc-bottom-home .bc-fashion-shop .icon {
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  #index .bc-bottom-home .bc-fashion-shop .icon {
    margin-bottom: 20px;
  }
}

#index .bc-bottom-home .bc-fashion-shop .icon:before {
  content: "\f10d";
  display: block;
  font-family: "FontAweSome";
  font-size: 2.5rem;
  color: #fff;
  width: 90px;
  height: 90px;
  border-radius: 90px;
  text-align: center;
  line-height: 90px;
  border: 1px solid #fff;
}

@media (max-width: 767px) {
  #index .bc-bottom-home .bc-fashion-shop .icon:before {
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 1.6666666667rem;
  }
}

#index .bc-bottom-home .bc-fashion-shop h3.title, #index .bc-bottom-home .bc-fashion-shop .title.h3 {
  color: #3498db;
  text-transform: uppercase;
  margin-bottom: 20px;
  line-height: 1.2;
}

@media (max-width: 767px) {
  #index .bc-bottom-home .bc-fashion-shop h3.title, #index .bc-bottom-home .bc-fashion-shop .title.h3 {
    font-size: 1.6666666667rem;
  }
}

#index .bc-bottom-home .bc-fashion-shop .desc {
  margin-bottom: 35px;
}

#index .bc-bottom-home .bc-fashion-shop .author {
  font-size: 1.1666666667rem;
  text-transform: uppercase;
}

#index .bc-bottom-home .bc-fashion-shop .author span {
  text-transform: none;
}

@media (max-width: 1229px) {
  #index .bc-bottom-home .bc-fashion-shop {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (max-width: 991px) {
  #index .bc-bottom-home .bc-fashion-shop {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

#index .bc-bottom-home .bc-lastest-post {
  *zoom: 1;
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 40px;
  margin-bottom: 100px;
}

#index .bc-bottom-home .bc-lastest-post:after, #index .bc-bottom-home .bc-lastest-post:before {
  content: "";
  display: table;
  clear: both;
}

#index .bc-bottom-home .bc-lastest-post .lastest-post-button {
  display: none;
}

#index .bc-bottom-home .bc-lastest-post #bc_lastest_post_content > div {
  padding: 0 15px;
  width: 50%;
  float: left;
}

@media (max-width: 767px) {
  #index .bc-bottom-home .bc-lastest-post #bc_lastest_post_content > div {
    width: 100%;
  }
}

#index .bc-bottom-home .bc-lastest-post #bc_lastest_post_content .bc-item {
  margin-bottom: 60px;
}

#index .bc-bottom-home .bc-lastest-post #bc_lastest_post_content .bc-item .bc-bloglastest-img {
  width: 200px;
  float: left;
  margin-right: 30px;
  position: relative;
  overflow: hidden;
  position: relative;
}

#index .bc-bottom-home .bc-lastest-post #bc_lastest_post_content .bc-item .bc-bloglastest-img:before, #index .bc-bottom-home .bc-lastest-post #bc_lastest_post_content .bc-item .bc-bloglastest-img:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(67, 69, 113, 0.1);
  transform: scale(1, 1);
  transition: all 0.6s ease-in-out;
  z-index: 10;
}

#index .bc-bottom-home .bc-lastest-post #bc_lastest_post_content .bc-item .bc-bloglastest-img img {
  width: 100%;
  height: auto;
  -webkit-transition: all .35s;
  -o-transition: all .35s;
  transition: all .35s;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

#index .bc-bottom-home .bc-lastest-post #bc_lastest_post_content .bc-item .bc-bloglastest-img:hover:before {
  background: rgba(255, 255, 255, 0.5);
  transform: scale(0, 1);
}

#index .bc-bottom-home .bc-lastest-post #bc_lastest_post_content .bc-item .bc-bloglastest-img:hover:after {
  background: rgba(255, 255, 255, 0.5);
  transform: scale(1, 0);
}

#index .bc-bottom-home .bc-lastest-post #bc_lastest_post_content .bc-item .bc-bloglastest-img:hover img {
  /*-webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2);*/
}

@media (max-width: 991px) and (min-width: 767px) {
  #index .bc-bottom-home .bc-lastest-post #bc_lastest_post_content .bc-item .bc-bloglastest-img {
    width: 150px;
  }
}

@media (max-width: 480px) {
  #index .bc-bottom-home .bc-lastest-post #bc_lastest_post_content .bc-item .bc-bloglastest-img {
    width: 130px;
    margin-right: 10px;
  }
}

#index .bc-bottom-home .bc-lastest-post #bc_lastest_post_content .bc-item .bc-bloglastest-right-content {
  float: left;
  width: calc(100% - 230px);
  position: relative;
}

@media (max-width: 991px) and (min-width: 767px) {
  #index .bc-bottom-home .bc-lastest-post #bc_lastest_post_content .bc-item .bc-bloglastest-right-content {
    width: calc(100% - 180px);
  }
}

@media (max-width: 480px) {
  #index .bc-bottom-home .bc-lastest-post #bc_lastest_post_content .bc-item .bc-bloglastest-right-content {
    width: calc(100% - 140px);
  }
}

#index .bc-bottom-home .bc-lastest-post #bc_lastest_post_content .bc-item .bc-bloglastest-right-content h3, #index .bc-bottom-home .bc-lastest-post #bc_lastest_post_content .bc-item .bc-bloglastest-right-content .h3 {
  font-size: 1.1666666667rem;
  font-weight: bold;
  color: #3498db;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 17px;
}

#index .bc-bottom-home .bc-lastest-post #bc_lastest_post_content .bc-item .bc-bloglastest-right-content h3 a, #index .bc-bottom-home .bc-lastest-post #bc_lastest_post_content .bc-item .bc-bloglastest-right-content .h3 a {
  color: inherit;
}

#index .bc-bottom-home .bc-lastest-post #bc_lastest_post_content .bc-item .bc-bloglastest-right-content .frame-date-author-comment {
  margin-bottom: 5px;
}

#index .bc-bottom-home .bc-lastest-post #bc_lastest_post_content .bc-item .bc-bloglastest-right-content .frame-date-author-comment .comment-count {
  display: none;
}

#index .bc-bottom-home .bc-lastest-post #bc_lastest_post_content .bc-item .bc-bloglastest-right-content .frame-date-author-comment .date_add {
  font-size: 1.1666666667rem;
  font-style: italic;
  display: inline-block;
}

#index .bc-bottom-home .bc-lastest-post #bc_lastest_post_content .bc-item .bc-bloglastest-right-content .frame-date-author-comment .author {
  display: inline-block;
  margin-left: 5px;
}

#index .bc-bottom-home .bc-lastest-post #bc_lastest_post_content .bc-item .bc-bloglastest-right-content .frame-date-author-comment .author:before {
  content: "/";
  font-size: 1.1666666667rem;
  display: inline-block;
  margin-right: 5px;
}

#index .bc-bottom-home .bc-lastest-post #bc_lastest_post_content .bc-item .bc-bloglastest-right-content .frame-date-author-comment span {
  font-size: 1.1666666667rem;
  font-style: italic;
}

#index .bc-bottom-home .bc-lastest-post #bc_lastest_post_content .bc-item .bc-bloglastest-right-content .frame-date-author-comment span:nth-child(1) {
  color: #252a2f;
  font-weight: bold;
  font-style: normal;
  margin-right: 5px;
  display: none;
}

#index .bc-bottom-home .manufacturer-container #manufacture_list .owl-controls .owl-nav .owl-prev, #index .bc-bottom-home .manufacturer-container #manufacture_list .owl-controls .owl-nav .owl-next {
  opacity: 0;
}

@media (max-width: 991px) {
  #index .bc-bottom-home .manufacturer-container #manufacture_list .owl-controls .owl-nav .owl-prev, #index .bc-bottom-home .manufacturer-container #manufacture_list .owl-controls .owl-nav .owl-next {
    opacity: 1;
  }
}

#index .bc-bottom-home .manufacturer-container #manufacture_list .owl-controls .owl-nav .owl-prev {
  transform: translate(100px, 0);
}

@media (max-width: 991px) {
  #index .bc-bottom-home .manufacturer-container #manufacture_list .owl-controls .owl-nav .owl-prev {
    transform: none;
  }
}

#index .bc-bottom-home .manufacturer-container #manufacture_list .owl-controls .owl-nav .owl-next {
  transform: translate(-100px, 0);
}

@media (max-width: 991px) {
  #index .bc-bottom-home .manufacturer-container #manufacture_list .owl-controls .owl-nav .owl-next {
    transform: none;
  }
}

#index .bc-bottom-home .manufacturer-container #manufacture_list:hover .owl-controls .owl-nav .owl-prev, #index .bc-bottom-home .manufacturer-container #manufacture_list:hover .owl-controls .owl-nav .owl-next {
  opacity: 1;
  transform: translate(0, 0);
}

#index .bc-bottom-home .manufacturer-container #manufacture_list .owl-item {
  /*border-right: 1px solid #eee;
					&.last {
						border-right: none;
					}*/
}

#index .bc-bottom-home .manufacturer-container #manufacture_list .owl-controls .owl-prev, #index .bc-bottom-home .manufacturer-container #manufacture_list .owl-controls .owl-next {
  position: absolute;
  top: 50%;
  margin-top: -20px !important;
}

#index .bc-bottom-home .manufacturer-container #manufacture_list .owl-controls .owl-prev {
  left: 0;
}

#index .bc-bottom-home .manufacturer-container #manufacture_list .owl-controls .owl-next {
  right: 0;
}

#index .bc-bottom-home .manufacturer-container #manufacture_list .manufacture-item {
  padding: 0 15px;
  text-align: center;
  /*@media (max-width: 991px) and (min-width: 768px) {
						padding: 20px 30px;
					}
					@media (min-width: 768px) {
						&:nth-child(1),&:nth-child(2),
						&:nth-child(3),&:nth-child(4) {
							border-top-color: transparent;
						}
						&:nth-child(4n) {
							border-right-color: transparent;
						}
					}
					@media (max-width: 767px) {
						width: 50%;
						&:nth-child(1),&:nth-child(2) {
							border-top-color: transparent;
						}
						&:nth-child(2n) {
							border-right-color: transparent;
						}
					}
					@media (max-width: 480px) {
						padding: 20px 30px;	
					}*/
}

/*==============================CSS FOR CATEGORY PAGE==============================*/
/* BREADCRUMB */
.top-breadcrumb {
  background: none;
  background-size: cover;
}

@media (max-width: 991px) {
  .top-breadcrumb .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.top-breadcrumb .breadcrumb-title {
  *zoom: 1;
  padding: 10px 18px 3px;
  border: 1px solid #e8e8e8;
}

.top-breadcrumb .breadcrumb-title:after, .top-breadcrumb .breadcrumb-title:before {
  content: "";
  display: table;
  clear: both;
}

.top-breadcrumb .breadcrumb-title a[name="back"] {
  display: none;
}

.top-breadcrumb .breadcrumb-title h1, .top-breadcrumb .breadcrumb-title .h1 {
  display: inline-block;
  float: left;
  width: 100%;
  text-align: center;
  color: #fff;
  font-weight: bold;
  margin: 0;
  line-height: 1;
}

@media (max-width: 991px) {
  .top-breadcrumb .breadcrumb-title h1, .top-breadcrumb .breadcrumb-title .h1 {
    width: 100%;
    text-align: center;
  }
}

.top-breadcrumb .breadcrumb-title .breadcrumb {
  display: inline-block;
  float: none;
  padding: 0;
  width: 100%;
  margin: 0;
  border: none;
  border-radius: 0;
  background-color: transparent;
  text-shadow: none;
  font-size: 1.3333333333rem;
  font-weight: normal;
  text-transform: uppercase;
  color: #8b8b8b;
  text-align: left;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.0833333333rem;
}

.top-breadcrumb .breadcrumb-title .breadcrumb > span a {
  padding-right: 5px;
}

.top-breadcrumb .breadcrumb-title .breadcrumb .navigation-pipe {
  width: auto;
  line-height: 1;
  display: inline-block;
  position: relative;
  top: 4px;
  vertical-align: top;
  font-size: 0;
  text-align: center;
  text-indent: 0;
  padding-right: 5px;
}

.top-breadcrumb .breadcrumb-title .breadcrumb .navigation-pipe:before {
  content: "\00BB";
  font-family: "FontAweSome";
  display: inline-block;
  font-size: 1.0833333333rem;
  color: #8b8b8b;
}

.top-breadcrumb .breadcrumb-title .breadcrumb .navigation_page {
  display: inline;
  margin-left: 0px;
}

.top-breadcrumb .breadcrumb-title .breadcrumb .navigation_page span {
  display: inline-block;
}

.top-breadcrumb .breadcrumb-title .breadcrumb .navigation_page a {
  margin-left: 0;
  padding-right: 5px;
}

.top-breadcrumb .breadcrumb-title .breadcrumb .navigation_page .navigation-pipe {
  padding-right: 10px;
}

.top-breadcrumb .breadcrumb-title .breadcrumb a {
  background: transparent;
  width: auto;
  font-weight: normal;
  margin-right: 0;
  padding: 0;
  display: inline-block;
  color: #252a2f;
}

.top-breadcrumb .breadcrumb-title .breadcrumb a:before, .top-breadcrumb .breadcrumb-title .breadcrumb a:after {
  display: none;
}

.top-breadcrumb .breadcrumb-title .breadcrumb a:hover {
  text-decoration: underline;
}

.top-breadcrumb .breadcrumb-title .breadcrumb a.home {
  font-size: 0;
  width: 20px;
  height: 20px;
  text-align: center;
  margin-left: 0;
}

.top-breadcrumb .breadcrumb-title .breadcrumb a.home:before {
  content: "\f015";
  display: block;
  font-family: "FontAweSome";
  font-size: 1.0833333333rem;
  color: #252a2f;
  position: relative;
  left: 0;
  top: 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all .35s;
  -o-transition: all .35s;
  transition: all .35s;
  text-indent: 0;
  margin-right: 0;
}

.top-breadcrumb .breadcrumb-title .breadcrumb a.home:hover:before {
  color: #3498db;
}

.top-breadcrumb .breadcrumb-title .breadcrumb a.home + .navigation-pipe {
  padding-left: 0px;
}

.top-breadcrumb .breadcrumb-title .breadcrumb > a, .top-breadcrumb .breadcrumb-title .breadcrumb > span {
  vertical-align: top;
  display: inline-block;
  color: inherit;
  float: left;
}

/* CATEGORY BLOCK LEFT */
#left_column {
  font-family: "Roboto Condensed", sans-serif;
  /*Checkbox on all section*/
  /*Remove all bottom border of layer filter section*/
  /*Make price filter visible children when responsive*/
  /*CSS for color filter section*/
  /*Css for title block*/
}

#left_column a {
  color: #252a2f;
}

#left_column .bc-banner-left {
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  #left_column .bc-banner-left {
    display: none;
  }
}

#left_column .bc-banner-left img {
  width: 100%;
  display: block;
}

#left_column .content_left {
  padding: 0px 15px 30;
}

@media (max-width: 767px) {
  #left_column .content_left {
    border: none;
    background: none;
    padding: 20px 0;
  }
}

#left_column div.checker {
  top: 1px;
}

#left_column .layered_filter {
  border: none;
}

#left_column .layered_price > ul {
  overflow: visible;
  padding: 10px 15px !important;
}

#left_column .color-group li {
  position: relative;
}

#left_column .color-group li .color-option {
  margin-right: 5px;
  position: relative;
  top: 1px;
}

#left_column .title_block {
  background: none;
  border: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #ffffff;
  background: #252a2f;
  font-weight: normal;
  padding: 12px 20px;
  line-height: 1;
  position: relative;
  margin-bottom: 10px;
}

#left_column .title_block a {
  color: inherit;
}

#left_column .title_block + .block_content {
  border: 1px solid #ddd;
}

#left_column .title_block:before {
  /*content: '';
		    display: inline-block;
		    width: 5px;
		    height: 5px;
		    background: $mainColor_1;
		    margin-right: 7px;
		    position: relative;
		    float: left;
		    top: 7px;*/
}

#left_column .title_block:after {
  content: "\f13a";
  font-family: "FontAweSome";
  display: none;
  position: absolute;
  right: 0;
  top: 0px;
  color: inherit;
  font-size: 1rem;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: right;
  padding: 0 15px;
}

#left_column .title_block.active:after {
  content: "\f139";
}

@media (max-width: 767px) {
  #left_column .title_block {
    cursor: pointer;
  }
  #left_column .title_block:after {
    display: block;
    font-size: 13px;
  }
  #left_column .title_block:hover, #left_column .title_block.active {
    color: #3498db;
  }
  #left_column .title_block:hover a, #left_column .title_block.active a {
    color: #3498db;
  }
}

#left_column .block {
  clear: both;
  /*Block SubTitle*/
  /*CSS for cateogry tree*/
}

#left_column .block .block_content {
  margin-top: 0;
}

#left_column .block .layered_subtitle {
  background: none;
  border: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #ffffff;
  background: #252a2f;
  font-weight: normal;
  padding: 12px 20px;
  line-height: 1;
  position: relative;
  margin-bottom: 10px;
  width: 100%;
}

#left_column .block .layered_subtitle a {
  color: inherit;
}

#left_column .block .layered_subtitle:before {
  content: '';
  display: none;
  width: 5px;
  height: 5px;
  background: #3498db;
  margin-right: 7px;
  position: relative;
  float: left;
  top: 7px;
}

#left_column .block .layered_subtitle + ul {
  border: 1px solid #ddd;
  margin-bottom: 30px;
  padding: 0;
}

#left_column .block .layered_subtitle + ul > li {
  width: 100%;
  padding: 10px 15px !important;
  border-bottom: 1px solid #ddd;
}

#left_column .block .layered_subtitle + ul > li:last-child {
  border-bottom: none;
}

#left_column .block .layered_subtitle + ul label {
  margin-bottom: 0;
}

#left_column .block .layered_subtitle_heading + ul {
  border: 1px solid #ddd;
  margin-bottom: 30px;
  padding: 0;
}

#left_column .block .layered_subtitle_heading + ul label {
  margin-bottom: 0;
}

#left_column .block .layered_subtitle_heading + ul > li {
  width: 100%;
  padding: 10px 15px !important;
  border-bottom: 1px solid #ddd;
}

#left_column .block .layered_subtitle_heading + ul > li:last-child {
  border-bottom: none;
}

#left_column .block div.selector {
  max-width: 100%;
  width: 100%;
}

#left_column .block#categories_block_left {
  /*Toggle icon*/
}

#left_column .block#categories_block_left .title_block {
  margin-top: 0;
}

#left_column .block#categories_block_left span.grower {
  background: none;
  font-size: 18px;
  top: 0px;
  width: 30px;
  height: 42px;
  line-height: 38px;
  color: #3498db;
  text-align: center;
  z-index: 2;
}

@media (max-width: 991px) and (min-width: 768px) {
  #left_column .block#categories_block_left span.grower {
    height: 37px;
    line-height: 35px;
  }
}

@media (max-width: 767px) {
  #left_column .block#categories_block_left span.grower {
    padding: 0 15px;
    width: 40px;
    height: 40px;
    right: -1px;
  }
}

#left_column .block#categories_block_left span.grower:before {
  content: "\f13a";
  font-size: 1rem;
  display: inline-block;
  color: inherit;
  width: auto;
  height: auto;
  text-align: right;
  line-height: 1;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

@media (max-width: 767px) {
  #left_column .block#categories_block_left span.grower:before {
    font-size: 13px;
  }
}

#left_column .block#categories_block_left span.grower.OPEN:before {
  content: "\f139";
}

#left_column .block#categories_block_left .tree {
  margin-bottom: 0;
}

#left_column .block#categories_block_left .tree > li {
  border-bottom: 1px solid #ddd;
  text-transform: uppercase;
  font-size: 1.1666666667rem;
}

#left_column .block#categories_block_left .tree > li:last-child {
  margin-bottom: -1px;
}

#left_column .block#categories_block_left .tree > li > a {
  font-weight: bold;
  padding: 10px 15px;
}

#left_column .block#categories_block_left .tree > li > a:before {
  content: "\f054";
  font-family: "FontAweSome";
  font-size: 1rem;
  font-weight: normal;
  color: #3498db;
  padding-right: 6px;
}

#left_column .block#categories_block_left .tree > li > ul {
  padding-top: 10px;
  padding-bottom: 10px;
}

#left_column .block#categories_block_left .tree > li > .grower.OPEN {
  color: #ffffff;
}

#left_column .block#categories_block_left .tree > li > .grower.OPEN + a {
  color: #ffffff;
  background: #3498db;
}

#left_column .block#categories_block_left .tree > li > .grower.OPEN + a:before {
  color: #ffffff;
}

#left_column .block#categories_block_left .tree > li:hover > a, #left_column .block#categories_block_left .tree > li.selected > a {
  color: #ffffff;
  background: #3498db;
}

#left_column .block#categories_block_left .tree > li:hover > a:before, #left_column .block#categories_block_left .tree > li.selected > a:before {
  color: #ffffff;
}

#left_column .block#categories_block_left .tree > li:hover > .grower, #left_column .block#categories_block_left .tree > li.selected > .grower {
  color: #ffffff;
}

#left_column .block#categories_block_left ul {
  border: none;
}

#left_column .block#categories_block_left li a {
  padding: 5px 15px;
  color: #252a2f;
  font-weight: normal;
  border-color: #eee;
  line-height: 1.5;
  border: none;
  position: relative;
  z-index: 1;
}

#left_column .block#categories_block_left li a:hover {
  background: none;
}

#left_column .block#categories_block_left li a.selected {
  color: #3498db;
  text-decoration: underline;
  background: none;
}

#left_column .block#categories_block_left li ul {
  padding-left: 15px;
}

#left_column .block#categories_block_left li ul .grower {
  width: 30px;
  height: 30px;
  line-height: 28px;
}

#left_column .block#categories_block_left li ul .grower:hover + a {
  background: none;
}

@media (max-width: 767px) {
  #left_column .block#categories_block_left li ul .grower {
    padding: 0 15px;
    width: 40px;
  }
}

#left_column .block#categories_block_left li ul li {
  text-transform: capitalize;
}

#left_column .block#categories_block_left li ul li a:before {
  line-height: 1;
  content: "\f054";
  font-size: 0.8333333333rem;
  padding-right: 3px;
  float: left;
  margin-top: 6px;
  color: #3498db;
}

#left_column .block#layered_block_left .title_block {
  display: none;
}

#left_column .block#layered_block_left .block_content {
  border: none;
}

#left_column .block#layered_block_left #enabled_filters {
  padding: 0;
  border: none;
  background: none;
}

#left_column .block#layered_block_left #enabled_filters ul {
  padding-top: 0;
  padding-right: 0px;
}

#left_column .block#layered_block_left #enabled_filters ul li {
  color: #252a2f;
}

#left_column .block#manufacturers_block_left ul, #left_column .block#suppliers_block_left ul, #left_column .block#blog_categories_displayLeftColumn ul, #left_column .block.myaccount-column ul {
  border: none;
  padding: 0px;
  margin-bottom: 0;
}

#left_column .block#manufacturers_block_left .form-group, #left_column .block#suppliers_block_left .form-group, #left_column .block#blog_categories_displayLeftColumn .form-group, #left_column .block.myaccount-column .form-group {
  border: none;
  padding: 10px 15px 10px;
}

#left_column .block#manufacturers_block_left li, #left_column .block#suppliers_block_left li, #left_column .block#blog_categories_displayLeftColumn li, #left_column .block.myaccount-column li {
  border: none;
  padding: 0;
  width: 100%;
  margin: 0;
  border-bottom: 1px solid #ddd;
}

#left_column .block#manufacturers_block_left li:last-child, #left_column .block#suppliers_block_left li:last-child, #left_column .block#blog_categories_displayLeftColumn li:last-child, #left_column .block.myaccount-column li:last-child {
  margin-bottom: -1px;
}

#left_column .block#manufacturers_block_left li a, #left_column .block#suppliers_block_left li a, #left_column .block#blog_categories_displayLeftColumn li a, #left_column .block.myaccount-column li a {
  padding: 10px 15px;
  display: block;
  text-transform: uppercase;
  color: #252a2f;
  font-weight: bold;
  border-color: #eee;
  line-height: 1.5;
  border: none;
  position: relative;
  z-index: 1;
  font-size: 1.1666666667rem;
}

#left_column .block#manufacturers_block_left li a:hover, #left_column .block#manufacturers_block_left li a.selected, #left_column .block#suppliers_block_left li a:hover, #left_column .block#suppliers_block_left li a.selected, #left_column .block#blog_categories_displayLeftColumn li a:hover, #left_column .block#blog_categories_displayLeftColumn li a.selected, #left_column .block.myaccount-column li a:hover, #left_column .block.myaccount-column li a.selected {
  background: none;
  color: #3498db;
}

#left_column .block#manufacturers_block_left li a:before, #left_column .block#suppliers_block_left li a:before, #left_column .block#blog_categories_displayLeftColumn li a:before, #left_column .block.myaccount-column li a:before {
  line-height: 1;
  content: "\f0da";
  font-family: "FontAweSome";
  color: inherit;
  padding-right: 8px;
}

#left_column .block#manufacturers_block_left .logout, #left_column .block#suppliers_block_left .logout, #left_column .block#blog_categories_displayLeftColumn .logout, #left_column .block.myaccount-column .logout {
  text-align: right;
  padding: 10px 15px;
  *zoom: 1;
}

#left_column .block#manufacturers_block_left .logout:after, #left_column .block#manufacturers_block_left .logout:before, #left_column .block#suppliers_block_left .logout:after, #left_column .block#suppliers_block_left .logout:before, #left_column .block#blog_categories_displayLeftColumn .logout:after, #left_column .block#blog_categories_displayLeftColumn .logout:before, #left_column .block.myaccount-column .logout:after, #left_column .block.myaccount-column .logout:before {
  content: "";
  display: table;
  clear: both;
}

#left_column .block#manufacturers_block_left .logout a, #left_column .block#suppliers_block_left .logout a, #left_column .block#blog_categories_displayLeftColumn .logout a, #left_column .block.myaccount-column .logout a {
  float: left;
  margin-top: 0;
}

#left_column .block#blog_lastest_posts_displayLeftColumn ul {
  margin: 0;
}

#left_column .block#blog_lastest_posts_displayLeftColumn ul li {
  margin: 0;
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
}

#left_column .block#blog_lastest_posts_displayLeftColumn ul li:last-child {
  border-bottom: none;
}

#left_column .block#blog_lastest_posts_displayLeftColumn ul li h3, #left_column .block#blog_lastest_posts_displayLeftColumn ul li .h3 {
  font-size: 1.1666666667rem;
  text-transform: uppercase;
  color: #3498db;
  line-height: 1.2;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 5px;
}

#left_column .block#blog_lastest_posts_displayLeftColumn ul li h3 a, #left_column .block#blog_lastest_posts_displayLeftColumn ul li .h3 a {
  color: inherit;
}

#left_column .block#blog_lastest_posts_displayLeftColumn ul li .post-description {
  font-family: "Open Sans", sans-serif;
}

#left_column .block#viewed-products_block_left ul {
  margin-bottom: 0;
}

#left_column .block#viewed-products_block_left ul li {
  margin-bottom: 0px;
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
}

@media (max-width: 991px) {
  #left_column .block#viewed-products_block_left ul li {
    padding: 10px 5px;
  }
}

#left_column .block#viewed-products_block_left ul li h5, #left_column .block#viewed-products_block_left ul li .h5 {
  margin: 10px 0 7px 0;
}

#left_column .block#viewed-products_block_left ul li .product-name {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.3333333333rem;
  text-transform: none;
}

#left_column .block#viewed-products_block_left ul li .products-block-image {
  width: 64px;
  margin-right: 15px;
}

@media (max-width: 991px) {
  #left_column .block#viewed-products_block_left ul li .products-block-image {
    float: left;
    margin-right: 5px;
  }
}

#left_column .block#viewed-products_block_left ul li:last-child {
  border: none;
}

#left_column .block#blog_comments_displayLeftColumn ul {
  margin: 0;
}

#left_column .block#blog_comments_displayLeftColumn ul li {
  margin: 0;
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
}

#left_column .block#blog_comments_displayLeftColumn ul li:last-child {
  border-bottom: none;
}

#left_column .block#blog_comments_displayLeftColumn ul li h3, #left_column .block#blog_comments_displayLeftColumn ul li .h3 {
  margin-top: 0;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  text-transform: uppercase;
  color: #3498db;
  line-height: 1.2;
  margin-bottom: 5px;
}

#left_column .block#blog_comments_displayLeftColumn ul li h3 a, #left_column .block#blog_comments_displayLeftColumn ul li .h3 a {
  color: inherit;
}

#left_column .block#blog_comments_displayLeftColumn ul li .comment-content {
  padding: 0;
  margin-bottom: 10px;
  font-family: "Open Sans", sans-serif;
}

#left_column .block#best-sellers_block_right .products-block {
  margin-bottom: 0;
}

#left_column .block#best-sellers_block_right .products-block li {
  padding: 10px 15px;
  margin-bottom: 0;
  border-bottom: 1px solid #ddd;
}

@media (max-width: 991px) {
  #left_column .block#best-sellers_block_right .products-block li {
    padding: 10px 5px;
  }
}

#left_column .block#best-sellers_block_right .products-block li .products-block-image {
  width: 64px;
  margin-right: 15px;
}

@media (max-width: 991px) {
  #left_column .block#best-sellers_block_right .products-block li .products-block-image {
    margin-right: 5px;
    float: left;
  }
}

#left_column .block#best-sellers_block_right .products-block li .product-content h5, #left_column .block#best-sellers_block_right .products-block li .product-content .h5 {
  margin: 10px 0 7px;
}

#left_column .block#best-sellers_block_right .products-block li .product-content .product-name {
  text-transform: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.3333333333rem;
}

#left_column .block#best-sellers_block_right .products-block li .product-content .price-box .price {
  font-size: 1.5rem;
  color: #3498db;
}

#left_column .block#best-sellers_block_right .lnk {
  *zoom: 1;
  padding: 10px 15px;
  line-height: 0;
  text-align: left;
}

#left_column .block#best-sellers_block_right .lnk:after, #left_column .block#best-sellers_block_right .lnk:before {
  content: "";
  display: table;
  clear: both;
}

#left_column .block#best-sellers_block_right .lnk a {
  float: none;
}

#left_column .block#blog_tags_displayLeftColumn .block_content {
  padding: 10px 15px;
}

#left_column .block#blog_tags_displayLeftColumn .block_content a {
  margin-bottom: 0;
}

/* CATEGORY CENTER BLOCK*/
.category #center_column .content_scene_cat {
  border: none;
  padding-top: 9px;
}

.category #center_column .content_scene_cat_bg {
  padding: 0;
  background-color: transparent !important;
}

.category #center_column .content_scene_cat_bg img {
  width: 100%;
  margin-bottom: 20px;
}

.category #center_column .content_scene_cat_bg .category-name {
  float: left;
  width: 100%;
  color: #252a2f;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 15px;
}

.category #center_column .content_scene_cat_bg .cat_desc {
  color: #8b8b8b;
}

.category #center_column .content_scene_cat_bg .cat_desc .rte strong {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  color: #252a2f;
  font-weight: bold;
}

.category #center_column .page-heading {
  display: none;
}

.category #center_column #subcategories {
  border: none;
  position: relative;
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  .category #center_column #subcategories {
    margin-bottom: 30px;
  }
}

.category #center_column #subcategories .owl-controls .owl-prev,
.category #center_column #subcategories .owl-controls .owl-next {
  background: #252a2f;
  position: absolute;
  top: 50%;
  margin-top: -20px !important;
  width: 40px;
  height: 40px;
}

.category #center_column #subcategories .owl-controls .owl-prev:before,
.category #center_column #subcategories .owl-controls .owl-next:before {
  line-height: 40px;
  font-size: 14px;
}

.category #center_column #subcategories .owl-controls .owl-prev:hover,
.category #center_column #subcategories .owl-controls .owl-next:hover {
  color: #ffffff !important;
  background: #3498db;
}

.category #center_column #subcategories .owl-controls .owl-prev {
  left: 0;
}

.category #center_column #subcategories .owl-controls .owl-next {
  right: 0;
}

.category #center_column #subcategories .subcategory-name {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 2rem;
  color: #ffffff;
  font-weight: bold;
}

.category #center_column #subcategories .subcategory-heading {
  text-transform: uppercase;
}

.category #center_column #subcategories ul {
  margin: 0 -15px 0 -15px;
  width: auto;
  display: block;
  position: relative;
  z-index: 0;
}

.category #center_column #subcategories ul .owl-controls .owl-nav .owl-prev, .category #center_column #subcategories ul .owl-controls .owl-nav .owl-next {
  opacity: 0;
}

@media (max-width: 991px) {
  .category #center_column #subcategories ul .owl-controls .owl-nav .owl-prev, .category #center_column #subcategories ul .owl-controls .owl-nav .owl-next {
    opacity: 1;
  }
}

.category #center_column #subcategories ul .owl-controls .owl-nav .owl-prev {
  transform: translate(100px, 0);
}

@media (max-width: 991px) {
  .category #center_column #subcategories ul .owl-controls .owl-nav .owl-prev {
    transform: none;
  }
}

.category #center_column #subcategories ul .owl-controls .owl-nav .owl-next {
  transform: translate(-100px, 0);
}

@media (max-width: 991px) {
  .category #center_column #subcategories ul .owl-controls .owl-nav .owl-next {
    transform: none;
  }
}

.category #center_column #subcategories ul:hover .owl-controls .owl-nav .owl-prev, .category #center_column #subcategories ul:hover .owl-controls .owl-nav .owl-next {
  opacity: 1;
  transform: translate(0, 0);
}

.category #center_column #subcategories ul li {
  margin: 0;
  height: auto;
  padding: 0px 15px;
  width: 100%;
}

.category #center_column #subcategories ul li h5, .category #center_column #subcategories ul li .h5 {
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  color: #252a2f;
  font-weight: bold;
  transform: translate(0, -50%) scale(1.5, 1.5);
  -webkit-transition: all .6s;
  -o-transition: all .6s;
  transition: all .6s;
  z-index: 3;
  margin-top: 0px;
  opacity: 0;
}

.category #center_column #subcategories ul li h5 a, .category #center_column #subcategories ul li .h5 a {
  font-weight: bold;
  color: inherit;
}

.category #center_column #subcategories ul li:hover .subcategory-image a:before, .category #center_column #subcategories ul li:hover .subcategory-image a:after {
  background: rgba(67, 69, 113, 0.1);
}

.category #center_column #subcategories ul li:hover .subcategory-image a:before {
  transform: scale(1, 1);
}

.category #center_column #subcategories ul li:hover .subcategory-image a:after {
  transform: scale(1, 1);
}

.category #center_column #subcategories ul li:hover h5, .category #center_column #subcategories ul li:hover .h5 {
  transform: translate(0, -50%) scale(1, 1);
  opacity: 1;
}

.category #center_column #subcategories .subcategory-image {
  padding: 0;
  position: relative;
  overflow: hidden;
}

.category #center_column #subcategories .subcategory-image a {
  border: none;
  padding: 0;
  position: relative;
}

.category #center_column #subcategories .subcategory-image a:before, .category #center_column #subcategories .subcategory-image a:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  transition: all 0.6s ease-in-out;
}

.category #center_column #subcategories .subcategory-image a:before {
  z-index: 1;
  transform: scale(1, 0);
  background: rgba(255, 255, 255, 0.5);
}

.category #center_column #subcategories .subcategory-image a:after {
  z-index: 2;
  transform: scale(0, 1);
  background: rgba(255, 255, 255, 0.5);
}

.category #center_column #subcategories .subcategory-image img {
  width: 100%;
  height: auto;
  transition: all 0.35s;
}

/* LAYER SLIDER */
.layered_slider_container {
  width: 89%;
  position: relative;
}

@media (max-width: 1199px) {
  .layered_slider_container {
    left: -3px;
  }
}

@media (max-width: 991px) {
  .layered_slider_container {
    left: -8px;
  }
}

.layered_slider_container .layered_slider {
  background: #eee;
  border: none;
  border-top: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-radius: 20px;
}

.layered_slider_container .ui-widget-header {
  background: none;
  background-color: #3498db;
  margin-top: -1px;
}

.layered_slider_container .ui-slider-handle {
  width: 24px;
  height: 24px;
  border: none;
  background: none;
  background: url("../images/thumb-slider.png") no-repeat center;
  top: 50%;
  margin-top: -12px;
}

/* COLOR OPTION */
#layered_form .color-option {
  border-color: #f3f3f3;
  width: 16px;
  height: 16px;
  position: relative;
}

#layered_form div div ul {
  max-height: none;
}

/* CONTENT SORT FILTER BAR */
.content_sortPagiBar {
  margin-bottom: 40px;
}

@media (max-width: 991px) {
  .content_sortPagiBar {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .content_sortPagiBar {
    margin-bottom: 10px;
  }
}

.content_sortPagiBar div.selector {
  display: inline-block;
  width: 200px !important;
}

@media (max-width: 992px) and (min-width: 768px) {
  .content_sortPagiBar div.selector {
    width: 150px !important;
  }
}

.content_sortPagiBar #productsSortForm, .content_sortPagiBar .nbrItemPage {
  margin: 0 0 0 20px !important;
  display: inline-block;
  float: right !important;
}

@media (max-width: 767px) {
  .content_sortPagiBar #productsSortForm, .content_sortPagiBar .nbrItemPage {
    width: 50%;
    float: left !important;
    margin-left: 0 !important;
  }
  .content_sortPagiBar #productsSortForm .selector, .content_sortPagiBar .nbrItemPage .selector {
    width: 100% !important;
  }
  .content_sortPagiBar #productsSortForm .selector select, .content_sortPagiBar .nbrItemPage .selector select {
    width: 100%;
    max-width: 100% !important;
  }
}

@media (max-width: 767px) {
  .content_sortPagiBar #productsSortForm {
    padding-right: 15px;
    clear: both;
  }
}

@media (max-width: 767px) {
  .content_sortPagiBar .nbrItemPage {
    padding-left: 15px;
  }
  .content_sortPagiBar .nbrItemPage .clearfix > span {
    padding-left: 0 !important;
  }
}

.content_sortPagiBar .nbrItemPage .clearfix > span {
  padding-left: 12px;
  display: none !important;
}

.content_sortPagiBar .sortPagiBar {
  border: 1px solid #d0d0d0;
  border-width: 1px 0;
  padding: 8px 0;
}

.content_sortPagiBar label {
  color: #252a2f;
  padding-top: 8px;
  padding-right: 10px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
}

@media (max-width: 767px) {
  .content_sortPagiBar label {
    width: 100%;
    text-align: left;
  }
}

.content_sortPagiBar .icon-grid-list {
  float: left;
}

.content_sortPagiBar .icon-grid-list ul {
  margin: 0;
}

@media (max-width: 767px) {
  .content_sortPagiBar .icon-grid-list ul {
    display: block !important;
    float: none;
  }
}

.content_sortPagiBar .icon-grid-list .display-title {
  margin-top: 5px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal !important;
  display: none;
}

@media (max-width: 767px) {
  .content_sortPagiBar .icon-grid-list .display-title {
    width: 100%;
    text-align: left;
    margin-bottom: 5px;
  }
}

.content_sortPagiBar .icon-grid-list li a {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 0;
  color: #252a2f;
  float: left;
}

.content_sortPagiBar .icon-grid-list li a i {
  display: inline-block;
  font-size: 24px;
  color: #252a2f;
  margin-right: 0px;
  position: relative;
  top: 3px;
}

.content_sortPagiBar .icon-grid-list #grid, .content_sortPagiBar .icon-grid-list #list {
  height: auto !important;
  transform: translate3D(0, 0, 0);
  padding: 0;
  border: none;
  border-radius: 0px;
  background: none;
  cursor: pointer;
  padding-top: 4px;
}

@media (max-width: 767px) {
  .content_sortPagiBar .icon-grid-list #grid, .content_sortPagiBar .icon-grid-list #list {
    padding-top: 0;
  }
}

.content_sortPagiBar .icon-grid-list #grid:hover, .content_sortPagiBar .icon-grid-list #grid.selected, .content_sortPagiBar .icon-grid-list #list:hover, .content_sortPagiBar .icon-grid-list #list.selected {
  background: none;
}

.content_sortPagiBar .icon-grid-list #grid:hover a, .content_sortPagiBar .icon-grid-list #grid.selected a, .content_sortPagiBar .icon-grid-list #list:hover a, .content_sortPagiBar .icon-grid-list #list.selected a {
  color: #3498db;
}

.content_sortPagiBar .icon-grid-list #grid:hover a i, .content_sortPagiBar .icon-grid-list #grid.selected a i, .content_sortPagiBar .icon-grid-list #list:hover a i, .content_sortPagiBar .icon-grid-list #list.selected a i {
  color: #3498db;
}

.content_sortPagiBar .icon-grid-list #grid {
  margin-right: 10px;
}

.content_sortPagiBar .icon-grid-list #grid i:before {
  content: "\f00a";
}

.content_sortPagiBar .top-pagination-content {
  margin-top: 0px;
  padding-top: 20px;
  z-index: 0;
}

@media (max-width: 991px) {
  .content_sortPagiBar .top-pagination-content {
    padding-top: 10px;
  }
}

.content_sortPagiBar .top-pagination-content .product-count {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.3333333333rem;
  color: #3498db;
}

.search .content_sortPagiBar #grid, .search .content_sortPagiBar #list {
  padding-top: 0;
}

.search .content_sortPagiBar .product-count {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.3333333333rem;
  color: #3498db;
  display: inline-block;
  float: right;
  padding-top: 5px;
}

.search .content_sortPagiBar #productsSortForm {
  float: left !important;
  margin-left: 0 !important;
  margin-top: -3px !important;
}

@media (max-width: 767px) {
  .search .content_sortPagiBar #productsSortForm {
    width: 50%;
    clear: none;
  }
  .search .content_sortPagiBar #productsSortForm label {
    float: left;
    text-align: left;
    width: auto;
  }
}

/* BOTTOM PAGING BLOCK*/
.bottom-pagination-content {
  border: none;
  padding-top: 0;
}

.bottom-pagination-content .showall {
  display: none !important;
}

.bottom-pagination-content .product-count {
  float: right;
  color: #3498db;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.3333333333rem;
}

@media (max-width: 991px) {
  .bottom-pagination-content .product-count {
    clear: none;
  }
}

@media (max-width: 767px) {
  .bottom-pagination-content .product-count {
    width: 100%;
  }
}

.bottom-pagination-content div.pagination {
  float: left;
  width: auto;
}

.bottom-pagination-content div.pagination ul {
  width: 100%;
  text-align: center;
  position: relative;
  font-size: 0;
  margin-top: 0;
}

.bottom-pagination-content div.pagination ul li {
  float: none;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  margin-right: 1px !important;
}

.bottom-pagination-content div.pagination ul li > a, .bottom-pagination-content div.pagination ul li > span {
  margin: 0;
}

.bottom-pagination-content div.pagination ul li:not(.pagination_nav) {
  width: 34px;
  height: 34px;
  border: none;
}

.bottom-pagination-content div.pagination ul li:not(.pagination_nav) a, .bottom-pagination-content div.pagination ul li:not(.pagination_nav) span {
  width: 100%;
  height: 100%;
  background: #252a2f;
  border: none;
  font-size: 1.1666666667rem;
  color: #ffffff;
  line-height: 32px;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.bottom-pagination-content div.pagination ul li:not(.pagination_nav).active a, .bottom-pagination-content div.pagination ul li:not(.pagination_nav).active span, .bottom-pagination-content div.pagination ul li:not(.pagination_nav).current a, .bottom-pagination-content div.pagination ul li:not(.pagination_nav).current span, .bottom-pagination-content div.pagination ul li:not(.pagination_nav):hover a, .bottom-pagination-content div.pagination ul li:not(.pagination_nav):hover span {
  background: #3498db;
  border-color: #3498db;
  color: #ffffff;
}

.bottom-pagination-content div.pagination ul li.pagination_previous, .bottom-pagination-content div.pagination ul li.pagination_next, .bottom-pagination-content div.pagination ul li#pagination_previous, .bottom-pagination-content div.pagination ul li#pagination_next {
  min-width: 34px;
  height: 34px;
  top: 0;
  vertical-align: top;
}

.bottom-pagination-content div.pagination ul li.pagination_previous.disabled, .bottom-pagination-content div.pagination ul li.pagination_next.disabled, .bottom-pagination-content div.pagination ul li#pagination_previous.disabled, .bottom-pagination-content div.pagination ul li#pagination_next.disabled {
  opacity: 0.6;
}

.bottom-pagination-content div.pagination ul li.pagination_previous b, .bottom-pagination-content div.pagination ul li.pagination_next b, .bottom-pagination-content div.pagination ul li#pagination_previous b, .bottom-pagination-content div.pagination ul li#pagination_next b {
  display: none;
}

.bottom-pagination-content div.pagination ul li.pagination_previous a, .bottom-pagination-content div.pagination ul li.pagination_previous span, .bottom-pagination-content div.pagination ul li.pagination_next a, .bottom-pagination-content div.pagination ul li.pagination_next span, .bottom-pagination-content div.pagination ul li#pagination_previous a, .bottom-pagination-content div.pagination ul li#pagination_previous span, .bottom-pagination-content div.pagination ul li#pagination_next a, .bottom-pagination-content div.pagination ul li#pagination_next span {
  float: none;
  width: 100%;
  height: 100%;
  color: #ffffff;
  position: relative;
  padding: 0;
  line-height: 38px;
}

.bottom-pagination-content div.pagination ul li.pagination_previous a i, .bottom-pagination-content div.pagination ul li.pagination_previous span i, .bottom-pagination-content div.pagination ul li.pagination_next a i, .bottom-pagination-content div.pagination ul li.pagination_next span i, .bottom-pagination-content div.pagination ul li#pagination_previous a i, .bottom-pagination-content div.pagination ul li#pagination_previous span i, .bottom-pagination-content div.pagination ul li#pagination_next a i, .bottom-pagination-content div.pagination ul li#pagination_next span i {
  width: 34px;
  height: 34px;
  background: #252a2f;
  text-align: center;
  font-size: 14px;
  line-height: 32px;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.bottom-pagination-content div.pagination ul li.pagination_previous:not(.disabled):hover, .bottom-pagination-content div.pagination ul li.pagination_next:not(.disabled):hover, .bottom-pagination-content div.pagination ul li#pagination_previous:not(.disabled):hover, .bottom-pagination-content div.pagination ul li#pagination_next:not(.disabled):hover {
  border-color: #3498db;
}

.bottom-pagination-content div.pagination ul li.pagination_previous:not(.disabled):hover a, .bottom-pagination-content div.pagination ul li.pagination_previous:not(.disabled):hover span, .bottom-pagination-content div.pagination ul li.pagination_next:not(.disabled):hover a, .bottom-pagination-content div.pagination ul li.pagination_next:not(.disabled):hover span, .bottom-pagination-content div.pagination ul li#pagination_previous:not(.disabled):hover a, .bottom-pagination-content div.pagination ul li#pagination_previous:not(.disabled):hover span, .bottom-pagination-content div.pagination ul li#pagination_next:not(.disabled):hover a, .bottom-pagination-content div.pagination ul li#pagination_next:not(.disabled):hover span {
  color: #3498db;
}

.bottom-pagination-content div.pagination ul li.pagination_previous:not(.disabled):hover i, .bottom-pagination-content div.pagination ul li.pagination_next:not(.disabled):hover i, .bottom-pagination-content div.pagination ul li#pagination_previous:not(.disabled):hover i, .bottom-pagination-content div.pagination ul li#pagination_next:not(.disabled):hover i {
  background: #3498db;
  color: #ffffff;
}

.bottom-pagination-content div.pagination ul li.pagination_previous, .bottom-pagination-content div.pagination ul li#pagination_previous {
  left: 0;
}

.bottom-pagination-content div.pagination ul li.pagination_previous a, .bottom-pagination-content div.pagination ul li.pagination_previous span, .bottom-pagination-content div.pagination ul li#pagination_previous a, .bottom-pagination-content div.pagination ul li#pagination_previous span {
  text-align: right;
}

.bottom-pagination-content div.pagination ul li.pagination_previous a i, .bottom-pagination-content div.pagination ul li.pagination_previous span i, .bottom-pagination-content div.pagination ul li#pagination_previous a i, .bottom-pagination-content div.pagination ul li#pagination_previous span i {
  float: left;
}

.bottom-pagination-content div.pagination ul li.pagination_previous a i:before, .bottom-pagination-content div.pagination ul li.pagination_previous span i:before, .bottom-pagination-content div.pagination ul li#pagination_previous a i:before, .bottom-pagination-content div.pagination ul li#pagination_previous span i:before {
  content: "\f060" !important;
}

.bottom-pagination-content div.pagination ul li.pagination_next, .bottom-pagination-content div.pagination ul li#pagination_next {
  right: 0;
  margin-left: 0;
}

.bottom-pagination-content div.pagination ul li.pagination_next a, .bottom-pagination-content div.pagination ul li.pagination_next span, .bottom-pagination-content div.pagination ul li#pagination_next a, .bottom-pagination-content div.pagination ul li#pagination_next span {
  text-align: left;
}

.bottom-pagination-content div.pagination ul li.pagination_next a i, .bottom-pagination-content div.pagination ul li.pagination_next span i, .bottom-pagination-content div.pagination ul li#pagination_next a i, .bottom-pagination-content div.pagination ul li#pagination_next span i {
  float: right;
}

.bottom-pagination-content div.pagination ul li.pagination_next a i:before, .bottom-pagination-content div.pagination ul li.pagination_next span i:before, .bottom-pagination-content div.pagination ul li#pagination_next a i:before, .bottom-pagination-content div.pagination ul li#pagination_next span i:before {
  content: "\f061" !important;
}

/* PRODUCT LAYOUT */
.columns-container ul.product_list {
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
  *zoom: 1;
}

.columns-container ul.product_list:after, .columns-container ul.product_list:before {
  content: "";
  display: table;
  clear: both;
}

.columns-container ul.product_list > p {
  font-size: 0;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 999;
  background: black;
}

.columns-container ul.product_list > p img {
  display: none;
}

.columns-container ul.product_list .color-list-container {
  margin-bottom: 10px;
  /*temporary hide*/
  display: none;
}

.columns-container ul.product_list .color-list-container ul li {
  width: 20px;
  height: 20px;
}

.columns-container ul.product_list .color-list-container ul li a {
  width: 100%;
  height: 100%;
  margin: 0;
  position: relative;
}

.columns-container ul.product_list .color-list-container ul li a:after {
  content: "";
  width: 14px;
  height: 14px;
  border: 4px solid #fff;
  position: absolute;
  left: 2px;
  top: 2px;
  display: block;
}

.columns-container ul.product_list .availability span {
  background-color: transparent;
  border: none;
  color: #55c65e;
}

.columns-container ul.product_list.grid > li {
  padding: 0 15px;
  margin-bottom: 30px;
  clear: none;
}

.columns-container ul.product_list.grid > li .product-container {
  padding: 15px 15px 10px;
}

@media (min-width: 992px) {
  .columns-container ul.product_list.grid > li:nth-child(3n+1) {
    clear: both;
  }
}

@media (max-width: 991px) and (min-width: 480px) {
  .columns-container ul.product_list.grid > li:nth-child(2n+1) {
    clear: both;
  }
}

.columns-container ul.product_list.list > li {
  width: 100%;
  margin-bottom: 20px;
  *zoom: 1;
}

.columns-container ul.product_list.list > li:after, .columns-container ul.product_list.list > li:before {
  content: "";
  display: table;
  clear: both;
}

.columns-container ul.product_list.list > li .product-container {
  float: left;
  width: 100%;
}

.columns-container ul.product_list.list > li .product-container .left-block {
  width: 30%;
  float: left;
  border: none;
}

@media (max-width: 991px) {
  .columns-container ul.product_list.list > li .product-container .left-block {
    width: 45%;
  }
}

@media (max-width: 767px) {
  .columns-container ul.product_list.list > li .product-container .left-block {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .columns-container ul.product_list.list > li .product-container .left-block .functional-buttons .ajax_add_to_cart_button,
  .columns-container ul.product_list.list > li .product-container .left-block .functional-buttons .addToWishlist, .columns-container ul.product_list.list > li .product-container .left-block .functional-buttons .add_to_compare,
  .columns-container ul.product_list.list > li .product-container .left-block .functional-buttons .quick-view {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}

.columns-container ul.product_list.list > li .product-container .left-block .product-image-container {
  padding: 20px 0;
}

.columns-container ul.product_list.list > li .product-container .right-block {
  width: 70%;
  float: left;
  padding: 20px 0px 20px 15px;
  text-align: left;
}

@media (max-width: 991px) {
  .columns-container ul.product_list.list > li .product-container .right-block {
    width: 55%;
  }
}

@media (max-width: 767px) {
  .columns-container ul.product_list.list > li .product-container .right-block {
    width: 50%;
  }
}

@media (max-width: 540px) {
  .columns-container ul.product_list.list > li .product-container .right-block .functional-buttons .ajax_add_to_cart_button span {
    display: none;
  }
}

.columns-container ul.product_list.list > li .product-container .right-block .product-name,
.columns-container ul.product_list.list > li .product-container .right-block .product-desc {
  padding-left: 0;
  padding-right: 0;
}

.columns-container ul.product_list.list > li .product-container .right-block .product-desc {
  white-space: normal;
  line-height: 1.5;
}

.columns-container ul.product_list.list > li .product-container .right-block .reviews-container {
  background: none;
  display: block;
  width: 100%;
}

.columns-container ul.product_list.list > li .product-container .right-block .reviews-container .star_content .star:after {
  color: #3498db !important;
}

.columns-container ul.product_list.list > li .product-container .right-block .comments_note {
  text-align: left;
}

.columns-container ul.product_list.list > li .product-container .right-block .content_price {
  background: none;
  width: 100%;
}

.columns-container ul.product_list.list > li .product-container .right-block .lnk_view,
.columns-container ul.product_list.list > li .product-container .right-block .product-flags {
  display: none;
}

/*=====================================CSS FOR PRODUCT DETAIL PAGE==================================================*/
.product div.star_on:after,
.product div.star:after {
  color: #3498db !important;
}

.product .top-hr {
  display: none;
}

.product .primary_block {
  *zoom: 1;
  padding-top: 30px;
}

.product .primary_block:after, .product .primary_block:before {
  content: "";
  display: table;
  clear: both;
}

.product .pb-left-column {
  padding-left: 0;
}

@media (max-width: 767px) {
  .product .pb-left-column {
    padding-right: 0;
  }
}

.product .pb-left-column #image-block {
  border: 1px solid #ddd;
  padding: 0;
  width: 100%;
}

.product .pb-left-column #image-block .zoomPad, .product .pb-left-column #image-block img {
  width: 100%;
  height: auto;
}

.product .pb-left-column #image-block .new-box, .product .pb-left-column #image-block .price-percent-reduction, .product .pb-left-column #image-block .pb-center-column .content_prices #reduction_percent, .product .pb-center-column .content_prices .pb-left-column #image-block #reduction_percent, .product .pb-left-column #image-block .sale-box {
  left: 0;
}

.product .pb-left-column #image-block .new-box.new-box, .product .pb-left-column #image-block .price-percent-reduction, .product .pb-left-column #image-block .pb-center-column .content_prices #reduction_percent, .product .pb-center-column .content_prices .pb-left-column #image-block #reduction_percent, .product .pb-left-column #image-block .sale-box.new-box, .product .pb-left-column #image-block .sale-box.price-percent-reduction, .product .pb-left-column #image-block .pb-center-column .content_prices .sale-box#reduction_percent, .product .pb-center-column .content_prices .pb-left-column #image-block .sale-box#reduction_percent {
  top: 0;
}

.product .pb-left-column #image-block .new-box.sale-box, .product .pb-left-column #image-block .sale-box.price-percent-reduction, .product .pb-left-column #image-block .pb-center-column .content_prices .sale-box#reduction_percent, .product .pb-center-column .content_prices .pb-left-column #image-block .sale-box#reduction_percent, .product .pb-left-column #image-block .sale-box.sale-box {
  top: 25px;
}

.product .pb-left-column #image-block .zoomPad {
  overflow: hidden;
}

.product .pb-left-column #views_block {
  position: relative;
  margin-top: 30px;
  margin-left: -5px;
  margin-right: -5px;
}

.product .pb-left-column #views_block .view_scroll_spacer {
  margin: 0;
}

.product .pb-left-column #views_block #thumbs_list {
  width: 100%;
}

.product .pb-left-column #views_block #thumbs_list li {
  text-align: center;
  float: left;
  height: auto;
  border: none;
  padding: 0 5px;
  margin: 0;
  width: 92px;
}

@media (max-width: 1199px) {
  .product .pb-left-column #views_block #thumbs_list li {
    width: 101px;
  }
}

@media (max-width: 991px) {
  .product .pb-left-column #views_block #thumbs_list li {
    width: 115px;
  }
}

@media (max-width: 767px) {
  .product .pb-left-column #views_block #thumbs_list li img {
    width: 100%;
    display: inline-block;
  }
}

.product .pb-left-column #views_block #thumbs_list li a {
  border: 1px solid #ddd;
  display: block;
  position: relative;
  *zoom: 1;
  transition-duration: 0.2s;
}

.product .pb-left-column #views_block #thumbs_list li a:after, .product .pb-left-column #views_block #thumbs_list li a:before {
  content: "";
  display: table;
  clear: both;
}

.product .pb-left-column #views_block #thumbs_list li a:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-top: 2px solid #3498db;
  border-left: 2px solid #3498db;
  transition: all .35s;
  transform: scale(0, 0);
  transform-origin: 0 0;
}

.product .pb-left-column #views_block #thumbs_list li a:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-bottom: 2px solid #3498db;
  border-right: 2px solid #3498db;
  transition: all .35s;
  transform: scale(0, 0);
  transform-origin: 100% 100%;
}

.product .pb-left-column #views_block #thumbs_list li a.shown, .product .pb-left-column #views_block #thumbs_list li a:hover {
  border-color: #3498db;
  border-width: 5px;
  /*&:before, &:after {
								transform: scale(1,1);
							}*/
}

.product .pb-left-column #views_block #thumbs_list li img {
  border: none;
  float: none;
  width: 100%;
  height: auto;
}

.product .pb-left-column #views_block #thumbs_list ul#thumbs_list_frame {
  height: auto;
}

.product .pb-left-column #views_block #view_scroll_left, .product .pb-left-column #views_block #view_scroll_right {
  width: 30px;
  height: 30px;
  padding: 0;
  position: absolute;
  top: 50%;
  margin-top: -20px !important;
  z-index: 20;
  background: #252a2f;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.product .pb-left-column #views_block #view_scroll_left:before, .product .pb-left-column #views_block #view_scroll_right:before {
  font-size: 15px;
  line-height: 30px;
}

.product .pb-left-column #views_block #view_scroll_left:hover, .product .pb-left-column #views_block #view_scroll_right:hover {
  background: #525d68;
}

.product .pb-left-column #views_block #view_scroll_left {
  left: -8px;
}

@media (max-width: 767px) {
  .product .pb-left-column #views_block #view_scroll_left {
    left: -8px;
  }
}

.product .pb-left-column #views_block #view_scroll_right {
  right: -8px;
}

@media (max-width: 767px) {
  .product .pb-left-column #views_block #view_scroll_right {
    right: -8px;
  }
}

.product .pb-center-column {
  padding-left: 15px;
}

@media (max-width: 767px) {
  .product .pb-center-column {
    padding-left: 0;
    padding-right: 0;
  }
}

.product .pb-center-column h1, .product .pb-center-column .h1 {
  text-transform: uppercase;
  margin-top: 0;
}

.product .pb-center-column .content_prices {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  position: relative;
}

.product .pb-center-column .content_prices .our_price_display {
  display: inline-block;
}

.product .pb-center-column .content_prices .our_price_display .price {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 2rem;
  color: #3498db;
  font-weight: normal;
  padding-right: 5px;
}

.product .pb-center-column .content_prices #old_price {
  margin-bottom: 0;
  padding-bottom: 0;
}

.product .pb-center-column .content_prices #old_price .price {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  margin-top: 3px;
  display: block;
}

.product .pb-center-column .content_prices #reduction_percent {
  display: none !important;
  line-height: 24px;
  position: relative;
  left: 0;
  top: 0;
  float: left;
  margin-bottom: 0;
}

.product .pb-center-column #availability_statut #availability_value {
  margin-left: 0;
  margin-right: 10px;
  background: none;
  border: none;
  background: #3498db;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-family: "Roboto Condensed", sans-serif;
  padding: 3px 6px 2px;
  line-height: 1;
}

.product .pb-center-column #pQuantityAvailable #quantityAvailable, .product .pb-center-column #pQuantityAvailable #quantityAvailableTxtMultiple {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.3333333333rem;
  font-weight: normal;
}

.product .pb-center-column #product_comments_block_extra {
  *zoom: 1;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  background: none;
  border: none;
}

.product .pb-center-column #product_comments_block_extra:after, .product .pb-center-column #product_comments_block_extra:before {
  content: "";
  display: table;
  clear: both;
}

.product .pb-center-column #product_comments_block_extra .comments_note {
  float: left;
  margin-right: 20px;
}

.product .pb-center-column #product_comments_block_extra .comments_advices {
  clear: none;
  float: left;
  margin-top: -4px;
}

.product .pb-center-column #product_comments_block_extra .comments_advices .reviews, .product .pb-center-column #product_comments_block_extra .comments_advices .open-comment-form {
  border-right: none;
  padding-right: 0;
  margin-right: 10px;
  color: #252a2f;
  font-weight: normal;
}

.product .pb-center-column #product_comments_block_extra .comments_advices a {
  line-height: normal;
}

.product .pb-center-column #product_comments_block_extra .comments_advices a:hover {
  color: #252a2f;
  text-decoration: underline;
}

.product .pb-center-column #product_comments_block_extra .comments_advices a:before {
  color: inherit;
  float: left;
}

.product .pb-center-column #product_comments_block_extra .comments_advices li {
  line-height: normal !important;
}

.product .pb-center-column #product_comments_block_extra .comments_advices li:after {
  content: "|";
  display: inline-block;
  margin-right: 10px;
}

.product .pb-center-column #product_comments_block_extra .comments_advices li:nth-child(2):after {
  display: none;
}

@media (max-width: 991px) {
  .product .pb-center-column #product_comments_block_extra .comments_advices li {
    display: inline-block;
  }
}

.product .pb-center-column #short_description_block {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #83909d;
  border-top: 1px solid #83909d;
  display: inline-block;
  width: 100%;
  color: #252a2f;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
}

.product .pb-center-column #short_description_block #short_description_content {
  padding-bottom: 0;
}

.product .pb-center-column #short_description_block #short_description_content p {
  margin-bottom: 0;
}

.product .pb-center-column #buy_block {
  padding-bottom: 20px;
  border-bottom: 1px solid #83909d;
}

.product .pb-center-column .box-info-product {
  background: none;
  border: none;
}

.product .pb-center-column .box-info-product .box-cart-bottom {
  *zoom: 1;
  margin-top: 20px;
  margin-bottom: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  clear: both;
  width: 100%;
}

.product .pb-center-column .box-info-product .box-cart-bottom:after, .product .pb-center-column .box-info-product .box-cart-bottom:before {
  content: "";
  display: table;
  clear: both;
}

.product .pb-center-column .box-info-product .box-cart-bottom > div {
  display: inline-block;
  float: left;
}

.product .pb-center-column .box-info-product .product_attributes {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
  *zoom: 1;
}

.product .pb-center-column .box-info-product .product_attributes:after, .product .pb-center-column .box-info-product .product_attributes:before {
  content: "";
  display: table;
  clear: both;
}

.product .pb-center-column .box-info-product .product_attributes label {
  text-align: left;
  font-weight: normal;
  margin-top: 10px;
  float: left;
  width: 100px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  color: #252a2f;
}

.product .pb-center-column .box-info-product .product_attributes .attribute_list {
  float: none;
}

.product .pb-center-column .box-info-product .product_attributes .attribute_list ul {
  clear: none !important;
}

.product .pb-center-column .box-info-product .product_attributes .attribute_list div.selector {
  /*height: 40px;
						>span, select {
							height: 40px;
							line-height: 40px;
						color: $colorText_4;
							&:after {
								width: 38px;
								height: 38px;
								line-height: 38px;
							}
						}*/
}

.product .pb-center-column .box-info-product .product_attributes fieldset {
  padding-bottom: 20px;
}

.product .pb-center-column .box-info-product #color_to_pick_list li {
  float: left;
  margin: 0;
  width: 32px;
  height: 32px;
  padding: 3px;
  border: 1px solid transparent;
}

.product .pb-center-column .box-info-product #color_to_pick_list li a {
  width: 100%;
  height: 100%;
}

.product .pb-center-column .box-info-product #color_to_pick_list li a[name*="White"] {
  border: 1px solid #ccc;
}

.product .pb-center-column .box-info-product #color_to_pick_list li.selected {
  border-color: #3498db;
}

.product .pb-center-column .box-info-product #quantity_wanted_p {
  float: left;
  position: relative;
}

.product .pb-center-column .box-info-product #quantity_wanted_p .btn {
  margin-left: 0;
  margin-right: 1px;
  border: none;
  position: relative;
  width: 34px;
  height: 34px;
  line-height: 33px;
  border-radius: 4px;
  overflow: hidden;
  float: right;
}

.product .pb-center-column .box-info-product #quantity_wanted_p .btn span {
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  background-image: none;
  background-color: #252a2f;
  -webkit-transition: background .3s;
  -o-transition: background .3s;
  transition: background .3s;
}

.product .pb-center-column .box-info-product #quantity_wanted_p .btn span i {
  color: #fff;
  font-size: 14px;
  line-height: 1;
}

.product .pb-center-column .box-info-product #quantity_wanted_p .btn.button-minus {
  margin-right: 0;
}

.product .pb-center-column .box-info-product #quantity_wanted_p .btn.button-minus i:before {
  content: "\f068";
}

.product .pb-center-column .box-info-product #quantity_wanted_p .btn.button-plus i:before {
  content: "\f067";
}

.product .pb-center-column .box-info-product #quantity_wanted_p .btn:hover span {
  background: #3498db;
}

.product .pb-center-column .box-info-product #quantity_wanted_p input {
  height: 34px;
  padding-right: 30px;
  background: #fff;
  text-align: center;
  border-radius: 4px;
  font-size: 1.5rem;
  color: #252a2f;
  margin-right: 1px;
}

.product .pb-center-column .box-info-product #add_to_cart {
  padding: 0;
  float: left;
  margin-right: 1px;
  margin-bottom: 0;
}

.product .pb-center-column .box-info-product #add_to_cart button {
  height: 38px;
  background: none;
  padding: 0;
  border-radius: 0;
}

.product .pb-center-column .box-info-product #add_to_cart button span {
  transition: all 0.2s ease-out;
  height: 38px;
  line-height: 38px;
  display: inline-block;
  background-color: #3498db;
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.3333333333rem;
  color: #fff !important;
  text-align: center;
  padding: 0 15px;
  border-radius: 0;
}

.product .pb-center-column .box-info-product #add_to_cart button span:hover {
  background-color: #252a2f;
}

.product .pb-center-column .box-info-product #add_to_cart button:before {
  transition: all 0.2s ease-out;
  content: '';
  display: inline-block;
  float: right;
  width: 38px;
  height: 38px;
  display: inline-block;
  font-size: 0;
  line-height: 38px;
  border-radius: 0px;
  border: none;
  background-color: #252a2f;
  color: #ffffff;
  text-align: center;
  padding: 0;
  transform: translate3d(0, 0, 0);
}

.product .pb-center-column .box-info-product #add_to_cart button:before:hover {
  background-color: #3498db;
  color: #ffffff;
}

.product .pb-center-column .box-info-product #add_to_cart button:before {
  content: "";
  font-family: "FontAweSome";
  display: block;
  font-size: 1.1666666667rem;
  color: #ffffff;
}

.product .pb-center-column .box-info-product #add_to_cart button:hover span {
  background-color: #252a2f;
}

.product .pb-center-column .box-info-product #add_to_cart button:hover:before {
  background: #3498db;
  content: "\f218";
}

.product .pb-center-column .box-info-product #add_to_cart button span {
  font-size: 1.3333333333rem !important;
}

.product .pb-center-column .box-info-product .functional-buttons {
  white-space: nowrap;
  font-size: 0;
  padding-top: 0px;
  line-height: 1;
}

.product .pb-center-column .box-info-product .functional-buttons .buttons_bottom_block, .product .pb-center-column .box-info-product .functional-buttons .compare {
  padding: 0;
  margin-bottom: 0;
  display: inline-block;
}

.product .pb-center-column .box-info-product .functional-buttons #wishlist_button_nopop, .product .pb-center-column .box-info-product .functional-buttons .add_to_compare {
  width: 38px;
  height: 38px;
  display: inline-block;
  font-size: 0;
  line-height: 38px;
  border-radius: 0px;
  border: none;
  background-color: #252a2f;
  color: #ffffff;
  text-align: center;
  padding: 0;
  transform: translate3d(0, 0, 0);
  /*width: 40px;
					height: 40px;
					line-height: 40px;
					float: left;
					&:before {
						font-size: em(14px) !important;
					}*/
}

.product .pb-center-column .box-info-product .functional-buttons #wishlist_button_nopop:hover, .product .pb-center-column .box-info-product .functional-buttons .add_to_compare:hover {
  background-color: #3498db;
  color: #ffffff;
}

.product .pb-center-column .box-info-product .functional-buttons #wishlist_button_nopop {
  float: left;
  margin-right: 1px;
}

.product .pb-center-column .box-info-product .functional-buttons #wishlist_button_nopop:before {
  content: "";
  font-family: "FontAweSome";
  display: block;
  font-size: 1.1666666667rem;
  color: #ffffff;
}

.product .pb-center-column .box-info-product .functional-buttons #wishlist_button_nopop.checked {
  background: #1a7eb8;
}

.product .pb-center-column .box-info-product .functional-buttons #wishlist_button_nopop.checked:before {
  content: "";
  font-family: "FontAweSome";
  display: block;
  font-size: 1.1666666667rem;
  color: #ffffff;
}

.product .pb-center-column .box-info-product .functional-buttons .add_to_compare {
  float: left;
}

.product .pb-center-column .box-info-product .functional-buttons .add_to_compare:before {
  content: "";
  font-family: "FontAweSome";
  display: block;
  font-size: 1.1666666667rem;
  color: #ffffff;
}

.product .pb-center-column .box-info-product .functional-buttons .add_to_compare.checked {
  background: #1a7eb8;
}

.product .pb-center-column .box-info-product .functional-buttons .add_to_compare.checked:before {
  content: "";
  font-family: "FontAweSome";
  display: block;
  font-size: 1.1666666667rem;
  color: #ffffff;
}

.product .pb-center-column .socialsharing_product {
  clear: both;
  padding-top: 15px;
}

.product .pb-center-column .socialsharing_product:before {
  content: "Share";
  display: none;
  text-transform: uppercase;
  font-size: 1.1666666667rem;
  font-weight: bold;
  color: #252a2f;
  float: left;
  width: 100px;
  margin-top: 10px;
}

@media (max-width: 767px) {
  .product .pb-center-column .socialsharing_product:before {
    margin-right: 10px;
  }
}

.product .pb-center-column .socialsharing_product button {
  background: #fff;
  border: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  margin-right: 0px;
  text-align: center;
  padding: 5px 5px;
}

.product .pb-center-column .socialsharing_product button:after {
  border-radius: 50% !important;
}

.product .pb-center-column .socialsharing_product button i {
  display: inline-block !important;
  font-size: 14px;
}

.product .pb-center-column .socialsharing_product button.btn-twitter i {
  color: #00aaf0;
}

.product .pb-center-column .socialsharing_product button.btn-facebook i {
  color: #435f9f;
}

.product .pb-center-column .socialsharing_product button.btn-google-plus i {
  color: #e04b34;
}

.product .pb-center-column .socialsharing_product button.btn-pinterest i {
  color: #ce1f21;
}

.product .pb-center-column .socialsharing_product button:hover {
  color: #ffffff;
}

.product .pb-center-column .socialsharing_product button:hover i {
  color: #ffffff;
}

.product .pb-center-column .socialsharing_product button:hover.btn-twitter {
  background: #00aaf0 !important;
}

.product .pb-center-column .socialsharing_product button:hover.btn-twitter:after {
  background: #00aaf0 !important;
}

.product .pb-center-column .socialsharing_product button:hover.btn-facebook {
  background: #435f9f !important;
}

.product .pb-center-column .socialsharing_product button:hover.btn-facebook:after {
  background: #435f9f !important;
}

.product .pb-center-column .socialsharing_product button:hover.btn-google-plus {
  background: #e04b34 !important;
}

.product .pb-center-column .socialsharing_product button:hover.btn-google-plus:after {
  background: #e04b34 !important;
}

.product .pb-center-column .socialsharing_product button:hover.btn-pinterest {
  background: #ce1f21 !important;
}

.product .pb-center-column .socialsharing_product button:hover.btn-pinterest:after {
  background: #ce1f21 !important;
}

@media (max-width: 991px) {
  .product .pb-right-column {
    display: none;
  }
}

.product .pb-right-column .product-custom-content h3, .product .pb-right-column .product-custom-content .h3 {
  color: #252a2f;
  margin-top: 0;
  margin-bottom: 30px;
}

.product .pb-right-column .product-custom-content .feature {
  *zoom: 1;
  margin-bottom: 20px;
}

.product .pb-right-column .product-custom-content .feature:after, .product .pb-right-column .product-custom-content .feature:before {
  content: "";
  display: table;
  clear: both;
}

.product .pb-right-column .product-custom-content .feature .icon {
  width: 55px;
  height: 55px;
  margin-right: 10px;
  float: left;
  line-height: 55px;
  border-radius: 55px;
  text-align: center;
  background-color: #3498db;
  color: #fff;
  font-size: 18px;
}

.product .pb-right-column .product-custom-content .feature .custom-title {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.3333333333rem;
  color: #252a2f;
  font-family: "Roboto Condensed", sans-serif;
  margin-top: 5px;
  margin-bottom: 5px;
}

.product #more_info_block #more_info_tabs {
  padding: 13px 20px;
  background: #fff;
  border-top: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-bottom: 4px solid #3498db;
  border-left: 1px solid #e8e8e8;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .product #more_info_block #more_info_tabs {
    padding: 10px;
  }
}

.product #more_info_block #more_info_tabs li {
  display: inline-block;
  float: left;
}

.product #more_info_block #more_info_tabs li a {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-right: 40px;
  display: block;
  color: #252a2f;
  -webkit-transition-duration: 0s;
  -o-transition-duration: 0s;
  transition-duration: 0s;
}

.product #more_info_block #more_info_tabs li a.selected, .product #more_info_block #more_info_tabs li a:hover {
  color: #3498db;
}

@media (max-width: 767px) {
  .product #more_info_block #more_info_tabs li a {
    display: inline-block;
    font-size: 16px;
    margin-right: 10px;
  }
}

.product #more_info_block #more_info_sheets {
  border: 1px solid #e8e8e8;
  padding: 20px;
  color: #252a2f;
}

.product #more_info_block .content-tab {
  padding: 0;
}

.product #more_info_block .table-data-sheet {
  border-color: #252a2f;
}

.product #more_info_block .table-data-sheet tr, .product #more_info_block .table-data-sheet td {
  border-color: #252a2f;
}

.product #more_info_block .table-data-sheet td:first-child {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  font-weight: bold;
  text-transform: uppercase;
}

.product.content_only {
  border: 10px solid #252a2f;
}

.product.content_only .primary_block.row {
  *zoom: 1;
  margin-left: 0;
  margin-right: 0;
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
}

.product.content_only .primary_block.row:after, .product.content_only .primary_block.row:before {
  content: "";
  display: table;
  clear: both;
}

.product.content_only #scroll-to-top {
  display: none;
}

.product.content_only .pb-left-column {
  padding-bottom: 30px;
  padding-left: 15px;
  float: left;
}

@media (max-width: 1199px) {
  .product.content_only .pb-left-column {
    width: 40%;
  }
}

@media (max-width: 991px) {
  .product.content_only .pb-left-column {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .product.content_only .pb-left-column {
    padding-right: 15px;
    padding-left: 15px;
    width: 50%;
  }
}

@media (max-width: 1199px) {
  .product.content_only .pb-left-column #views_block #thumbs_list li {
    width: 98px;
  }
}

@media (max-width: 991px) {
  .product.content_only .pb-left-column #views_block #thumbs_list li {
    width: 107px;
  }
}

@media (max-width: 1199px) {
  .product.content_only .pb-left-column #views_block #view_scroll_right {
    top: 0;
    right: 0;
  }
}

@media (max-width: 1199px) {
  .product.content_only .pb-left-column #views_block #view_scroll_left {
    top: 0;
    left: 0;
  }
}

.product.content_only .pb-center-column {
  padding-left: 15px;
  float: left;
}

@media (max-width: 1199px) {
  .product.content_only .pb-center-column {
    width: 60%;
  }
}

@media (max-width: 991px) {
  .product.content_only .pb-center-column {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .product.content_only .pb-center-column {
    width: 50%;
    padding-left: 15px;
  }
}

@media (max-width: 320px) {
  .product.content_only .pb-center-column .box-info-product .functional-buttons {
    float: left;
  }
}

@media (max-width: 1229px) {
  .product.content_only .pb-right-column {
    display: none;
  }
}

#index .product {
  display: none;
}

/*PRODUCT RELATED SECTION*/
.page-product-box {
  overflow: visible;
  padding-top: 30px;
}

.page-product-box .owl-controls .owl-nav .owl-prev, .page-product-box .owl-controls .owl-nav .owl-next {
  opacity: 0;
}

@media (max-width: 991px) {
  .page-product-box .owl-controls .owl-nav .owl-prev, .page-product-box .owl-controls .owl-nav .owl-next {
    opacity: 1;
  }
}

.page-product-box .owl-controls .owl-nav .owl-prev {
  transform: translate(100px, 0);
}

@media (max-width: 991px) {
  .page-product-box .owl-controls .owl-nav .owl-prev {
    transform: none;
  }
}

.page-product-box .owl-controls .owl-nav .owl-next {
  transform: translate(-100px, 0);
}

@media (max-width: 991px) {
  .page-product-box .owl-controls .owl-nav .owl-next {
    transform: none;
  }
}

.page-product-box:hover .owl-controls .owl-nav .owl-prev, .page-product-box:hover .owl-controls .owl-nav .owl-next {
  opacity: 1;
  transform: translate(0, 0);
}

.page-product-box .page-product-heading {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1;
  color: #3498db;
  background: none;
  padding: 13px 20px;
  background: #fff;
  border-top: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-bottom: 4px solid #3498db;
  border-left: 1px solid #e8e8e8;
  text-transform: uppercase;
}

.page-product-box .block_content {
  margin-left: -15px;
  margin-right: -15px;
}

.page-product-box .owl-controls {
  position: static !important;
}

.page-product-box .owl-controls .owl-prev,
.page-product-box .owl-controls .owl-next {
  background: #252a2f;
  position: absolute;
  top: 50%;
  margin: -20px 0 0 0 !important;
  width: 40px;
  height: 40px;
}

.page-product-box .owl-controls .owl-prev:before,
.page-product-box .owl-controls .owl-next:before {
  line-height: 40px;
  font-size: 14px;
}

.page-product-box .owl-controls .owl-prev:hover,
.page-product-box .owl-controls .owl-next:hover {
  color: #ffffff !important;
  background: #3498db;
}

.page-product-box .owl-controls .owl-prev {
  left: 0px !important;
}

.page-product-box .owl-controls .owl-next {
  right: 0px !important;
}

.page-product-box .item {
  transform: translate3d(0, 0, 0);
}

.page-product-box .item .product-container {
  border: 1px solid #e8e8e8;
  text-align: left;
  padding: 15px 15px 10px;
}

@media (max-width: 991px) and (min-width: 768px) {
  .page-product-box .item .product-container {
    padding: 10px 5px 10px;
  }
}

.page-product-box .item .product-container .product_desc .product-image {
  border: none;
  padding: 0;
}

.page-product-box .item .product-container .product-name a {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  color: #3498db;
  margin: 10px 0;
}

.page-product-box .item .product-container .product-name a:hover {
  color: #252a2f;
  text-decoration: underline;
}

.page-product-box .item .product-container .price {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 2rem;
  color: #252a2f;
}

.page-product-box .item .product-container .ajax_add_to_cart_button {
  padding-top: 10px;
  padding-bottom: 20px;
}

.page-product-box .item .product-container .ajax_add_to_cart_button span {
  transition: all 0.2s ease-out;
  height: 38px;
  line-height: 38px;
  display: inline-block;
  background-color: #3498db;
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.3333333333rem;
  color: #fff !important;
  text-align: center;
  padding: 0 15px;
  border-radius: 0;
}

.page-product-box .item .product-container .ajax_add_to_cart_button span:hover {
  background-color: #252a2f;
}

.page-product-box .item .product-container .ajax_add_to_cart_button:before {
  transition: all 0.2s ease-out;
  content: '';
  display: inline-block;
  float: right;
  width: 38px;
  height: 38px;
  display: inline-block;
  font-size: 0;
  line-height: 38px;
  border-radius: 0px;
  border: none;
  background-color: #252a2f;
  color: #ffffff;
  text-align: center;
  padding: 0;
  transform: translate3d(0, 0, 0);
}

.page-product-box .item .product-container .ajax_add_to_cart_button:before:hover {
  background-color: #3498db;
  color: #ffffff;
}

.page-product-box .item .product-container .ajax_add_to_cart_button:before {
  content: "";
  font-family: "FontAweSome";
  display: block;
  font-size: 1.1666666667rem;
  color: #ffffff;
}

.page-product-box .item .product-container .ajax_add_to_cart_button:hover span {
  background-color: #252a2f;
}

.page-product-box .item .product-container .ajax_add_to_cart_button:hover:before {
  background: #3498db;
  content: "\f218";
}

@media (max-width: 410px) {
  .page-product-box .item .product-container .ajax_add_to_cart_button span {
    display: none;
  }
}

.page-product-box .owl-controls .owl-prev, .page-product-box .owl-controls .owl-next {
  position: absolute;
  top: 50%;
}

.page-product-box .owl-controls .owl-prev {
  left: -20px;
}

.page-product-box .owl-controls .owl-next {
  right: -20px;
}

.heading-counter {
  margin-bottom: 0;
  font-family: "Roboto Condensed", sans-serif;
}

.heading-counter #summary_products_quantity {
  font-size: 1.1666666667rem;
  color: #3498db;
  font-weight: bold;
}

/*Step*/
ul.step {
  border-radius: 3px;
  margin-top: 25px;
}

ul.step li {
  position: relative;
  border: none;
  border-right: 1px solid #ddd !important;
  font-family: "Oswald", sans-serif;
}

@media (max-width: 767px) {
  ul.step li {
    border-right: none !important;
  }
}

ul.step li:last-child {
  border-right: none !important;
}

ul.step li a, ul.step li span {
  padding-right: 35px !important;
  font-size: 1.25rem;
}

ul.step li:before {
  display: block;
  position: absolute;
  right: 15px;
  top: 15px;
  font-family: "FontAweSome";
  color: #252a2f;
  font-size: 15px;
}

ul.step li.step_todo {
  background: #f3f3f3;
  border-radius: 0;
}

ul.step li.step_todo:before {
  content: "\f04e";
}

ul.step li.step_todo span, ul.step li.step_todo a {
  border-radius: 0;
  border: none;
  text-shadow: none;
  color: #252a2f;
  text-transform: uppercase;
}

ul.step li.step_todo span:after, ul.step li.step_todo a:after {
  display: none;
}

ul.step li.step_current, ul.step li.step_done {
  background: #252a2f;
  border-radius: 0;
}

ul.step li.step_current a, ul.step li.step_current span, ul.step li.step_done a, ul.step li.step_done span {
  text-shadow: none;
  border: none;
  text-transform: uppercase;
}

ul.step li.step_current a:after, ul.step li.step_current span:after, ul.step li.step_done a:after, ul.step li.step_done span:after {
  display: none;
}

ul.step li.step_current:before {
  content: "\f0c6";
  color: #fff;
}

ul.step li.step_current span {
  font-size: 1.25rem;
}

ul.step li.step_done {
  background: #3498db;
}

ul.step li.step_done:before {
  content: "\f046";
  color: #fff;
  font-style: normal;
}

/*cart summary table*/
#cart_summary tr td, #cart_summary tr th {
  border-color: #ddd;
  padding: 10px 15px;
}

#cart_summary tbody td.cart_product {
  padding: 15px;
}

#cart_summary tbody td.cart_product img {
  border: none;
}

#cart_summary tbody td.cart_delete .cart_quantity_delete i:before {
  content: "\f146";
}

#cart_summary tbody td.cart_delete .cart_quantity_delete:hover {
  color: #3498db;
}

#cart_summary tfoot tr {
  background: none;
}

#cart_summary tfoot tr td {
  padding: 10px 15px;
}

#cart_summary tfoot tr td.text-right {
  font-weight: normal;
}

#cart_summary tfoot tr td.total_price_container span {
  font-family: "Roboto Condensed", sans-serif;
}

#cart_summary > thead > tr > th {
  background: #3498db;
  color: #ffffff;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.3333333333rem;
  text-transform: uppercase;
}

#cart_summary .price {
  color: #252a2f;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.5rem;
}

#cart_summary .price .old-price {
  font-size: 1.1666666667rem;
  margin-top: 5px;
}

#cart_summary .price .price-percent-reduction, #cart_summary .price .product .pb-center-column .content_prices #reduction_percent, .product .pb-center-column .content_prices #cart_summary .price #reduction_percent {
  display: none;
  border-radius: 0;
  height: 30px;
  line-height: 30px;
  padding: 0;
  width: auto;
  padding: 0 10px;
  position: relative;
  float: left;
  top: 0;
  right: 0;
  font-size: 1.1666666667rem;
}

#cart_summary #total_price {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  color: #3498db;
  font-weight: bold;
}

#cart_summary .cart_quantity input, #cart_summary .cart_quantity > span {
  color: #252a2f;
  font-size: 1.3333333333rem;
}

#cart_summary .cart_quantity > span {
  font-size: 1.5rem;
}

#cart_summary .cart_quantity_button {
  margin-top: 5px;
}

#cart_summary .btn.button-plus, #cart_summary .btn.button-minus {
  color: #ffffff;
  border: none;
  overflow: hidden;
}

@media (max-width: 767px) {
  #cart_summary .btn.button-plus, #cart_summary .btn.button-minus {
    display: inline-block;
    float: left;
  }
}

#cart_summary .btn.button-plus span, #cart_summary .btn.button-minus span {
  background: #252a2f;
  border-radius: 4px;
}

#cart_summary .btn.button-plus:hover span, #cart_summary .btn.button-minus:hover span {
  background: #3498db;
}

#cart_summary .btn.button-plus {
  float: right;
}

.cart_navigation {
  padding-top: 30px;
}

.cart_navigation .standard-checkout, .cart_navigation .button-exclusive, .cart_navigation .btn {
  margin: 0;
}

@media (max-width: 767px) {
  .cart_navigation .standard-checkout, .cart_navigation .button-exclusive, .cart_navigation .btn {
    display: block;
    margin-bottom: 10px;
    float: left;
    width: 100%;
  }
}

.cart_navigation .standard-checkout i.right, .cart_navigation .standard-checkout i.left, .cart_navigation .standard-checkout i, .cart_navigation .button-exclusive i.right, .cart_navigation .button-exclusive i.left, .cart_navigation .button-exclusive i, .cart_navigation .btn i.right, .cart_navigation .btn i.left, .cart_navigation .btn i {
  color: inherit;
  font-size: 12px;
  line-height: 1;
  vertical-align: 0px;
  display: inline-block;
}

.cart_navigation .standard-checkout span, .cart_navigation .button-exclusive span, .cart_navigation .btn span {
  font-size: 1.1666666667rem;
}

.cart_navigation .standard-checkout, .cart_navigation [type="submit"] {
  height: 34px;
  line-height: 34px !important;
  display: inline-block;
  background-color: #252a2f;
  text-transform: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  font-weight: normal;
  color: #ffffff !important;
  text-align: center;
  padding: 0 15px;
  border: none;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.cart_navigation .standard-checkout span, .cart_navigation [type="submit"] span {
  font-size: inherit !important;
  color: inherit !important;
}

.cart_navigation .standard-checkout:after, .cart_navigation [type="submit"]:after {
  /*content:'';
		display: block;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
	background-color: $colorBtn_1;
		transform: scale(0,0);
		opacity: 0;	
		border-radius: 0px;*/
}

.cart_navigation .standard-checkout:hover, .cart_navigation [type="submit"]:hover {
  color: #ffffff !important;
  background-color: #3498db;
  /*&:after {
			transition: all .3s ease;
			transform: scale(1,1);	
			opacity: 1;
		}*/
}

.cart_navigation .standard-checkout span, .cart_navigation [type="submit"] span {
  border: none;
  padding: 0;
}

.cart_navigation .standard-checkout i, .cart_navigation [type="submit"] i {
  display: inline-block !important;
}

.cart_navigation .button-exclusive {
  height: 34px;
  line-height: 34px !important;
  padding: 0 15px;
  display: inline-block;
  background-color: #3498db;
  text-transform: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  font-weight: none;
  color: #ffffff !important;
  text-align: center;
  padding: 0 12px;
  border: none;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.cart_navigation .button-exclusive span {
  font-size: inherit !important;
  color: inherit !important;
}

.cart_navigation .button-exclusive:after {
  /*content:'';
		display: block;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
		background-color: #fff;
		transform: scale(0,0);
		opacity: 0;	
		border-radius: 0px;*/
}

.cart_navigation .button-exclusive:hover {
  color: #ffffff !important;
  background-color: #252a2f;
  /*&:after {
			transition: all .35s ease;
			transform: scale(1,1);	
			opacity: 1;
		}*/
}

#order .top-breadcrumb, #authentication .top-breadcrumb, #address .top-breadcrumb, #identity .top-breadcrumb, #addresses .top-breadcrumb, #my-account .top-breadcrumb {
  padding-bottom: 20px;
}

#order label, #authentication label, #address label, #identity label, #addresses label, #my-account label {
  margin-bottom: 10px;
}

#order .form-group, #authentication .form-group, #address .form-group, #identity .form-group, #addresses .form-group, #my-account .form-group {
  margin-top: 0px;
  margin-bottom: 20px;
}

#order .checkbox .checker, #authentication .checkbox .checker, #address .checkbox .checker, #identity .checkbox .checker, #addresses .checkbox .checker, #my-account .checkbox .checker {
  margin-top: 0px;
}

#order .footer_links, #authentication .footer_links, #address .footer_links, #identity .footer_links, #addresses .footer_links, #my-account .footer_links {
  border-color: #ddd;
}

#order .addresses div.checker {
  margin-top: 0px;
}

#order .addresses .address_add .btn {
  height: 34px;
  line-height: 34px !important;
  display: inline-block;
  background-color: #252a2f;
  text-transform: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  font-weight: normal;
  color: #ffffff !important;
  text-align: center;
  padding: 0 15px;
  border: none;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

#order .addresses .address_add .btn span {
  font-size: inherit !important;
  color: inherit !important;
}

#order .addresses .address_add .btn:after {
  /*content:'';
		display: block;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
	background-color: $colorBtn_1;
		transform: scale(0,0);
		opacity: 0;	
		border-radius: 0px;*/
}

#order .addresses .address_add .btn:hover {
  color: #ffffff !important;
  background-color: #3498db;
  /*&:after {
			transition: all .3s ease;
			transform: scale(1,1);	
			opacity: 1;
		}*/
}

#order .addresses .address_add.submit {
  margin-top: 20px;
}

/*Payment module*/
p.payment_module a {
  border-radius: 0;
  background-color: transparent;
  border-color: #eee;
  background-position: 15px 50%;
}

p.payment_module a:hover {
  color: #3498db;
}

p.payment_module a:hover span {
  color: inherit;
}

p.payment_module a:hover:after {
  color: inherit;
}

/*Authentication*/
#authentication .box {
  border: none;
  background: none;
  padding-left: 0;
  padding-right: 0;
}

#authentication h3 + .required, #authentication .h3 + .required {
  display: none;
}

#authentication #create-account_form {
  border: 1px solid #e8e8e8;
  padding: 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

#authentication #create-account_form p {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  color: #252a2f;
}

@media (max-width: 767px) {
  #authentication #create-account_form {
    min-height: 0;
  }
}

#authentication #login_form {
  border: 1px solid #e8e8e8;
  padding: 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

#authentication #login_form button#SubmitLogin {
  height: 34px;
  line-height: 34px !important;
  display: inline-block;
  background-color: #252a2f;
  text-transform: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  font-weight: normal;
  color: #ffffff !important;
  text-align: center;
  padding: 0 15px;
  border: none;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

#authentication #login_form button#SubmitLogin span {
  font-size: inherit !important;
  color: inherit !important;
}

#authentication #login_form button#SubmitLogin:after {
  /*content:'';
		display: block;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
	background-color: $colorBtn_1;
		transform: scale(0,0);
		opacity: 0;	
		border-radius: 0px;*/
}

#authentication #login_form button#SubmitLogin:hover {
  color: #ffffff !important;
  background-color: #3498db;
  /*&:after {
			transition: all .3s ease;
			transform: scale(1,1);	
			opacity: 1;
		}*/
}

#authentication #login_form .lost_password a {
  color: red;
}

#authentication #login_form .lost_password a:hover {
  color: #3498db;
  text-decoration: underline;
}

@media (max-width: 480px) {
  #authentication input.form-control {
    width: 100%;
    max-width: 100% !important;
  }
}

/*Adress*/
#address .top-breadcrumb {
  padding-bottom: 20px;
}

#address #adress_alias {
  margin-bottom: 40px;
}

/*My account*/
#my-account #center_column {
  overflow: hidden;
}

#my-account ul.myaccount-link-list li a {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.3333333333rem;
  border-radius: 3px;
  background: none;
  border-color: #3498db;
  color: #252a2f;
}

#my-account ul.myaccount-link-list li a i {
  text-shadow: none;
  border-radius: 0;
  border: none;
  color: inherit;
  font-size: 1.5rem;
  padding: 15px;
}

#my-account ul.myaccount-link-list li a span {
  font-weight: normal;
  border: none;
  border-left: 1px solid #3498db;
  color: inherit;
  text-shadow: none;
  border-radius: 0;
}

#my-account ul.myaccount-link-list li a:hover {
  background: #3498db;
  color: #ffffff;
  border-color: #ddd;
}

#my-account ul.myaccount-link-list li a:hover span {
  border-left: 1px solid #fff;
}

/*Product Compare*/
#products-comparison #product_comparison {
  border-color: #f3f3f3;
}

#products-comparison #product_comparison .remove a {
  font-size: 20px;
  line-height: 1;
  color: #252a2f;
}

#products-comparison #product_comparison .remove a:hover {
  color: red;
}

#products-comparison #product_comparison .remove .icon-trash:before {
  content: "\f146";
}

#products-comparison #product_comparison tr td {
  line-height: 1;
  border-color: #ddd;
  padding: 10px 15px;
}

#products-comparison #product_comparison tr td.comparison_infos {
  position: relative;
}

#products-comparison #product_comparison tr td.comparison_infos .btn:not(.ajax_add_to_cart_button) {
  height: 34px;
  line-height: 34px !important;
  display: inline-block;
  background-color: #252a2f;
  text-transform: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  font-weight: normal;
  color: #ffffff !important;
  text-align: center;
  padding: 0 15px;
  border: none;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

#products-comparison #product_comparison tr td.comparison_infos .btn:not(.ajax_add_to_cart_button) span {
  font-size: inherit !important;
  color: inherit !important;
}

#products-comparison #product_comparison tr td.comparison_infos .btn:not(.ajax_add_to_cart_button):after {
  /*content:'';
		display: block;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
	background-color: $colorBtn_1;
		transform: scale(0,0);
		opacity: 0;	
		border-radius: 0px;*/
}

#products-comparison #product_comparison tr td.comparison_infos .btn:not(.ajax_add_to_cart_button):hover {
  color: #ffffff !important;
  background-color: #3498db;
  /*&:after {
			transition: all .3s ease;
			transform: scale(1,1);	
			opacity: 1;
		}*/
}

#products-comparison #product_comparison tr td.td_empty, #products-comparison #product_comparison tr td.feature-name {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.3333333333rem;
  color: #252a2f;
}

#products-comparison #product_comparison tr td.td_empty strong, #products-comparison #product_comparison tr td.feature-name strong {
  font-weight: normal;
}

#products-comparison #product_comparison tr td.compare_extra_information span, #products-comparison #product_comparison tr td.feature-name.td_empty span {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  font-weight: normal;
  color: #3498db;
}

#products-comparison #product_comparison tr td .price-percent-reduction, #products-comparison #product_comparison tr td .product .pb-center-column .content_prices #reduction_percent, .product .pb-center-column .content_prices #products-comparison #product_comparison tr td #reduction_percent {
  top: 54px;
  margin-top: 15px;
}

#products-comparison #product_comparison tr td .product-rating div.star:after,
#products-comparison #product_comparison tr td .product-rating div.star.star_on:after {
  color: #3498db;
}

#products-comparison #product_comparison .product-image-block {
  width: 100%;
}

#products-comparison #product_comparison .product-image-block .product_image {
  border: none;
}

#products-comparison #product_comparison .product-image-block .product_image img {
  width: 100%;
}

#products-comparison #product_comparison h5, #products-comparison #product_comparison .h5 {
  min-height: 0;
  padding-bottom: 0;
}

#products-comparison #product_comparison .button-container {
  font-size: 0;
}

#products-comparison #product_comparison .button-container a {
  margin: 0;
}

#products-comparison #product_comparison .button-container a.lnk_view {
  height: 34px;
  line-height: 34px !important;
  display: inline-block;
  background-color: #252a2f;
  text-transform: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  font-weight: normal;
  color: #ffffff !important;
  text-align: center;
  padding: 0 15px;
  border: none;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 0 !important;
  margin-top: 0;
  height: 38px !important;
  margin-left: 1px;
}

#products-comparison #product_comparison .button-container a.lnk_view span {
  font-size: inherit !important;
  color: inherit !important;
}

#products-comparison #product_comparison .button-container a.lnk_view:after {
  /*content:'';
		display: block;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
	background-color: $colorBtn_1;
		transform: scale(0,0);
		opacity: 0;	
		border-radius: 0px;*/
}

#products-comparison #product_comparison .button-container a.lnk_view:hover {
  color: #ffffff !important;
  background-color: #3498db;
  /*&:after {
			transition: all .3s ease;
			transform: scale(1,1);	
			opacity: 1;
		}*/
}

#products-comparison #product_comparison .button-container a.lnk_view span {
  padding-top: 3px;
  display: inline-block;
  margin-right: 0;
}

#products-comparison #product_comparison .button-container .ajax_add_to_cart_button {
  display: inline-block;
  margin-top: 0;
  vertical-align: top;
}

#products-comparison #product_comparison .button-container .ajax_add_to_cart_button span {
  transition: all 0.2s ease-out;
  height: 38px;
  line-height: 38px;
  display: inline-block;
  background-color: #3498db;
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.3333333333rem;
  color: #fff !important;
  text-align: center;
  padding: 0 15px;
  border-radius: 0;
}

#products-comparison #product_comparison .button-container .ajax_add_to_cart_button span:hover {
  background-color: #252a2f;
}

#products-comparison #product_comparison .button-container .ajax_add_to_cart_button:before {
  transition: all 0.2s ease-out;
  content: '';
  display: inline-block;
  float: right;
  width: 38px;
  height: 38px;
  display: inline-block;
  font-size: 0;
  line-height: 38px;
  border-radius: 0px;
  border: none;
  background-color: #252a2f;
  color: #ffffff;
  text-align: center;
  padding: 0;
  transform: translate3d(0, 0, 0);
}

#products-comparison #product_comparison .button-container .ajax_add_to_cart_button:before:hover {
  background-color: #3498db;
  color: #ffffff;
}

#products-comparison #product_comparison .button-container .ajax_add_to_cart_button:before {
  content: "";
  font-family: "FontAweSome";
  display: block;
  font-size: 1.1666666667rem;
  color: #ffffff;
}

#products-comparison #product_comparison .button-container .ajax_add_to_cart_button:hover span {
  background-color: #252a2f;
}

#products-comparison #product_comparison .button-container .ajax_add_to_cart_button:hover:before {
  background: #3498db;
  content: "\f218";
}

#products-comparison .table-responsive {
  margin-top: 30px;
}

#products-comparison .ajax_block_product .remove {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
}

#products-comparison .ajax_block_product .product-image-block .product_image {
  padding: 0;
}

#products-comparison .ajax_block_product .product-image-block .new-box, #products-comparison .ajax_block_product .product-image-block .price-percent-reduction, #products-comparison .ajax_block_product .product-image-block .product .pb-center-column .content_prices #reduction_percent, .product .pb-center-column .content_prices #products-comparison .ajax_block_product .product-image-block #reduction_percent, #products-comparison .ajax_block_product .product-image-block .sale-box {
  left: -15px;
}

#products-comparison .ajax_block_product .product-image-block .new-box, #products-comparison .ajax_block_product .product-image-block .price-percent-reduction, #products-comparison .ajax_block_product .product-image-block .product .pb-center-column .content_prices #reduction_percent, .product .pb-center-column .content_prices #products-comparison .ajax_block_product .product-image-block #reduction_percent {
  top: -10px;
}

#products-comparison .ajax_block_product .product-image-block .sale-box {
  top: 15px;
}

#password #center_column .box {
  padding-top: 20px;
}

#password .footer_links {
  border-color: #ddd;
}

#password .footer_links .button {
  height: 34px;
  line-height: 34px !important;
  display: inline-block;
  background-color: #252a2f;
  text-transform: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  font-weight: normal;
  color: #ffffff !important;
  text-align: center;
  padding: 0 15px;
  border: none;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

#password .footer_links .button span {
  font-size: inherit !important;
  color: inherit !important;
}

#password .footer_links .button:after {
  /*content:'';
		display: block;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
	background-color: $colorBtn_1;
		transform: scale(0,0);
		opacity: 0;	
		border-radius: 0px;*/
}

#password .footer_links .button:hover {
  color: #ffffff !important;
  background-color: #3498db;
  /*&:after {
			transition: all .3s ease;
			transform: scale(1,1);	
			opacity: 1;
		}*/
}

/*ONESTEP CHECKOUT*/
#order-opc div.selector {
  width: 100%;
}

#order-opc div.selector span, #order-opc div.selector select {
  width: 100%;
}

#order-opc .opc-wapper {
  margin-left: -15px;
  margin-right: -15px;
  *zoom: 1;
}

#order-opc .opc-wapper:after, #order-opc .opc-wapper:before {
  content: "";
  display: table;
  clear: both;
}

#order-opc .opc-wapper .page-heading {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 2rem;
  text-align: left;
  margin-bottom: 20px !important;
  border-bottom: 2px solid #3498db;
}

#order-opc .opc-wapper .page-heading:before, #order-opc .opc-wapper .page-heading:after {
  display: none;
}

#order-opc #openLoginFormBlock {
  color: #252a2f;
}

#order-opc #openLoginFormBlock:hover {
  color: #3498db;
}

#order-opc .form-group {
  margin-bottom: 10px;
}

#order-opc .form-group .form-control {
  width: 100%;
  max-width: 100%;
}

#order-opc #opc_new_account,
#order-opc #carrier_area,
#order-opc .opc_payment_area,
#order-opc .opc-account-area {
  width: 50%;
  float: left;
  padding: 0 15px;
}

@media (max-width: 767px) {
  #order-opc #opc_new_account,
  #order-opc #carrier_area,
  #order-opc .opc_payment_area,
  #order-opc .opc-account-area {
    width: 100%;
  }
}

#order-opc #opc_new_account .opc-account-area {
  width: 100%;
}

#order-opc .opc-add-save {
  border-color: #f3f3f3;
}

#order-opc div.radio-inline label {
  margin-bottom: 0;
}

#order-opc p.warning {
  font-weight: normal !important;
}

/*OUR STORES*/
#stores .radius-input label {
  padding-top: 5px;
}

/*ABOUT US*/
.cms-about-us #center_column ul {
  list-style: none;
  padding-left: 0;
}

.cms-about-us #center_column ul li {
  border-top: 1px solid #f3f3f3 !important;
  color: #3498db;
  padding-left: 0;
}

.cms-about-us #center_column ul li:first-child {
  border: none !important;
}

.cms-about-us #center_column ul li .icon-ok {
  padding-right: 10px !important;
  width: auto;
}

.cms-about-us #center_column ul li .icon-ok:before {
  content: "\f058";
  font-style: normal;
  font-family: "FontAweSome";
  display: inline-block;
  font-size: 12px;
  color: inherit;
  float: left;
  position: relative;
  top: 4px;
}

.cms-about-us #center_column .cms-block, .cms-about-us #center_column .cms-box {
  border: 1px solid #e8e8e8;
  padding: 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

/*CONTACT*/
#contact .contact-info-icons {
  padding-top: 30px;
}

#contact .contact-info {
  padding-bottom: 0px;
  text-align: center;
}

@media (max-width: 767px) {
  #contact .contact-info {
    text-align: left;
    padding-bottom: 20px;
  }
}

#contact .contact-info .icon {
  margin-right: 10px;
  float: none;
  width: 30px;
  margin-bottom: 10px;
}

#contact .contact-info .icon img {
  display: none;
}

#contact .contact-info .icon:before {
  display: inline-block;
  font-family: "FontAweSome";
  font-size: 18px;
  color: #3498db;
}

#contact .contact-info .icon.icon-phone-icon:before {
  content: "\f095";
}

#contact .contact-info .icon.icon-mailicon:before {
  content: "\f0e0";
}

#contact .contact-info .icon.icon-faxicon:before {
  content: "\f1ac";
}

#contact .contact-info .contact-info-title {
  display: block;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #3498db;
  margin-bottom: 5px;
}

@media (max-width: 767px) {
  #contact .contact-info .contact-info-title {
    display: inline-block;
  }
}

#contact .contact-info .contact-info-subtitle {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  text-transform: none;
  color: #252a2f;
}

@media (max-width: 767px) {
  #contact .contact-info .contact-info-subtitle {
    clear: both;
    display: block;
    padding-left: 40px;
  }
}

#contact .desc_contact.contact-title {
  margin-bottom: 20px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  color: #3498db;
}

#contact .desc_contact.contact-title i {
  color: #3498db;
}

#contact .contact-form-box {
  background: none;
  border: 1px solid #e8e8e8;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  padding: 20px;
  text-align: left;
}

#contact .contact-form-box fieldset {
  background: none;
  padding: 0;
}

#contact .contact-form-box fieldset > .clearfix {
  text-align: left;
}

#contact .contact-form-box fieldset > .clearfix > div:nth-child(1) {
  padding-left: 0;
}

@media (max-width: 991px) {
  #contact .contact-form-box fieldset > .clearfix > div:nth-child(1) {
    padding-right: 0;
    margin-bottom: 15px;
  }
}

#contact .contact-form-box fieldset > .clearfix > div:nth-child(2) {
  padding-right: 0;
}

@media (max-width: 991px) {
  #contact .contact-form-box fieldset > .clearfix > div:nth-child(2) {
    padding-left: 0;
  }
}

#contact .contact-form-box .page-subheading, #contact .contact-form-box .category #center_column .content_scene_cat_bg .category-name, .category #center_column .content_scene_cat_bg #contact .contact-form-box .category-name, #contact .contact-form-box .category #center_column #subcategories .subcategory-heading, .category #center_column #subcategories #contact .contact-form-box .subcategory-heading, #contact .contact-form-box .product .pb-center-column h1, .product .pb-center-column #contact .contact-form-box h1, #contact .contact-form-box .product .pb-center-column .h1, .product .pb-center-column #contact .contact-form-box .h1, #contact .contact-form-box .module-bcblog-post .out_related_products .title, .module-bcblog-post .out_related_products #contact .contact-form-box .title, #contact .contact-form-box .module-bcblog-post .blog-comment-list .title, .module-bcblog-post .blog-comment-list #contact .contact-form-box .title, #contact .contact-form-box .module-bcblog-post .related_posts .title, .module-bcblog-post .related_posts #contact .contact-form-box .title, #contact .contact-form-box .module-bcblog-post .pl_comment_form .title, .module-bcblog-post .pl_comment_form #contact .contact-form-box .title {
  margin-top: 0;
}

#contact .contact-form-box div.selector {
  width: 100% !important;
}

#contact .contact-form-box div.selector select.form-control {
  max-width: 100%;
}

#contact .contact-form-box .form-group {
  margin-bottom: 20px;
}

#contact .contact-form-box input {
  width: 100%;
  max-width: 100%;
  background: #fff;
}

#contact .contact-form-box div.uploader {
  height: 34px;
  line-height: 32px;
}

#contact .contact-form-box div.uploader input, #contact .contact-form-box div.uploader span {
  height: 34px;
  line-height: 32px;
  border-radius: 4px;
  background-color: #fff;
  font-size: 1.1666666667rem;
  color: #8b8b8b;
}

#contact .contact-form-box div.uploader span.action {
  background-image: none;
  height: 34px;
  line-height: 34px !important;
  display: inline-block;
  background-color: #252a2f;
  text-transform: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  font-weight: normal;
  color: #ffffff !important;
  text-align: center;
  padding: 0 15px;
  border: none;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  height: 34px;
  line-height: 34px !important;
  width: auto;
  margin-left: 5px;
}

#contact .contact-form-box div.uploader span.action span {
  font-size: inherit !important;
  color: inherit !important;
}

#contact .contact-form-box div.uploader span.action:after {
  /*content:'';
		display: block;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
	background-color: $colorBtn_1;
		transform: scale(0,0);
		opacity: 0;	
		border-radius: 0px;*/
}

#contact .contact-form-box div.uploader span.action:hover {
  color: #ffffff !important;
  background-color: #3498db;
  /*&:after {
			transition: all .3s ease;
			transform: scale(1,1);	
			opacity: 1;
		}*/
}

@media (max-width: 370px) {
  #contact .contact-form-box div.uploader span.action {
    padding: 0 5px;
    min-width: 0;
  }
}

#contact .contact-form-box div.uploader span.filename {
  float: left;
  width: calc(100% - 133px);
  border-color: #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

#contact address .address-title {
  color: #3498db;
  margin-bottom: 10px;
  display: block;
  text-align: center;
  text-transform: uppercase;
}

#contact address + p {
  overflow: hidden;
}

/*SITEMAP*/
#left_column ul.tree {
  padding-left: 0;
}

#left_column ul.tree li {
  padding: 0;
  margin-left: 0;
  background: none;
  border: none;
}

#sitemap .sitemap_block li a:hover {
  font-weight: normal;
  color: #3498db;
  text-decoration: underline;
}

/*ADDRESSES*/
#addresses .top-breadcrumb {
  padding-bottom: 20px;
}

#addresses #center_column {
  overflow: hidden;
}

#addresses .address_update a:nth-child(2) {
  height: 34px;
  line-height: 34px !important;
  display: inline-block;
  background-color: #252a2f;
  text-transform: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  font-weight: normal;
  color: #ffffff !important;
  text-align: center;
  padding: 0 15px;
  border: none;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

#addresses .address_update a:nth-child(2) span {
  font-size: inherit !important;
  color: inherit !important;
}

#addresses .address_update a:nth-child(2):after {
  /*content:'';
		display: block;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
	background-color: $colorBtn_1;
		transform: scale(0,0);
		opacity: 0;	
		border-radius: 0px;*/
}

#addresses .address_update a:nth-child(2):hover {
  color: #ffffff !important;
  background-color: #3498db;
  /*&:after {
			transition: all .3s ease;
			transform: scale(1,1);	
			opacity: 1;
		}*/
}

#addresses .footer_links li:nth-child(2) a {
  height: 34px;
  line-height: 34px !important;
  display: inline-block;
  background-color: #252a2f;
  text-transform: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  font-weight: normal;
  color: #ffffff !important;
  text-align: center;
  padding: 0 15px;
  border: none;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

#addresses .footer_links li:nth-child(2) a span {
  font-size: inherit !important;
  color: inherit !important;
}

#addresses .footer_links li:nth-child(2) a:after {
  /*content:'';
		display: block;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
	background-color: $colorBtn_1;
		transform: scale(0,0);
		opacity: 0;	
		border-radius: 0px;*/
}

#addresses .footer_links li:nth-child(2) a:hover {
  color: #ffffff !important;
  background-color: #3498db;
  /*&:after {
			transition: all .3s ease;
			transform: scale(1,1);	
			opacity: 1;
		}*/
}

/*IDENTITY*/
#identity .footer_links li:nth-child(2) a {
  height: 34px;
  line-height: 34px !important;
  display: inline-block;
  background-color: #252a2f;
  text-transform: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  font-weight: normal;
  color: #ffffff !important;
  text-align: center;
  padding: 0 15px;
  border: none;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

#identity .footer_links li:nth-child(2) a span {
  font-size: inherit !important;
  color: inherit !important;
}

#identity .footer_links li:nth-child(2) a:after {
  /*content:'';
		display: block;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
	background-color: $colorBtn_1;
		transform: scale(0,0);
		opacity: 0;	
		border-radius: 0px;*/
}

#identity .footer_links li:nth-child(2) a:hover {
  color: #ffffff !important;
  background-color: #3498db;
  /*&:after {
			transition: all .3s ease;
			transform: scale(1,1);	
			opacity: 1;
		}*/
}

/*WISHLIST*/
#module-blockwishlist-mywishlist .footer_links li:nth-child(2) a {
  height: 34px;
  line-height: 34px !important;
  display: inline-block;
  background-color: #252a2f;
  text-transform: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  font-weight: normal;
  color: #ffffff !important;
  text-align: center;
  padding: 0 15px;
  border: none;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

#module-blockwishlist-mywishlist .footer_links li:nth-child(2) a span {
  font-size: inherit !important;
  color: inherit !important;
}

#module-blockwishlist-mywishlist .footer_links li:nth-child(2) a:after {
  /*content:'';
		display: block;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
	background-color: $colorBtn_1;
		transform: scale(0,0);
		opacity: 0;	
		border-radius: 0px;*/
}

#module-blockwishlist-mywishlist .footer_links li:nth-child(2) a:hover {
  color: #ffffff !important;
  background-color: #3498db;
  /*&:after {
			transition: all .3s ease;
			transform: scale(1,1);	
			opacity: 1;
		}*/
}

/*-- Hide overflow line below page-heading --*/
#left_column + #center_column {
  overflow: hidden;
}

/*==============================CSS FOR PASSWORD PAGE==============================*/
#password p.submit {
  margin-bottom: 20px;
}

.module-bcblog-categoryPost .select {
  text-align: right;
}

.module-bcblog-categoryPost div.selector {
  max-width: 200px;
  display: inline-block;
}

.module-bcblog-categoryPost div.selector span {
  text-align: left;
}

.module-bcblog-categoryPost .content_sortPagiBar .top-pagination-content .nbrItemPage, .module-bcblog-categoryPost .content_sortPagiBar .top-pagination-content #productsSortForm {
  margin-left: 0 !important;
  margin-right: 20px !important;
  float: left !important;
}

@media (max-width: 767px) {
  .module-bcblog-categoryPost .content_sortPagiBar .top-pagination-content .nbrItemPage, .module-bcblog-categoryPost .content_sortPagiBar .top-pagination-content #productsSortForm {
    width: 50%;
    margin-right: 0 !important;
    padding-left: 0;
    padding-right: 15px;
    clear: none;
  }
  .module-bcblog-categoryPost .content_sortPagiBar .top-pagination-content .nbrItemPage label, .module-bcblog-categoryPost .content_sortPagiBar .top-pagination-content #productsSortForm label {
    width: auto;
    float: left;
  }
  .module-bcblog-categoryPost .content_sortPagiBar .top-pagination-content .nbrItemPage .selector, .module-bcblog-categoryPost .content_sortPagiBar .top-pagination-content #productsSortForm .selector {
    float: left;
  }
}

.module-bcblog-categoryPost .content_sortPagiBar .top-pagination-content .nbrItemPage input {
  height: 34px;
  line-height: 34px !important;
  display: inline-block;
  background-color: #252a2f;
  text-transform: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  font-weight: normal;
  color: #ffffff !important;
  text-align: center;
  padding: 0 15px;
  border: none;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border: none;
  vertical-align: bottom;
}

.module-bcblog-categoryPost .content_sortPagiBar .top-pagination-content .nbrItemPage input span {
  font-size: inherit !important;
  color: inherit !important;
}

.module-bcblog-categoryPost .content_sortPagiBar .top-pagination-content .nbrItemPage input:after {
  /*content:'';
		display: block;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
	background-color: $colorBtn_1;
		transform: scale(0,0);
		opacity: 0;	
		border-radius: 0px;*/
}

.module-bcblog-categoryPost .content_sortPagiBar .top-pagination-content .nbrItemPage input:hover {
  color: #ffffff !important;
  background-color: #3498db;
  /*&:after {
			transition: all .3s ease;
			transform: scale(1,1);	
			opacity: 1;
		}*/
}

@media (max-width: 767px) {
  .module-bcblog-categoryPost .content_sortPagiBar .top-pagination-content .nbrItemPage input {
    float: left;
  }
}

@media (max-width: 767px) {
  .module-bcblog-categoryPost .content_sortPagiBar .top-pagination-content #productsSortForm {
    padding-right: 0;
    padding-left: 15px;
  }
}

.module-bcblog-categoryPost .product-count {
  margin-top: 10px;
  display: inline-block;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.3333333333rem;
  color: #3498db;
  margin-left: 10px;
}

.module-bcblog-categoryPost .rss {
  margin-left: 10px;
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.3333333333rem;
  color: #3498db;
}

.module-bcblog-categoryPost .rss a {
  color: inherit;
}

.module-bcblog-categoryPost .rss:hover {
  color: #252a2f;
}

.module-bcblog-categoryPost .rss:hover a {
  text-decoration: underline;
}

.module-bcblog-categoryPost .columns-container {
  /*Blog Category*/
}

.module-bcblog-categoryPost .columns-container ul.product_list {
  margin-left: -15px;
  margin-right: -15px;
  border: none;
}

.module-bcblog-categoryPost .columns-container ul.product_list .bc-item {
  margin-bottom: 40px;
  padding: 0 15px;
}

.module-bcblog-categoryPost .columns-container ul.product_list .bc-left-post .post-image {
  position: relative;
  overflow: hidden;
}

.module-bcblog-categoryPost .columns-container ul.product_list .bc-left-post .post-image a {
  display: inline-block;
  overflow: hidden;
  position: relative;
}

.module-bcblog-categoryPost .columns-container ul.product_list .bc-left-post .post-image a:before, .module-bcblog-categoryPost .columns-container ul.product_list .bc-left-post .post-image a:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(67, 69, 113, 0.1);
  transform: scale(1, 1);
  transition: all 0.6s ease-in-out;
  z-index: 10;
}

.module-bcblog-categoryPost .columns-container ul.product_list .bc-left-post .post-image a img {
  width: 100%;
  height: auto;
  -webkit-transition: all .35s;
  -o-transition: all .35s;
  transition: all .35s;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.module-bcblog-categoryPost .columns-container ul.product_list .bc-left-post .post-image a:hover:before {
  background: rgba(255, 255, 255, 0.5);
  transform: scale(0, 1);
}

.module-bcblog-categoryPost .columns-container ul.product_list .bc-left-post .post-image a:hover:after {
  background: rgba(255, 255, 255, 0.5);
  transform: scale(1, 0);
}

.module-bcblog-categoryPost .columns-container ul.product_list .bc-left-post .post-image a:hover img {
  /*-webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2);*/
}

.module-bcblog-categoryPost .columns-container ul.product_list .bc-right-post .post-name {
  margin-bottom: 0px;
}

.module-bcblog-categoryPost .columns-container ul.product_list .bc-right-post .post-name h3, .module-bcblog-categoryPost .columns-container ul.product_list .bc-right-post .post-name .h3 {
  font-size: 1.5rem;
  font-weight: normal;
  text-transform: uppercase;
  color: #3498db;
}

.module-bcblog-categoryPost .columns-container ul.product_list .bc-right-post .post-name h3 a, .module-bcblog-categoryPost .columns-container ul.product_list .bc-right-post .post-name .h3 a {
  color: inherit;
}

.module-bcblog-categoryPost .columns-container ul.product_list .bc-right-post .post-name h3 a:hover, .module-bcblog-categoryPost .columns-container ul.product_list .bc-right-post .post-name .h3 a:hover {
  color: #252a2f;
  text-decoration: underline;
}

.module-bcblog-categoryPost .columns-container ul.product_list .bc-right-post .post-description {
  margin: 0;
  padding: 10px 0;
}

.module-bcblog-categoryPost .columns-container ul.product_list.grid .bc-item {
  float: left;
  width: 50%;
}

.module-bcblog-categoryPost .columns-container ul.product_list.grid .bc-item:nth-child(2n+1) {
  clear: both;
}

.module-bcblog-categoryPost .columns-container ul.product_list.list .bc-item {
  float: left;
  width: 100%;
}

.module-bcblog-categoryPost .columns-container ul.product_list.list .bc-item .bc-left-post {
  width: 50%;
  float: left;
  padding-right: 15px;
}

.module-bcblog-categoryPost .columns-container ul.product_list.list .bc-item .bc-right-post {
  width: 50%;
  float: left;
}

.module-bcblog-categoryPost .columns-container ul.product_list.list .bc-item .bc-right-post h3, .module-bcblog-categoryPost .columns-container ul.product_list.list .bc-item .bc-right-post .h3 {
  margin-top: 0;
}

.frame-date-author {
  font-family: "Open Sans", sans-serif;
  font-style: italic;
  color: #8b8b8b;
}

.frame-date-author > div {
  display: block;
  *zoom: 1;
}

.frame-date-author > div:after, .frame-date-author > div:before {
  content: "";
  display: table;
  clear: both;
}

.frame-date-author span {
  font-family: "Open Sans", sans-serif;
  font-style: inherit;
  font-size: 1rem;
  color: inherit;
  display: inline-block;
  float: left;
  line-height: 1.5;
}

.frame-date-author span:nth-child(1) {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  font-style: normal;
  color: #252a2f;
  padding-right: 5px;
  font-weight: bold;
}

.frame-date-author span:nth-child(2) {
  padding-top: 2px;
}

.module-bcblog-post {
  /*Post detail Item*/
  /*Blog Comment Block*/
  /*Latest Article -- Same css from blog category -- */
  /*Blog Comment Form*/
}

.module-bcblog-post .name_detail {
  text-transform: uppercase;
  color: #3498db;
  margin-bottom: 20px;
  margin-top: 0;
}

.module-bcblog-post .img_detailblog {
  margin-bottom: 30px;
  text-align: center;
}

.module-bcblog-post .plpost_content {
  padding-bottom: 10px;
}

.module-bcblog-post .tag_blog {
  padding-bottom: 10px;
}

.module-bcblog-post .tag_blog .title_tag_blog {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.3333333333rem;
  color: #252a2f;
}

.module-bcblog-post .tag_blog .tag_list {
  font-style: italic;
}

.module-bcblog-post .tag_blog .tag_list a:hover {
  color: #3498db;
  text-decoration: underline;
}

.module-bcblog-post .out_related_products {
  padding-top: 20px;
  padding-bottom: 20px;
}

.module-bcblog-post .out_related_products .ajax_block_product .product-image {
  border: none;
}

.module-bcblog-post .out_related_products .ajax_block_product .product-name {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  color: #3498db;
  margin: 10px 0;
}

.module-bcblog-post .out_related_products .ajax_block_product .product-name a {
  color: inherit;
}

.module-bcblog-post .out_related_products .ajax_block_product .ajax_add_to_cart_button {
  display: inline-block;
  margin-top: 10px;
}

.module-bcblog-post .out_related_products .ajax_block_product .ajax_add_to_cart_button span {
  transition: all 0.2s ease-out;
  height: 38px;
  line-height: 38px;
  display: inline-block;
  background-color: #3498db;
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.3333333333rem;
  color: #fff !important;
  text-align: center;
  padding: 0 15px;
  border-radius: 0;
}

.module-bcblog-post .out_related_products .ajax_block_product .ajax_add_to_cart_button span:hover {
  background-color: #252a2f;
}

.module-bcblog-post .out_related_products .ajax_block_product .ajax_add_to_cart_button:before {
  transition: all 0.2s ease-out;
  content: '';
  display: inline-block;
  float: right;
  width: 38px;
  height: 38px;
  display: inline-block;
  font-size: 0;
  line-height: 38px;
  border-radius: 0px;
  border: none;
  background-color: #252a2f;
  color: #ffffff;
  text-align: center;
  padding: 0;
  transform: translate3d(0, 0, 0);
}

.module-bcblog-post .out_related_products .ajax_block_product .ajax_add_to_cart_button:before:hover {
  background-color: #3498db;
  color: #ffffff;
}

.module-bcblog-post .out_related_products .ajax_block_product .ajax_add_to_cart_button:before {
  content: "";
  font-family: "FontAweSome";
  display: block;
  font-size: 1.1666666667rem;
  color: #ffffff;
}

.module-bcblog-post .out_related_products .ajax_block_product .ajax_add_to_cart_button:hover span {
  background-color: #252a2f;
}

.module-bcblog-post .out_related_products .ajax_block_product .ajax_add_to_cart_button:hover:before {
  background: #3498db;
  content: "\f218";
}

.module-bcblog-post #plpost {
  padding-top: 20px;
  padding-bottom: 20px;
}

.module-bcblog-post .blog-comment-list {
  padding-top: 20px;
  padding-bottom: 20px;
}

.module-bcblog-post .blog-comment-list .comment-item {
  margin-bottom: 20px;
}

.module-bcblog-post .blog-comment-list .comment-item > div span {
  font-family: "Open Sans", sans-serif;
  font-style: italic;
}

.module-bcblog-post .blog-comment-list .comment-item > div span:nth-child(1) {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  color: #252a2f;
  font-style: normal;
  padding-right: 5px;
  font-weight: bold;
  margin-right: 10px;
}

.module-bcblog-post .blog-comment-list .comment-item > div.comment-content {
  margin: 5px 0;
}

.module-bcblog-post .related_posts {
  padding-top: 20px;
  padding-bottom: 20px;
}

.module-bcblog-post .related_posts .blog_content .item-related-post {
  margin-bottom: 40px;
}

.module-bcblog-post .related_posts .blog_content .item-related-post:last-child {
  margin-bottom: 0;
}

.module-bcblog-post .related_posts .blog_content .item-related-post .post-name {
  margin-bottom: 0px;
}

.module-bcblog-post .related_posts .blog_content .item-related-post .post-name h3, .module-bcblog-post .related_posts .blog_content .item-related-post .post-name .h3 {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  margin-top: 0;
  color: #3498db;
  text-transform: uppercase;
}

.module-bcblog-post .related_posts .blog_content .item-related-post .post-name h3 a, .module-bcblog-post .related_posts .blog_content .item-related-post .post-name .h3 a {
  color: inherit;
}

.module-bcblog-post .related_posts .blog_content .item-related-post .post-name h3:hover, .module-bcblog-post .related_posts .blog_content .item-related-post .post-name .h3:hover {
  color: #252a2f;
}

.module-bcblog-post .related_posts .blog_content .item-related-post .post-name h3:hover a, .module-bcblog-post .related_posts .blog_content .item-related-post .post-name .h3:hover a {
  text-decoration: underline;
}

.module-bcblog-post .related_posts .blog_content .item-related-post .post-description {
  margin: 0 0 10px;
  padding: 0;
}

.module-bcblog-post .related_posts .blog_content .item-related-post .post-date-add, .module-bcblog-post .related_posts .blog_content .item-related-post .post-author {
  *zoom: 1;
}

.module-bcblog-post .related_posts .blog_content .item-related-post .post-date-add:after, .module-bcblog-post .related_posts .blog_content .item-related-post .post-date-add:before, .module-bcblog-post .related_posts .blog_content .item-related-post .post-author:after, .module-bcblog-post .related_posts .blog_content .item-related-post .post-author:before {
  content: "";
  display: table;
  clear: both;
}

.module-bcblog-post .related_posts .blog_content .item-related-post .post-date-add span, .module-bcblog-post .related_posts .blog_content .item-related-post .post-author span {
  font-family: "Open Sans", sans-serif;
  font-style: inherit;
  font-size: inherit;
  color: inherit;
  display: inline-block;
  float: left;
  line-height: 1.5;
}

.module-bcblog-post .related_posts .blog_content .item-related-post .post-date-add span:nth-child(1), .module-bcblog-post .related_posts .blog_content .item-related-post .post-author span:nth-child(1) {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  color: #252a2f;
  font-style: normal;
  padding-right: 5px;
  font-weight: bold;
}

.module-bcblog-post .related_posts .blog_content .item-related-post .post-date-add span:nth-child(2), .module-bcblog-post .related_posts .blog_content .item-related-post .post-author span:nth-child(2) {
  padding-top: 2px;
  font-style: italic;
}

.module-bcblog-post .pl_comment_form {
  padding-top: 20px;
  padding-bottom: 20px;
}

.module-bcblog-post .pl_comment_form .submit a {
  height: 34px;
  line-height: 34px !important;
  padding: 0 15px;
  display: inline-block;
  background-color: #3498db;
  text-transform: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
  font-size: 1.1666666667rem;
  font-weight: none;
  color: #ffffff !important;
  text-align: center;
  padding: 0 12px;
  border: none;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.module-bcblog-post .pl_comment_form .submit a span {
  font-size: inherit !important;
  color: inherit !important;
}

.module-bcblog-post .pl_comment_form .submit a:after {
  /*content:'';
		display: block;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
		background-color: #fff;
		transform: scale(0,0);
		opacity: 0;	
		border-radius: 0px;*/
}

.module-bcblog-post .pl_comment_form .submit a:hover {
  color: #ffffff !important;
  background-color: #252a2f;
  /*&:after {
			transition: all .35s ease;
			transform: scale(1,1);	
			opacity: 1;
		}*/
}

.post-name a:hover {
  color: #3498db;
}
